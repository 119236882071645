import CheckIcon from '@src/svgs/CheckIcon';
import { applyCSSOverrides } from '@src/utility/styled';
import styled from 'styled-components';

type OverridesConfig = {
  containerStyles?: Record<string, string | number>;
};

export default function Checkbox({
  label,
  color,
  borderColor,
  labelColor,
  onClick,
  disabled,
  checked,
  shape = 'square',
  overrides = {},
}: {
  label: string;
  color: string;
  borderColor: string;
  labelColor?: string;
  onClick: () => void;
  disabled?: boolean;
  checked: boolean;
  shape?: 'circle' | 'square';
  overrides?: OverridesConfig;
}) {
  return (
    <CheckboxContainer
      onClick={onClick}
      disabled={disabled}
      overrides={overrides.containerStyles}
    >
      <Check color={color} borderColor={borderColor} shape={shape}>
        {checked ? (
          <CheckIcon
            width="9"
            height="9"
            fillColor="#03041A"
            strokeColor="#03041A"
            strokeWidth="0.75"
          />
        ) : null}
      </Check>
      <CheckboxLabel color={labelColor}>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
}

const Check = styled.div<{
  color: string;
  borderColor: string;
  shape: 'circle' | 'square';
}>`
  display: flex;
  width: 10px;
  height: 10px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '2px')};
`;

export const CheckboxContainer = styled.div<{
  disabled?: boolean;
  overrides?: Record<string, string | number>;
}>`
  display: flex;
  gap: 8px;
  user-select: none;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.6;
  `}
  ${(props) => applyCSSOverrides(props.overrides)}
`;

export const CheckboxLabel = styled.div<{ color?: string }>`
  text-transform: none;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  color: #f3e9d7;
  ${(props) => props.color && `color: ${props.color};`}
`;
