import { useEffect, useState } from 'react';
import { useAnalytics, useStore } from '../stores-v2/StoreContext';
import type { DatoContext } from '../stores-v2/types';
import type { UserResource, SessionResource } from '@clerk/types';

interface InitParams {
  storyId: string | null | undefined;
  videoId?: string;
  showcase?: string;
  env?: string;
  datoCtx?: DatoContext;
  clerkUser?: UserResource | null;
  clerkSession?: SessionResource | null;
  isClerkLoaded?: boolean;
  hasValidAuth: boolean;
}

export function useStoreInitialization({
  env,
  datoCtx,
  clerkUser,
  clerkSession,
  isClerkLoaded,
  hasValidAuth,
}: InitParams) {
  const rootStore = useStore();
  const analyticsStore = useAnalytics();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!hasValidAuth) return;

    console.debug('Initializing store with:', {
      env,
      datoCtx,
      clerkUser,
      clerkSession,
    });

    async function initializeStore() {
      rootStore.initialize({
        env: env || '',
        datoContext: datoCtx,
      });

      // If we have clerk auth, set up clerk context
      if (clerkSession) {
        try {
          const token = await clerkSession.getToken();
          rootStore.userIdentityStore.setClerkContext(
            //@ts-ignore
            clerkUser,
            clerkSession,
            token || null,
          );
          analyticsStore.identifyUser();
        } catch (error) {
          console.error('Error fetching clerk token:', error);
          return;
        }
      }

      // Setup Clients
      rootStore.datoClientStore.initializeClients();

      setIsReady(true);
    }

    initializeStore();
  }, [env, datoCtx, clerkUser, clerkSession, isClerkLoaded, hasValidAuth]);

  return {
    isReady,
    userIdentity: rootStore.userIdentityStore.getUserIdentity(),
  };
}
