/* eslint-disable react/jsx-no-target-blank */
import React, { forwardRef, useState } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { TextInput } from './TextInput';
import styled, { css } from 'styled-components';

import SliderSelect from './SliderSelect';
import UpDownIcon from '../../svgs/UpDownIcon';
import ItemDropdownSelect from '../common/ItemDropdownSelect';
import {
  AnimationSettingsUI,
  InstagramAnimationLines,
} from './AnimationSettingsUI';
import lodash from 'lodash';
import {
  DEFAULT_KARAOKE_CONFIG,
  MAX_CHARS_INSTAGRAM,
} from '../../videoTranscriptionProcessor/constants/karaokeConstants';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';
import StrokeSelect from '../common/StrokeSelect';
import KaraokeBackground from '../common/KaraokeBackground';
import KaraokeShadow, { KaraokeShadowConfig } from '../common/KaraokeShadow';
import { CommaIcon, PeriodIcon } from '../common/icons';
import {
  convertFromPixels,
  convertToPixels,
  getMinMaxYPosition,
} from '../../videoTranscriptionProcessor/utils';
import ColorDropdownSelect from '../common/ColorDropdownSelect';
import { observer } from 'mobx-react-lite';
import FontDropdownSelect from '../common/FontDropdownSelect';
import { FONT_SIZE_VALUES } from '../../utility/general';
import TextBoldAlignFormatting from '../common/TextBoldAlignFormatting';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { Bold } from '@src/utility/fonts';

interface TextSettingsProps {
  activeElement?: ElementState;
  skipFields: string[];
  selectedBold: Bold | null;
  selectedActions: (Action | Readability)[];
  selectedFontFamily: string;
  selectedFontSize: string;
  selectedFillColor: string;
  selectedBackgroundColor: string | null;
  selectedShadow?: KaraokeShadowConfig;
  modifyProperty: (propertyMap: Partial<KaraokeConfig>) => void;
  handleToggleBold: (bold: Bold, customFontWeight?: number) => void;
  handleSanitizePunctuation: (action: Readability) => void;
  handleToggleAction: (
    action: Action,
    propertyName: keyof KaraokeConfig,
    normalState: string,
    requiredState: string,
  ) => void;
  alignType: Align;
  toggleAlign: (align: Align) => void;
  setAnimation: (
    time: string | number | null,
    type: any,
    subType?: any,
    speed?: any,
    duration?: number | null,
    background_effect?: string | null,
  ) => void;
  animations: {
    enter?: string;
    exit?: string;
    element?: string;
  };
  karaokeElement: KaraokeConfig;
  hasStickyBottom: boolean;
}

export enum Action {
  bold = 'Bold',
  italic = 'Italic',
  capitalize = 'Capitalize',
  align = 'Align',
}

export enum Readability {
  comma = 'Comma',
  period = 'Period',
  filler = 'Fillers',
}

export enum Align {
  leftAlign = 'Left Align',
  midAlign = 'Middle Align',
  rightAlign = 'Right Align',
}

export const AlignValues = {
  [Align.leftAlign]: '0%',
  [Align.midAlign]: '50%',
  [Align.rightAlign]: '100%',
};

enum Colors {
  white = 'rgba(255, 255, 255, 1)',
  black = 'rgba(0, 0, 0, 1)',
  red = 'rgba(255, 0, 0, 1)',
  green = 'rgba(0, 255, 0, 1)',
  orange = 'rgba(255, 165, 0, 1)',
  blue = 'rgba(0, 0, 255, 1)',
  yellow = 'rgba(255, 255, 0, 1)',
}

export const TextSettingsUI = observer(
  forwardRef<any, TextSettingsProps>((props, ref) => {
    const {
      skipFields,
      selectedBold,
      selectedActions,
      selectedFontFamily,
      selectedFontSize,
      selectedFillColor,
      selectedBackgroundColor,
      selectedShadow,
      modifyProperty,
      handleToggleBold,
      handleToggleAction,
      handleSanitizePunctuation,
      alignType,
      toggleAlign,
      setAnimation,
      animations,
      karaokeElement,
      hasStickyBottom,
    } = props;

    const videoCreator = useVideoCreatorStore();

    const modifyPropertyDebounced = lodash.debounce(modifyProperty, 500);
    const [lines, setLines] = useState<keyof typeof InstagramAnimationLines>(
      karaokeElement.instagramLines || 5,
    );

    const modifyLines = (lines: keyof typeof InstagramAnimationLines) => {
      setLines(lines);
      modifyProperty({ instagramLines: lines });
    };

    const transcriptionLanguage = videoCreator.originalTranscription?.language;
    const selectedLanguage =
      karaokeElement.language || DEFAULT_KARAOKE_CONFIG.language!;

    const selectedBackgroundOpacity = parseFloat(
      !selectedBackgroundColor || selectedBackgroundColor === 'transparent'
        ? '0'
        : selectedBackgroundColor.split(',')[3]?.split(')')[0]?.trim() ?? '1',
    );

    const strokeWidth = parseFloat(
      karaokeElement.stroke_width ?? DEFAULT_KARAOKE_CONFIG.stroke_width,
    );
    const strokeColor =
      karaokeElement.stroke_color || DEFAULT_KARAOKE_CONFIG.stroke_color;

    const minMaxXPosition = (() => {
      let min = 0;
      let max = 100;
      let width = videoCreator.renderer?.getSource()?.width || 1280;
      let height = videoCreator.renderer?.getSource()?.height || 720;
      const pxPerChar = 15 / 20; // 14px per char for 20px font size
      const fontSize = parseFloat(selectedFontSize);
      const fontSizeUnits = selectedFontSize.match(/[a-z]+/)?.[0] || 'px';
      const fontSizeInPx =
        fontSizeUnits === 'vh'
          ? convertToPixels(fontSize, 'vh', { height, width })
          : fontSize;
      if (karaokeElement.instagramEffect) {
        const largeFontSize = 1.25 * fontSizeInPx;
        const dx =
          100 -
          MAX_CHARS_INSTAGRAM *
            convertFromPixels(largeFontSize * pxPerChar, 'vw', {
              height,
              width,
            });
        if (alignType === Align.midAlign) {
          min = 47 - 0.5 * dx;
          max = 51 + 0.5 * dx;
        } else if (alignType === Align.leftAlign) {
          min = 45;
          max = 50 + dx;
        } else if (alignType === Align.rightAlign) {
          min = 50 - dx;
          max = 56;
        }
      } else {
        min = 40;
        max = 60;
      }
      return [min, max];
    })();

    const minMaxYPosition = getMinMaxYPosition(
      undefined,
      selectedFontSize,
      lines,
      karaokeElement,
    );

    return (
      <Main>
        {/* {!skipFields.includes('text') && props.activeElement && (
        <TextInput activeElement={props.activeElement} />
      )} */}

        <SliderGroup>
          <Section>
            <SectionCaption>Animation</SectionCaption>
            <AnimationSettingsUI
              ref={ref}
              hasStickyBottom={hasStickyBottom}
              types={['enter']}
              elementType="text"
              defaultAnimations={{}}
              setAnimation={setAnimation}
              animations={animations}
              setLines={modifyLines}
              lines={lines}
            />
          </Section>
          <Section>
            <SectionCaption>Position</SectionCaption>
            <Position>
              <SliderSelect
                title="Position"
                Icon={<UpDownIcon />}
                action={modifyPropertyDebounced}
                defaultValue="80"
                unit="%"
                currValue={parseFloat(karaokeElement.y)}
                propertyName="y"
                reverse={true}
                min={100 - minMaxYPosition[1]}
                max={100 - minMaxYPosition[0]}
              />
              <SliderSelect
                title="Position"
                Icon={
                  <div style={{ rotate: '90deg', paddingTop: '5px' }}>
                    <UpDownIcon />
                  </div>
                }
                action={modifyPropertyDebounced}
                defaultValue="50"
                unit="%"
                currValue={parseInt(karaokeElement.x || '50')}
                propertyName="x"
                min={minMaxXPosition[0]}
                max={minMaxXPosition[1]}
              />
            </Position>
          </Section>
        </SliderGroup>

        {!transcriptionLanguage?.includes('en') && (
          <Section>
            <SectionCaption>Subtitles</SectionCaption>
            <div style={{ width: '177px', marginBottom: '8px' }}>
              <ItemDropdownSelect
                action={(propertyName, value) =>
                  modifyProperty({ [propertyName]: value })
                }
                propertyName="language"
                defaultValue={
                  selectedLanguage.includes('en') ? 'english' : 'original'
                }
                values={['Original', 'English'].map((value) => ({
                  caption: value,
                  value: value.toLowerCase(),
                }))}
              />
            </div>
          </Section>
        )}

        <Section>
          <SectionCaption>Font</SectionCaption>
          <FontColorGroup>
            <FontDropdownSelect
              ref={ref}
              action={(propertyName, value) =>
                modifyProperty({ [propertyName]: value })
              }
              propertyName="font_family"
              defaultValue={selectedFontFamily}
              type="karaoke"
            />

            <FontSize>
              <ItemDropdownSelect
                ref={ref}
                hasStickyBottom={hasStickyBottom}
                action={(propertyName, value) =>
                  modifyProperty({ [propertyName]: value })
                }
                propertyName="font_size"
                defaultValue={selectedFontSize.toString()}
                values={FONT_SIZE_VALUES.map((value) => ({
                  caption: `${value}`,
                  value: `${value}`,
                }))}
              />
            </FontSize>

            <ColorDropdownSelect
              action={(propertyName, value) =>
                modifyProperty({ [propertyName]: value })
              }
              propertyName="fill_color"
              defaultValue={selectedFillColor}
            />
          </FontColorGroup>
        </Section>

        <StrokeSegment>
          <Section>
            <SectionCaption>Highlight</SectionCaption>
            <Highlight>
              <KaraokeBackground
                action={modifyProperty}
                defaultValue={selectedBackgroundColor}
                opacity={selectedBackgroundOpacity}
              />
            </Highlight>
          </Section>

          <Section>
            <SectionCaption>Shadow</SectionCaption>
            <KaraokeShadow
              action={modifyProperty}
              value={selectedShadow || undefined}
            />
          </Section>

          <Section>
            <SectionCaption>Stroke</SectionCaption>
            <Stroke>
              <ColorDropdownSelect
                action={(propertyName, value) => {
                  const propChange = {
                    [propertyName]: value,
                  };
                  if (
                    !value ||
                    value === 'transparent' ||
                    value === 'rgba(0, 0, 0, 0)'
                  ) {
                    propChange.stroke_width = '0';
                    propChange.letter_spacing = '0%';
                  } else if (
                    !karaokeElement.stroke_color ||
                    karaokeElement.stroke_color === 'transparent' ||
                    karaokeElement.stroke_color === 'rgba(0, 0, 0, 0)'
                  ) {
                    propChange.stroke_width = '1 vmin';
                    propChange.letter_spacing = '75%';
                  }
                  modifyProperty(propChange);
                }}
                propertyName="stroke_color"
                defaultValue={strokeColor}
                showBorderColor
              />
              <StrokeSelect
                ref={ref}
                modifyProperty={modifyProperty}
                strokeWidth={strokeWidth}
                hasStickyBottom={hasStickyBottom}
              />
            </Stroke>
          </Section>
        </StrokeSegment>

        <Section>
          <SectionCaption>Text Formatting</SectionCaption>
          <ActionGroup>
            <TextBoldAlignFormatting
              ref={ref}
              alignType={alignType}
              handleToggleAction={handleToggleAction}
              handleToggleBold={handleToggleBold}
              hasStickyBottom={hasStickyBottom}
              selectedFontFamily={selectedFontFamily}
              selectedBold={selectedBold}
              selectedActions={selectedActions as Action[]}
              toggleAlign={toggleAlign}
            />
          </ActionGroup>
        </Section>

        <Section>
          <SectionCaption>Readability</SectionCaption>
          <ActionGroup>
            {Object.values(Readability).map((action) => (
              <ActionButton
                key={action}
                isSelected={selectedActions.includes(action)}
                onClick={() => handleSanitizePunctuation(action)}
              >
                {(() => {
                  switch (action) {
                    case Readability.comma:
                      return <CommaIcon fillColor={'currentColor'} />;
                    case Readability.period:
                      return <PeriodIcon fillColor={'currentColor'} />;
                    case Readability.filler:
                      return <span className="filler">Filler</span>;
                  }
                })()}
              </ActionButton>
            ))}
          </ActionGroup>
        </Section>
      </Main>
    );
  }),
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Position = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const FontSize = styled.div`
  // flex: 1;
`;

const ActionButton = styled.button<{ isSelected: boolean }>`
  outline: 0;
  border: 1px solid ${(props) => (props.isSelected ? '#F2D093' : '#484848')};
  // width: 32px;
  width: 12%;
  aspect-ratio: 1/1;
  // height: 32px;
  background-color: ${(props) => (props.isSelected ? '#F2D093' : '#03041A')};
  border-radius: 4px;
  color: ${(props) => (props.isSelected ? '#03041A' : '#484848')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span.filler {
    font-weight: 400;
    font-size: 11px;
    line-height: 9.68px;
    color: ${(props) => (props.isSelected ? '#03041A' : 'white')};
  }
`;

const ActionGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 5px;
  // justify-content: space-between;
  align-items: center;
`;

const SliderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding-bottom: 8px;
`;

const FontColorGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const StrokeSegment = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Highlight = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

const Stroke = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SectionCaption = styled.span`
  font-family: 'Inter', sans-serif;
  color: #484848;
  font-size: 10px;
  font-weight: 500;
`;
