export default function CircleMinusIcon({
  width = '9',
  height = '9',
  strokeColor = 'black',
}: {
  width?: string;
  height?: string;
  strokeColor?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9 9"
      fill="none"
    >
      <g clipPath="url(#clip0_24846_14332)">
        <path d="M2 4H7V5H2V4Z" fill={strokeColor} />
        <path
          d="M4.5 0.00230797C3.34407 -0.0364972 2.22093 0.415349 1.37722 1.25863C0.533503 2.10191 0.038176 3.2677 0 4.5C0.038176 5.7323 0.533503 6.89809 1.37722 7.74137C2.22093 8.58465 3.34407 9.0365 4.5 8.99769C5.65593 9.0365 6.77907 8.58465 7.62278 7.74137C8.4665 6.89809 8.96182 5.7323 9 4.5C8.96182 3.2677 8.4665 2.10191 7.62278 1.25863C6.77907 0.415349 5.65593 -0.0364972 4.5 0.00230797ZM4.5 8.24808C3.53091 8.28541 2.58739 7.91203 1.87585 7.20963C1.1643 6.50723 0.74265 5.53298 0.703125 4.5C0.74265 3.46702 1.1643 2.49277 1.87585 1.79037C2.58739 1.08797 3.53091 0.714594 4.5 0.751923C5.46909 0.714594 6.41261 1.08797 7.12415 1.79037C7.8357 2.49277 8.25735 3.46702 8.29688 4.5C8.25735 5.53298 7.8357 6.50723 7.12415 7.20963C6.41261 7.91203 5.46909 8.28541 4.5 8.24808Z"
          fill={strokeColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_24846_14332">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
