import { buildBlockRecord, Client } from '@datocms/cma-client-browser';
import ApiClient from '@src/apiClient/ApiClient';
import { GraphQLClient } from 'graphql-request';
import {
  AIFlowEvaluatedFunction,
  AIFlowQueryResult,
  AiFlowRetryCondition,
} from '../types.ts/ai_prompts';
import { AIFlow, AIFlowLog } from '@src/aiFlow/AIFlow';
import { AIFlowStep } from '@src/aiFlow/AIFlowStep';
import { AI_FLOW_BY_TITLE_AND_ACTIVE_QUERY } from '../gql/ai-prompts-gql';

export class AIFlowRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async load(preset: { title: string; category: string }) {
    let result;
    try {
      result = (await this.gqlClient.request(
        AI_FLOW_BY_TITLE_AND_ACTIVE_QUERY,
        { title: preset.title },
      )) as { aiFlow: AIFlowQueryResult };
    } catch (err) {
      console.error('Error loading AI flow', err);
      return null;
    }

    const aiFlowResult = result?.aiFlow;
    const steps = aiFlowResult.steps.map((step) => {
      return new AIFlowStep({
        id: step.id,
        label: step.label,
        systemMessageTemplate: step.systemMessageTemplate,
        userPromptTemplate: step.userPromptTemplate,
        includeMessagesFromStepId: step.overrideMessagesFromStep,
        maxAttempts: step.maxAttempts,
        iterateOver: step.iterateOver,
        models: step.modelName.split(',').map((modelName) => modelName.trim()),
        config: {
          temperature: step.temperature,
        },
        stream: step.stream ? 'object' : 'none',
        filter: undefined,
        mapper: undefined,
        reducer: undefined,
        retryCondition: step.retryConditionFunction
          ? (this.evalFunction(
              step.retryConditionFunction,
            ) as AiFlowRetryCondition)
          : true,
      });
    });

    return new AIFlow({
      title: aiFlowResult.title,
      steps,
      context: {},
      logEnabled: aiFlowResult.logEnabled,
      onRunLogs: (logs) => this.saveLogs(logs),
    });
  }

  async saveLogs(logs: AIFlowLog[]): Promise<undefined> {
    if (logs.length === 0) return;
    const flowTitle = logs[0].flow;

    const stepLogBlocks = [];
    try {
      const stepLogBlockTypeId = (
        await this.dClient.itemTypes.find('ai_flow_step_log')
      ).id as string;
      const stepLogMessageBlockTypeId = (
        await this.dClient.itemTypes.find('ai_flow_log_message')
      ).id as string;

      let currentStepLabel = '';
      let messageLogs = [];
      for (let log of logs) {
        if (log.stepLabel !== currentStepLabel && currentStepLabel) {
          stepLogBlocks.push(
            buildBlockRecord({
              item_type: { type: 'item_type', id: stepLogBlockTypeId },
              step_label: log.stepLabel,
              message_log: messageLogs,
            }),
          );

          messageLogs = [];
        }
        currentStepLabel = log.stepLabel;

        messageLogs.push(
          buildBlockRecord({
            item_type: { type: 'item_type', id: stepLogMessageBlockTypeId },
            timestamp: log.time,
            message: log.message,
          }),
        );
      }
      stepLogBlocks.push(
        buildBlockRecord({
          item_type: { type: 'item_type', id: stepLogBlockTypeId },
          step_label: logs[logs.length - 1].stepLabel,
          message_log: messageLogs,
        }),
      );
    } catch (err) {
      console.log(err);
    }

    try {
      const itemType = await this.dClient.itemTypes.find('ai_flow_log');

      await this.dClient.items.create({
        item_type: { type: 'item_type', id: itemType.id },
        flow_title: flowTitle,
        logs: stepLogBlocks,
      });
    } catch (err) {
      console.error('Error saving AI flow logs', err);
    }
  }

  private evalFunction(functionString: string) {
    try {
      const Fn = eval(
        functionString.replaceAll('\n', ''),
      ) as AIFlowEvaluatedFunction;
      return Fn;
    } catch (err) {
      console.error('Error evaluating function', err);
      return null;
    }
  }
}
