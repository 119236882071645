import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import { TranscriptChange, TranscriptData } from '../types.ts/transcript';
import { KaraokeConfig } from '../videoTranscriptionProcessor/types/karaokeTypes';
import { ImageKey, TalkingPointContent } from './general';
import { AspectRatio } from './video';
import { Attribution } from '../utility/unsplash';
import { AIFlowQueryResult, AIPrompt } from './ai_prompts';

export type ContentViewData = { storyId: string } & Record<
  Extract<
    SupportedGeneratedContentTypes,
    | 'Blog'
    | 'Quotes'
    | 'Takeaways'
    | 'Email'
    | 'Music Selection'
    | 'Photo Punchlist'
  >,
  {
    content: AiGeneratedContent<
      Extract<
        SupportedGeneratedContentTypes,
        | 'Blog'
        | 'Quotes'
        | 'Takeaways'
        | 'Email'
        | 'Music Selection'
        | 'Photo Punchlist'
      >
    > | null;
    storyId: string;
    hasBeenGenerated: boolean;
  }
>;
export type SupportedGeneratedContentTypes =
  | 'Photo Punchlist'
  | 'Description'
  | 'Blog'
  | 'Quotes'
  | 'Takeaways'
  | 'Email'
  | 'Music Selection';

export type AiGeneratedContent<
  T extends SupportedGeneratedContentTypes = SupportedGeneratedContentTypes,
> = {
  id?: string;
  title: T;
  response: T extends 'Photo Punchlist'
    ? PunchListContent[]
    : T extends 'Blog' | 'Email' | 'Music Selection' | 'Description'
      ? string
      : string[];
};

export type AiGeneratedPunchListContent = {
  id?: string;
  prompt: 'Photo Punchlist';
  generatedContent: {
    punchList: PunchListItem[];
  };
};

export type Person = {
  id?: string;
  name: string;
  email: string;
  social_profile_key: string;
  link_account_page: string;
  title?: string;
};

export type Caption = {
  id?: string;
  platform:
    | 'twitter'
    | 'facebook'
    | 'linkedin'
    | 'tiktok'
    | 'instagram'
    | 'youtube';
  caption?: string;
  story: {
    id: string;
  };
  shareable: {
    __typename: string;
  };
};

export type Subtitles = {
  lines: {
    text: string;
    start: number;
    end: number;
    translated: string;
    karaoke_break_start_ts_diff?: number;
    karaoke_break_end_ts_diff?: number;
  }[];
};

export type ShareableImageType = {
  id: string;
  imagefile?: Artifact;
  quote: string;
  storytellerName?: string;
  _allReferencingSharedContents?: SharedContent[];
  _allReferencingCaptions?: Caption[];
};

export type ShareableImagePreviewType = Record<
  'id' | 'url' | 'quote',
  string
> & {
  imageFile: Artifact | undefined;
  _allReferencingCaptions: Caption[];
  _allReferencingSharedContents?: SharedContent[];
};

export type OtherVideo = VideoClip & Pick<Video, 'aiGeneratedContent'>;

export type CustomerType = {
  clipProducerTemplates?: AIFlowQueryResult[];
  blogTemplates?: AIPrompt[];
  emailTemplates?: AIPrompt[];
  shareableImagesTemplates?: AIPrompt[];
  talkingPointsTemplate?: AIPrompt;
};

export type Story = {
  id: string;
  slug: string;
  hash?: string;
  title: string;
  description: string;
  brollPage: string;
  contributors: Record<'id' | 'name' | 'email', string>[];
  primaryShowcase: Showcase;
  thumbnail: {
    url: string;
  };
  storyType?: 'raw' | 'produced';
  byExternalUser?: boolean;
  externalUploadLog?: Record<string, any>;
  externalUploadStatus?: 'FAILED' | 'IN PROGRESS' | 'COMPLETED';
  uploadLog: StoryStepsLog;
  _allReferencingShowcases: Showcase[];
  _allReferencingSharedContents?: SharedContent[];
  _allReferencingCaptions: Caption[];
  aiGeneratedContent: AiGeneratedContent<SupportedGeneratedContentTypes>[];
  originalVideo: StoryVideo;
  originalVideoStatus: { status: 'IN_PROGRESS' | 'COMPLETE' | 'FAILED' };
  finalVideo?: Pick<Video, 'id' | 'videoFilePrimary' | 'videoStatus'>;
  otherVideos: Array<OtherVideo>;
  allVideos: Array<OtherVideo>;
  storyTeller: {
    name: string;
    id?: string;
    title?: string;
  };
  storyArtifacts?: Artifact[];
  storyAssets?: Asset[];
  aiPhotos?: AIPhotos[];
  storyArtifactsVideo?: Artifact[];
  transcription?: Transcription;
  // transcriptionChanges: TranscriptChangelog;
  alreadyGeneratedContent: string[];
  aiResponse?: {
    responses: AiGeneratedContent<SupportedGeneratedContentTypes>[];
  };
  savedBlog?: Record<number, Record<'title' | 'content' | 'username', string>>;
  savedEmail?: Record<number, Record<'title' | 'content' | 'username', string>>;
  savedTalkingPointContent?: Record<
    number,
    {
      title: string;
      content: Record<
        keyof TalkingPointContent,
        Record<'title' | 'content' | 'prompt', string>
      >;
    }
  >;
  // socialCaptions: Caption | null;
  shareableImages: ShareableImageType[];
  myAudios?: myAudio[];
  // Temporary fields to support both self hosted and dato hosted videos
  useAws: boolean;
  selfHostedVideo?: StoryVideo;
  datoHostedVideo?: StoryVideo;
};

export type DatoStory = Story; // Omit<Story, 'originalVideo' | 'transcription'>;

export type Transcription = {
  jobStatus: string;
  audio: {
    url: string;
  };
  elementsJson: {
    id: string;
    url: string;
  };
  waveformData: {
    id: string;
    url: string;
  };
};

export type DatoColor = {
  red: number;
  green: number;
  blue: number;
  alpha: number;
  hex: string;
  cssRgb: string;
};

export type TextBranding = {
  id?: string;
  title: string;
  fontName: string;
  fontSize: string;
  fontColor: DatoColor | null;
  backgroundColor: DatoColor | null;
  backgroundTransparency: number;
  strokeColor: DatoColor | null;
  strokeThickness: string;
  textType: 'basic' | 'karaoke';
  aspectRatio: AspectRatio;
};

export type GenericTextBranding = {
  id?: string;
  title: string;
  fontFamily: string;
  fontSize: string;
  fillColor: DatoColor;
  backgroundColor: DatoColor;
  backgroundTransparency: number;
  fontWeight: string;
  xAlignment: number;
  italic: boolean;
  uppercase: boolean;
  aspectRatio: AspectRatio;
  shadowColor: DatoColor | null;
  shadowBlur: number | null;
  shadowXOffset: number | null;
  shadowYOffset: number | null;
};

export type LowerThirdTextBranding = GenericTextBranding & {
  enterAnimation: string;
  exitAnimation: string;
  enterAnimationLength: number;
  exitAnimationLength: number;
};

export type KaraokeTextBranding = GenericTextBranding & {
  animation: string;
  xPos: number;
  yPos: number;
  instagramLines: number;
  strokeWidth: string;
  strokeColor: DatoColor;
  hideComma: boolean;
  hidePeriod: boolean;
  hideFillers: boolean;
};

export type LastUsedTemplate = {
  type: 'karaoke' | 'basic';
  title: string;
  aspectRatio: AspectRatio;
  id: string;
};

export type BrandingFontMetadata = {
  fontFamily: string;
  fontSubfamily: string;
  fullName: string;
  postScriptName: string;
  usWeightClass: number;
};

export type BrandingCustomFont = BrandingFontMetadata & {
  artifactId: string; // corresponds to `customFontFiles.id`
  archivedAt: string | null;
  format: string;
  // Creatomate properties
  style: string;
  source: string;
};

export type ShowcaseBranding = {
  id: string;
  title: string;
  primaryColors: string; // comma separated hex values
  secondaryColors: string; // comma separated hex values
  brandFonts: {
    id: string; // uuid_v4
    title: string;
    fontFamily: string;
    fontSize: number;
    bold: boolean;
    italic: boolean;
  }[];
  customFonts: BrandingCustomFont[];
  customFontFiles: Artifact[];
  designElements: Artifact[];
  brandVoice: string;
  avoidWords: string;
  missionStatement: string;
  keyMetrics: string;
  companyReports: Document[];
  organizationWebsite: string;
  externalLinks: string; // comma separated urls
};

export type ShowcaseAccess = Pick<Showcase, 'id' | 'slug' | 'organization'> & {
  adminList: {
    email: string;
  }[];
  allowList: {
    email: string;
  }[];
};

export type Showcase = {
  id: string;
  title: string;
  organization: string;
  stories: Pick<Story, 'id' | 'originalVideo' | 'useAws'>[];
  slug: string;
  logo: {
    url: string;
  };
  mainImage: {
    url: string;
  };
  profiles?: Array<{
    id: string;
    name: string;
    platforms?: {
      displayName: string;
      id: string;
      platform: string;
      profileUrl: string;
      type: string;
      usedQuota: number;
      userImage: string;
      username: string;
    }[];
  }>;
  customerType?: CustomerType;
  organizationArtifacts?: Artifact[];
  organizationArtifactsVideo?: Artifact[];
  organizationLogos?: Artifact[];
  contributors: Record<'id' | 'name' | 'email', string>[];
  lowerThirdTextBranding?: LowerThirdTextBranding[];
  karaokeTextBranding?: KaraokeTextBranding[];
  lastUsedTemplates?: LastUsedTemplate[];
  branding?: ShowcaseBranding;
};

export type DatoShowcase = Omit<Showcase, 'stories'> & {
  stories: Pick<Story, 'id' | 'useAws' | 'originalVideo'>[];
};

export type PunchListContent = {
  Line: string;
  Description: string;
  Evocative: string;
  Metadata: string;
  dallePrompt: string;
  dalleImages: string[];
  stockKeywords: string;
};

export type FileData = {
  id?: string;
  uploadId?: string;
  type: 'stock' | 'ai' | 'artifact' | 'quotes' | 'audio';
  url?: string;
  file?: File;
  fileName?: string;
  title: string;
  alt: string;
  cat?: string;
  customData?: Record<string, any>;
  tags?: string[];
  author?: string;
};

export type StoryDTO = Omit<
  Story,
  'aiPhotos' | 'storyAssets' | 'storyArtifacts' | 'storyArtifactsVideo'
> & {
  aiPhotos?: (AIPhotos | { id: string })[];
  storyAssets?: (Asset | { id: string })[];
  storyArtifacts?: (Artifact | { id: string })[];
  storyArtifactsVideo?: (Artifact | { id: string })[];
};

export type VolumeKeyPoint = {
  value: string;
  time: string;
};

export type VideoVersion = Video &
  Pick<SimpleSchemaTypes.ItemVersion, 'meta' | 'type' | 'editor'> & {
    versionId: string;
  } & {
    editor: SimpleSchemaTypes.User | SimpleSchemaTypes.ItemVersion['editor'];
  };

export type SharedContent = {
  id: string;
  title: string;
  socialProfileGroup: {
    id: string;
    name: string;
    platform: string;
    profiles: {
      id: string;
      name: string;
      platform: string;
      profileUrl: string;
      userImage: string;
      username: string;
    }[];
  };
  external: boolean;
  video: {
    id: string;
    title: string;
    thumbnail: {
      url: string;
    };
  };
  story: {
    id: string;
  };
  organization: {
    id: string;
  };
  postId: string;
  postUrl: string;
  lastFetched: string;
  postType: string;
  platform: string;
  post: string;
  likes: number;
  comments: number;
  shares: number;
  clicks: number;
  views: number;
};

export type ExtraElementData = {
  volumeKeyPoints?: VolumeKeyPoint[];
  punchListData?: PunchListItem;
  isLogo?: boolean;
  karaokeTemplateId?: string;
  lowerThirdTemplates?: Record<string, string>;
};

export type AssociatedVideo = Pick<
  Video,
  | 'id'
  | 'title'
  | 'aspectRatio'
  | 'parentVideo'
  | 'associatedVideos'
  | 'videoFilePrimary'
  | 'thumbnail'
  | 'shareableImageId'
  | 'videoStatus'
  | '_allReferencingSharedContents'
  | '_allReferencingCaptions'
  | 'sourcePlatform'
  | 'isClientReady'
  | 'isHidden'
  | 'clipJson'
  | 'lastActionJson'
>;

export type VideoClip = Pick<
  Video,
  | 'id'
  | 'title'
  | 'videoFilePrimary'
  | 'thumbnail'
  | 'shareableImageId'
  | 'slug'
  | 'hash'
  | 'aspectRatio'
  | 'associatedVideos'
  | 'videoStatus'
  | '_allReferencingVideos'
  | '_allReferencingSharedContents'
  | '_allReferencingCaptions'
  | 'sourcePlatform'
  | 'isClientReady'
  | 'isDownloadEnabled'
  | 'isHidden'
  | 'clipJson'
  | 'lastActionJson'
  | 'videoSource'
  | '_publishedAt'
>;

export type ParentVideo = AssociatedVideo & Pick<Video, 'videoSource'>;
export type ParentVideoQueryResult = AssociatedVideo & {
  videoJson: Video['videoSource'];
};

export type VideoAction = {
  editor: string;
  date: number | string;
} & (
  | {
      type: 'publish' | 'system_render_confirm';
      resolution: string;
    }
  | {
      type:
        | 'save'
        | 'autosave'
        | 'unsave'
        | 'system_render_start'
        | 'system_render_update'
        | 'system_render_error';
      resolution?: undefined;
    }
);

export type Video = {
  id?: string;
  title: string;
  videoFilePrimary?: StoryVideo;
  parentVideo?: ParentVideo;
  associatedVideos: AssociatedVideo[];
  aspectRatio: AspectRatio;
  thumbnail?: {
    id?: string;
    url: string;
    responsiveImage?: {
      src: string;
      srcSet: string;
    };
  };
  shareableImageId?: string;
  extraElementData: Record<
    string,
    ExtraElementData | KaraokeConfig | null | undefined
  >;
  renderId?: string;
  videoStatus: 'none' | 'rendering' | 'rendered' | 'editing' | 'error';
  videoSource: Record<string, any>; // RendererState;
  transcriptionChanges?: TranscriptChange[];
  transcriptionSnapshot?: Pick<TranscriptData, 'elements'>;
  subtitles?: Subtitles;
  aiGeneratedContent?: Array<{
    id?: string;
    prompt: string;
    generatedContent: string;
  }>;
  slug?: string;
  hash?: string;
  punchList?: PunchListItem[];
  _allReferencingSharedContents?: SharedContent[];
  _allReferencingVideos?: Pick<
    VideoClip,
    'id' | 'videoStatus' | 'associatedVideos'
  >[];
  _allReferencingCaptions?: Caption[];
  sourcePlatform: 'creator-studio' | 'content-studio';
  isClientReady?: boolean;
  isDownloadEnabled?: boolean;
  isHidden?: boolean;
  clipJson?: Record<string, any>;
  lastActionJson?: VideoAction;
  _publishedAt?: string;
};

export type StoryQueryResult = {
  story?: Omit<Story, 'punchList' | 'allVideos'>;
};

export type DatoStoryQueryResult = {
  story?: Omit<Story, 'punchList' | 'allVideos'>;
};

export type AlbumStory = {
  id: string;
  title: string;
  _allReferencingSharedContents?: SharedContent[];
  aiResponse?: {
    responses: AiGeneratedContent<SupportedGeneratedContentTypes>[];
  };
  byExternalUser?: boolean;
  externalUploadStatus?: 'FAILED' | 'IN PROGRESS' | 'COMPLETED';
  transcription: Pick<Transcription, 'jobStatus'>;
  storyTeller: {
    name: string;
  };
  _publishedAt: string;
  thumbnail: {
    url: string;
  };
  originalVideo?: {
    video: {
      duration: number;
      thumbnailUrl: string;
      mp4Url?: string;
      mp4UrlHigh?: string;
      mp4UrlMedium?: string;
      mp4UrlLow?: string;
    };
  };
  finalVideo: Pick<Video, 'thumbnail' | 'videoFilePrimary'>;
  otherVideos: Array<OtherVideo>;
  uploadLog: StoryStepsLog;
  useAws: boolean;
  datoHostedVideo?: {
    video: {
      duration: number;
      thumbnailUrl: string;
      mp4Url?: string;
      mp4UrlHigh?: string;
      mp4UrlMedium?: string;
      mp4UrlLow?: string;
    };
  };
};

export type DatoAlbumStory = AlbumStory; //Omit<AlbumStory, 'originalVideo'>;

export type AlbumQueryResult = {
  id: string;
  title: string;
  stories: AlbumStory[];
};

export type DatoAlbumQueryResult = Omit<AlbumQueryResult, 'stories'> & {
  stories: DatoAlbumStory[];
};

export type VideoFilesQueryResult = {
  video?: {
    id: string;
    thumbnail: {
      id: string;
      url: string;
      responsiveImage: {
        src: string;
        srcSet: string;
      };
    };
    videoFilePrimary?: StoryVideo;
    associatedVideos: AssociatedVideo[];
    _allReferencingVideos: ParentVideoQueryResult[];
    aspectRatio: Video['aspectRatio'];
    hash: string;
    slug: string;
    lastActionJson?: VideoAction;
  };
};

export type VideoItem = {
  id: string;
  title: string;
  video_files?: {
    upload_id: string;
  };
  thumbnail: {
    upload_id: string;
  };
  subtitles_json: string;
  video_json: string;
  video_status: 'none' | 'rendering' | 'rendered' | 'editing' | 'error';
  aspect_ratio: Video['aspectRatio'];
  transcription_json: string;
  transcription_snapshot_json: string;
  ai_generated_content: Array<{
    id: string;
    prompt: string;
    generated_content: string;
  }>;
  extra_elements_json: string;
  slug: string;
  hash: string;
  associated_videos: string[];
  source_platform: 'creator-studio' | 'content-studio';
  is_client_ready?: boolean;
  is_download_enabled?: boolean;
  is_hidden?: boolean;
  clip_json?: string;
  last_action_json?: string;
  // punch_list_json: string; //Record<string, any>;
  shared_content: string;
};

export type PunchListItem = {
  id?: string;
  type?: ImageKey;
  sub_type?: 'manual' | 'ai';
  elementId: string;
  artifactSrc?: string;
  artifactAspectRatio?: number;
  matchReason?: string;
  artifactDescription?: string;
  similarityScore?: string;
  line: string;
  description: string;
  evocative: string;
  metadata: Record<string, string>;
  genAiPrompt?: string;
  dallePrompt: string;
  dalleImages: Record<'uploadId' | 'uploadUrl', string>[];
  stockOptions?: Record<'id' | 'url' | 'description', string>[];
  stockKeywords: string;
  transcriptPosition: { startIndex: number; endIndex: number };
  duration?: number;
  startTime?: number;
  endTime?: number;
};

export type StoryVideo = {
  id?: string;
  url: string;
  height: number;
  width: number;
  video: {
    framerate: number;
    duration: number;
    muxPlaybackId?: string;
    thumbnailUrl: string;
    mp4Url: string;
    mp4UrlHigh: string;
    mp4UrlMedium: string;
    mp4UrlLow: string;
  };
  customData: any;
};

export type Artifact = {
  id: string;
  author?: string;
  title: string;
  width: string;
  height: string;
  video: {
    duration: number;
    playbackId: string;
    muxPlaybackId: string;
    thumbnailUrl: string;
    mp4Url?: string;
  } | null;
  responsiveImage: {
    alt: string;
    base64: string;
    sizes: string;
    src: string;
    srcSet: string;
    title: string;
    aspectRatio: number;
  } | null;
  format: string;
  mimeType: string;
  url: string;
  customData: {
    people?: string;
    place?: string;
    description?: string;
  };
  _createdAt: string;
};

export type Document = {
  id: string;
  title: string;
  format: string;
  mimeType: string;
  url: string;
  customData: any;
  _createdAt: string;
};

export type Song = {
  id: string;
  url: string;
  tags: string[];
  title: string;
  customData: {
    artist?: string;
    artists?: string;
    duration: string;
    songName: string;
    waveformDataUploadId?: string;
  };
};

export type Music = {
  id: string;
  genre: string;
  collection: Song[];
};

export type myAudio = {
  id: string;
  title: string;
  duration: number | null;
  waveformUploadId: string | null;
  metadataStatus: 'generating' | 'success' | 'failed' | 'none';
  song: Song;
};

export type Asset = Artifact;
export type AIPhotos = Artifact;

export type PhotoAsset = {
  id: string;
  title: string;
  description?: string;
  src: string;
  fullSrc?: string;
  places?: string;
  type: 'stock' | 'ai' | 'artifact';
  alt?: string;
  slug?: string;
  aspectRatio?: number;
  attribution?: Attribution;
};

export type PhotoAssetData = {
  tab: PhotoArtifactTab;
  resource: PhotoAsset[] | undefined;
  lastSelectedStock?: PhotoAsset[] | undefined;
  lastSelectedAi?: PhotoAsset[] | undefined;
  selectedId?: string;
};

export enum PhotoTab {
  artifact = 'Story',
  stock = 'Stock',
  ai = 'Ai',
  quote = 'Quotes',
  orgArtifact = 'Organization',
  logo = 'Logo',
}

export enum PhotoArtifactTab {
  story = 'Story',
  album = 'Organization',
  logo = 'Logo',
  stock = 'Stock',
  ai = 'Ai',
}

export type SocialPost = {
  id: string;
  external: boolean;
  title?: string;
  post?: string;
  platform: string;
  permalink: string;
  thumbnailUrl?: string;
  media?: ExternalMedia[];
  postType?: string; // carousel, video, image, text
  analytics?: {
    likes?: number;
    comments?: number;
    shares?: number;
    clicks?: number;
    views?: number;
  };
  raw_data?: any;
  created?: Date;
};

export type ExternalMedia = {
  width?: number;
  height?: number;
  bitRate?: number;
  contentType: string; // image or video
  mediaUrl: string;
};

export enum SocialPlatform {
  instagram = 'Instagram',
  facebook = 'Facebook',
  linkedin = 'LinkedIn',
  tiktok = 'TikTok',
  x = 'X',
  youtube = 'YouTube',
}

export type VideoRenderingStatus = Pick<
  Video,
  | 'videoStatus'
  | 'id'
  | 'videoFilePrimary'
  | 'renderId'
  | 'isHidden'
  | 'aspectRatio'
  | 'lastActionJson'
>;

export type ShowcaseArtifactsAiTitleUpdate = Pick<Showcase, 'id'> & {
  organizationArtifacts: Pick<Artifact, 'id' | 'title'>[];
};

export type StoryArtifactsAiTitleUpdate = Pick<Story, 'id'> & {
  storyArtifacts: Pick<Artifact, 'id' | 'title'>[];
};

export type StoryStepsLog = {
  id: string;
  initialUpload: Record<string, any>;
  submitToAuphonic: Record<string, any>;
  checkAuphoneProductionStatus: Record<string, any>;
  transferFilesToProductionBucket: Record<string, any>;
  ffmpegProcessing: Record<string, any>;
  compressVideo: Record<string, any>;
  generateWaveform: Record<string, any>;
  awsTranscribe: Record<string, any>;
  checkAwsTranscribeStatus: Record<string, any>;
  revaiTranscribe: Record<string, any>;
  checkRevaiTranscribeStatus: Record<string, any>;
  combineResults: Record<string, any>;
  extractThumbnailsFromLowRes: Record<string, any>;
  handleFailure: Record<string, any>;
};
