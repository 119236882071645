import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  RedirectToSignIn,
  useAuth,
  useClerk,
  useSession,
  useUser,
} from '@clerk/clerk-react';

// Stores and Hooks
import { useStore } from '@src/stores-v2/StoreContext';
import { useStoreInitialization } from '@src/hooks/useStoreInitialization';
import { useLaunchDarklyInit } from '@src/hooks/useLaunchDarklyInit';

// Components
import { VideoCreator } from '../components/VideoCreator';
import ContentStudio from '../components/ContentStudio';
import BrandKit from '../components/BrandKit';
import RootLayout from './RootLayout';
import Dashboard from '../components/Dashboard/';
import BrollPage from '../components/BrollPage';

// Services
import { DatoContextService } from '@src/services/DatoContextService';
import SimpleLoadingSpinner from '@src/components/SimpleLoadingSpinner';
import { useAuthInitialization } from '@src/hooks/useAuthInitialization';
import {
  isChrome,
  isEdge,
  isEdgeChromium,
  isChromium,
} from 'react-device-detect';
import UnsupportedBrowserModal from '@src/components/UnsupportedBrowserModal';
import { inDebugMode } from '@src/utility/debug';

const EditorEntry = observer(() => {
  const rootStore = useStore();

  // Clerk hooks
  const clerk = useClerk();
  const { session, isLoaded: isSessionLoaded } = useSession();
  const { user, isLoaded: isUserLoaded } = useUser();
  const { orgRole, orgSlug } = useAuth();
  console.debug('Clerk Provider State:', {
    isSessionLoaded,
    isUserLoaded,
    sessionStatus: session?.status,
    hasUser: !!user,
    clerkLoaded: clerk.loaded,
  });

  // External services
  const ldClient = useLDClient();

  // URL Parameters
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { storyId, env } = params;

  // Load Dato context
  const datoCtx = React.useMemo(() => DatoContextService.loadDatoContext(), []);

  const { isAuthReady, needsRedirect, hasValidAuth } = useAuthInitialization({
    datoCtx,
    clerkUser: user,
    clerkSession: session,
    isClerkLoaded: isSessionLoaded && isUserLoaded,
  });

  // Initialize stores
  const { isReady, userIdentity } = useStoreInitialization({
    storyId,
    env,
    datoCtx,
    clerkUser: user,
    clerkSession: session,
    isClerkLoaded: isSessionLoaded && isUserLoaded,
    hasValidAuth,
  });

  // Initialize LaunchDarkly
  useLaunchDarklyInit({
    ldClient,
    storyId,
    clerkUser: user,
    isClerkLoaded: isSessionLoaded,
    orgRole,
    orgSlug,
  });

  // Router configuration
  const videoCreatorElement = (
    <RootLayout>
      <VideoCreator />
    </RootLayout>
  );

  const router = createBrowserRouter([
    {
      path: '/',
      element: videoCreatorElement,
    },
    {
      path: '/creator',
      element: videoCreatorElement,
    },
    {
      path: '/content',
      element: (
        <RootLayout>
          <ContentStudio params={params} />
        </RootLayout>
      ),
    },
    {
      path: '/story-manager',
      element: (
        <RootLayout>
          <Dashboard params={params} />
        </RootLayout>
      ),
    },
    {
      path: '/broll',
      element: <BrollPage />,
    },
    {
      path: '/brand-kit',
      element: (
        <RootLayout>
          <BrandKit params={params} />
        </RootLayout>
      ),
    },
  ]);

  if (clerk.loaded && needsRedirect) {
    return (
      <>
        <RedirectToSignIn />
      </>
    );
  }

  if (!isReady) {
    return (
      <>
        <LoadingScreen>
          <SimpleLoadingSpinner text="Loading..." />
        </LoadingScreen>
      </>
    );
  }

  const isSupportedBrowser = isChrome || isEdge || isChromium || isEdgeChromium;

  return (
    <>
      <RouterProvider router={router} />
      {!inDebugMode() && !isSupportedBrowser && <UnsupportedBrowserModal />}
    </>
  );
});

const LoadingScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #03041a;
`;

// Provider composition
export default EditorEntry;
