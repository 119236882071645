import { Client } from '@datocms/cma-client-browser';
import ApiClient from '../apiClient/ApiClient';
import { Platform } from '../types.ts/caption';
import { ShareableImagePreviewType } from '../types.ts/story';

export class CaptionRepository {
  private dClient: Client | ApiClient;

  constructor(dClient: Client | ApiClient) {
    this.dClient = dClient;
  }

  async createCaption(params: {
    title: string;
    platform: Lowercase<Platform>;
    text: string; // Needs to align with CaptionService param type.
    storyId: string;
    shareableId?: string;
  }) {
    const itemType = await this.dClient.itemTypes.find('caption');
    const savedCaption = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      title: params.title,
      platform: params.platform,
      caption: params.text,
      story: params.storyId,
      shareable: params.shareableId,
    });
    return savedCaption.id;
  }

  async updateCaption(
    id: string,
    params: {
      text: string; // Must align with CaptionService param type.
      shareableId?: string;
    },
  ) {
    await this.dClient.items.update(id, {
      caption: params.text,
      shareable: params.shareableId,
    });
  }

  async createShareableImage(params: {
    storyId: string;
    quote: string;
    imageFile: ShareableImagePreviewType['imageFile'];
  }) {
    if (!params.imageFile || params.imageFile?.id === null) {
      throw new Error('Image file is required');
    }

    const itemType = await this.dClient.itemTypes.find('shareable_image');
    const savedImage = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      story: params.storyId,
      quote: params.quote,
      imagefile: {
        upload_id: params.imageFile.id,
      },
    });
    return savedImage.id;
  }
}
