import { TranscriptElement } from '@src/types.ts/transcript';

export type TranscriptDiffElement = TranscriptElement & {
  originalValue?: string | null;
};

export type TranscriptElementBlock = {
  elements: TranscriptDiffElement[];
  state: TranscriptDiffElement['state'];
  indexOffset: number;
};

export type TranscriptDiffSelectedElement = {
  element: TranscriptDiffElement;
  elementIndex: number;
  blockIndex: number;
};

export const checkMultipleWords = (element: TranscriptDiffElement) => {
  return (element.value?.split(' ').length || 0) > 2;
};

/**
 * Splits the transcript elements into blocks based on their state.
 * Replaced elements and elements with multiple words are treated as
 * a single block.
 */
export const getTranscriptElementBlocks = (
  elements: TranscriptDiffElement[],
  original: TranscriptDiffElement[],
  cursor?: { from: number; to: number },
) => {
  if (!elements.length || !original.length) return [];
  const blocks: TranscriptElementBlock[] = [];
  let currentBlock: TranscriptDiffElement[] = [];
  let currentState: TranscriptDiffElement['state'] = elements[0].state;
  elements.forEach((el, index, arr) => {
    const _indexOffset = index - currentBlock.length;
    const indexOffset =
      cursor && _indexOffset > cursor.from ? _indexOffset - 1 : _indexOffset;
    if (
      el.state === 'replaced' || // cut replaced element block
      checkMultipleWords(el) || // cut pre-multiple word element block
      (currentBlock.length === 1 && checkMultipleWords(currentBlock[0])) || // cut post-multiple word element block
      el.state !== currentState // cut state change element block
    ) {
      blocks.push({
        elements: currentBlock,
        state: currentState,
        indexOffset,
      });
      currentBlock =
        el.state === 'replaced'
          ? [{ ...el, originalValue: original[el.initial_index]?.value }]
          : [el];
      currentState = el.state;
    } else {
      currentBlock.push(el);
      if (index === arr.length - 1) {
        blocks.push({
          elements: currentBlock,
          state: currentState,
          indexOffset,
        });
      }
    }
  });
  return blocks;
};

export const getTranscriptElementFromCharIndex = (
  charIndex: number,
  elements?: TranscriptDiffElement[],
) => {
  if (!elements) return;
  let length = 0;
  for (let i = 0; i < elements.length; i++) {
    const elementCharIndex = length;
    length += elements[i].value?.length || 0;
    if (charIndex <= length) {
      return {
        element: elements[i],
        elementIndex: i,
        elementCharIndex,
      };
    }
  }
};
