import React, {
  MouseEvent,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import VideoCreatorStore from '../../stores/VideoCreatorStore';
import { TranscriptContextMenu } from './TranscriptContextMenu';
import DebugModal from '../debug/DebugModal';
import { WordReplacementModal } from './WordReplacementModal';
import { RepairIcon, TranscriptHistoryIcon } from '../common/icons';
import {
  getClosestNotRemovedElementIndexToRight,
  getClosestNotRemovedElementIndexToLeft,
  getClosestRemovedIndexToRight,
  getClosestRemovedIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
  getClosestNotRemovedTextIndexToLeft,
  getClosestElementIndexToLeftByFilter,
} from '../../videoTranscriptionProcessor/utils';
import {
  ImageKey,
  ImageWithType,
  SidebarOption,
  TranscriptContextMenuActions,
} from '../../types.ts/general';
import { TranscriptionDiff as TranscriptionDiffNew } from './_TranscriptionDiff';
import { TranscriptionText } from './TranscriptionText';
import ReplaceIcon from '../../svgs/ReplaceIcon';
import { RegenerateTranscriptionModal } from './RegenerateTranscriptionModal';
import Modal from '../common/Modal';
import PhotoModal from '../common/PhotosModal';
import PhotoModalTop from '../common/PhotoModalTop';
import { saveAssetToDato } from '../../utility/general';
import { saveClipFromTranscript } from '../../services/AIClipProducer';
import { KaraokeBreaksView } from './KaraokeBreaksView';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { KaraokeBreaksSubtitlesView } from './KaraokeBreaksSubtitlesView';
import EditTimingSection from './EditTimingSection';
import { inDebugMode } from '../../utility/debug';
import { analytics } from '@src/utility/analytics';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

// Cleanup Classes
import { TranscriptionStatus } from '../../types.ts/video';
import { useStore, useUserIdentity } from '@src/stores-v2/StoreContext';
import {
  getTranscriptElementBlocks,
  getTranscriptElementFromCharIndex,
  TranscriptElementBlock,
} from './utils';
import { Tooltip } from 'react-tooltip';

export const Transcript: React.FC = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const userIdentity = useUserIdentity();
  const { datoClientStore } = useStore();

  const { enableFixTranscriptButton } = useFlagsCombination(
    userIdentity.currentRole,
  );
  const [cursor, setCursor] = useState<{ from: number; to: number }>({
    from: 0,
    to: 0,
  });
  const [lastTimeUpdated, setLastTimeUpdated] = useState<number>(0);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [contextMenuActions, setContextMenuActions] = useState<
    TranscriptContextMenuActions[] | null
  >(null);
  const [selectedWordIndex, setSelectedWordIndex] = useState<number>(-1);
  const [showReplaceModal, setShowReplaceModal] = useState<Record<
    'startIndex' | 'endIndex',
    number
  > | null>(null);
  const [showEditTimingModal, setShowEditTimingModal] = useState<{
    startIndex: number;
    endIndex: number;
  } | null>(null);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [showReplaceWarning, setShowReplaceWarning] = useState<boolean>(false);
  const [photoSelection, togglePhotoSelectionModal] = useState<{
    loc: Record<'startIndex' | 'endIndex', number>;
    text: string;
  } | null>(null);
  const [selectedImage, setSelectedImage] = useState<
    ImageWithType[ImageKey] | null
  >(null);
  const showSubtitles = videoCreator.getShowSubtitles();

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const originalTranscription = videoCreator.originalTranscription;
  const isInKaraokeView =
    showSubtitles && videoCreator.karaokeProducer.hasElements();
  const isSubtitlesTranslated =
    isInKaraokeView &&
    !originalTranscription?.language.includes('en') &&
    videoCreator.karaokeProducer.getKaraokeConfig().language === 'english';
  const subtitleElements = videoCreator.subtitleElements;

  const transcriptElements = useMemo(() => {
    return isInKaraokeView && isSubtitlesTranslated
      ? subtitleElements
      : videoCreator.finalTranscriptionElements;
  }, [
    isInKaraokeView,
    isSubtitlesTranslated,
    subtitleElements,
    videoCreator.finalTranscriptionElements,
  ]);

  const transcriptBlocks = useMemo(() => {
    if (!showHistory) return [];
    return computed(() =>
      getTranscriptElementBlocks(
        videoCreator.finalTranscriptionElements || [],
        videoCreator.originalTranscription?.elements || [],
      ),
    ).get();
  }, [
    showHistory,
    videoCreator.finalTranscriptionElements,
    videoCreator.originalTranscription?.elements,
  ]);

  // Does any kind of debug mode exist?
  const [debugEnabled, setDebugEnabled] = useState(false);

  // autoscroll transcript when playing
  useEffect(() => {
    setAutoScroll(videoCreator.isPlaying);
  }, [videoCreator.isPlaying]);

  useEffect(() => {
    if (!autoScroll) return;
    scrollRef?.current
      ?.querySelector(
        `[data-index='${Math.min(
          cursor.to + (cursor.to < 10 ? 1 : 10),
          (transcriptElements?.length || 0) - 1,
        )}']`,
      )
      ?.scrollIntoView({
        // behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
  }, [cursor, autoScroll]);

  // highlight words as video playing
  useEffect(() => {
    if (
      !transcriptElements ||
      (videoCreator.isPlaying &&
        videoCreator.time < lastTimeUpdated &&
        lastTimeUpdated < videoCreator.time + 5) ||
      Math.abs(lastTimeUpdated - videoCreator.time) < 0.1
    )
      return;
    setLastTimeUpdated(videoCreator.time);

    const cursor = getCursorPosition();
    setCursor(cursor);
  }, [transcriptElements, videoCreator.time]);

  useEffect(() => {
    if (showHistory && showSubtitles) {
      videoCreator.setShowSubtitles(!showSubtitles);
    }
  }, [showHistory]);

  useEffect(() => {
    if (showSubtitles && showHistory) {
      setShowHistory(!showHistory);
    }
  }, [showSubtitles]);

  const compensateForRemovedElementsPosition = useCallback(
    (index: number) => {
      if (showHistory) return index;
      if (!transcriptElements) return 0;
      let i = 0;
      while (index > 0 && i < transcriptElements.length) {
        if (
          transcriptElements[i].state !== 'removed' &&
          transcriptElements[i].state !== 'cut'
        ) {
          index--;
        }
        i++;
      }
      return i;
    },
    [showHistory, transcriptElements],
  );

  const getBoundaryIndexesFromSelectionInDiff = useCallback(
    (selection: Selection) => {
      const anchorBlockIndex = +(
        selection.anchorNode?.parentElement?.dataset.blockIndex || 0
      );
      const focusBlockIndex = +(
        selection.focusNode?.parentElement?.dataset.blockIndex || 0
      );
      const [startBlockIndex, endBlockIndex] = [
        Math.min(anchorBlockIndex, focusBlockIndex),
        Math.max(anchorBlockIndex, focusBlockIndex),
      ];
      const startBlock = transcriptBlocks.find(
        (block) => block.indexOffset === startBlockIndex,
      );
      const endBlock = transcriptBlocks.find(
        (block) => block.indexOffset === endBlockIndex,
      );
      const [startOffset, endOffset] =
        anchorBlockIndex === focusBlockIndex
          ? [selection.anchorOffset, selection.focusOffset].sort(
              (a, b) => a - b,
            )
          : anchorBlockIndex < focusBlockIndex
            ? [selection.anchorOffset, selection.focusOffset]
            : [selection.focusOffset, selection.anchorOffset];
      const startElement = getTranscriptElementFromCharIndex(
        startOffset,
        startBlock?.elements,
      );
      const endElement = getTranscriptElementFromCharIndex(
        endOffset,
        endBlock?.elements,
      );
      if (startElement && endElement) {
        return {
          startIndex: startBlockIndex + startElement.elementIndex,
          endIndex: endBlockIndex + endElement.elementIndex,
        };
      }
      return null;
    },
    [transcriptBlocks],
  );

  const getBoundaryIndexesFromSelectionInNonDiff = useCallback(
    (selection: Selection) => {
      const anchorIndex: number =
        selection?.anchorNode?.parentElement === scrollRef.current
          ? compensateForRemovedElementsPosition(selection.anchorOffset) - 2 // -2 to compensate cursor position
          : Number(
              selection.anchorNode?.parentElement?.dataset?.index ||
                (selection.anchorNode as HTMLElement)?.dataset?.index, // cursor has index
            );
      const focusIndex: number =
        selection?.focusNode?.parentElement === scrollRef.current
          ? compensateForRemovedElementsPosition(selection?.focusOffset) - 2 // -2 to compensate cursor position
          : Number(
              selection?.focusNode?.parentElement?.dataset?.index ||
                (selection.focusNode as HTMLElement)?.dataset?.index, // cursor has index
            );

      if (isNaN(anchorIndex) || isNaN(focusIndex)) return null;

      let startIndex, endIndex, startOffset, endOffset;
      // selecting from left to right or from right to left
      if (anchorIndex >= focusIndex) {
        startIndex = focusIndex;
        endIndex = anchorIndex;
        startOffset = selection.focusOffset;
        endOffset = selection.anchorOffset;
      } else {
        startIndex = anchorIndex;
        endIndex = focusIndex;
        startOffset = selection.anchorOffset;
        endOffset = selection.focusOffset;
      }

      // todo: selection of transcript?
      // start and end of selection may be a little outside of text selected
      if (selection?.toString()?.length) {
        if (transcriptElements![startIndex]?.type === 'punct' && startOffset) {
          startIndex += 1;
        }
      }
      return { startIndex, endIndex, focusIndex, anchorIndex };
    },
    [transcriptElements, compensateForRemovedElementsPosition],
  );

  const getBoundaryIndexesFromSelection = useCallback(
    (selection: Selection) => {
      if (showHistory) return getBoundaryIndexesFromSelectionInDiff(selection);
      else return getBoundaryIndexesFromSelectionInNonDiff(selection);
    },
    [
      showHistory,
      getBoundaryIndexesFromSelectionInDiff,
      getBoundaryIndexesFromSelectionInNonDiff,
    ],
  );

  function getStartAndEndOfSelection() {
    const selection = window.getSelection();
    if (!(selection && selection.toString().length)) return null;
    const boundaries = getBoundaryIndexesFromSelection(selection);
    if (!boundaries) {
      selection.empty();
    }
    return boundaries;
  }

  const handleCursorMove = useCallback(
    (direction: 1 | -1) => {
      if (!transcriptElements) return;
      let newPosition;
      if (direction > 0) {
        newPosition = getClosestNotRemovedElementIndexToRight(
          cursor.from + 1,
          transcriptElements,
        );
        if (newPosition === -1) {
          newPosition =
            cursor.from + 1 > transcriptElements.length
              ? 0
              : transcriptElements.length;
        }
      } else {
        newPosition = getClosestNotRemovedElementIndexToLeft(
          cursor.from - 1,
          transcriptElements,
        );
        newPosition =
          newPosition === -1 ? transcriptElements.length : newPosition;
      }
      setAutoScroll(true);
      setCursor({
        from: newPosition || 0,
        to: (newPosition || 0) + 1,
      });

      // move playhead on timeline
      if (
        !isNaN(newPosition) &&
        transcriptElements[newPosition] &&
        transcriptElements[newPosition].ts != null
      ) {
        const moveToTs =
          Math.ceil((transcriptElements[newPosition].ts || 0) * 1000) / 1000;
        videoCreator.setTime(moveToTs, true);
      }
    },
    [transcriptElements, cursor],
  );

  const handleAddPunctuation = (
    code: 'Comma' | 'Period' | 'Space' | 'Enter',
  ) => {
    videoCreator.addPunctuation(code, cursor.from, isSubtitlesTranslated);
    setCursor({
      from: cursor.from + 1,
      to: cursor.from + 2,
    });
  };

  const handleAddKaraokeBreak = () => {
    let position = getClosestNotRemovedElementIndexToLeft(
      cursor.from - 1,
      transcriptElements!,
    );

    if (transcriptElements![position].type == 'text') {
      // HACK: since auto breaks end up there.
      position += 1;
    }

    const closeBreak = videoCreator.hasKaraokeBreakNear(
      cursor.from,
      isSubtitlesTranslated,
    );

    if (position < 0 || position > transcriptElements!.length - 1 || closeBreak)
      return;

    // Add break to element to right (aka next punct.)
    videoCreator.addKaraokeBreaks([position], isSubtitlesTranslated);
  };

  const handleRemoveKaraokeBreak = (position: number) => {
    if (position < 0 || position > transcriptElements!.length - 1) return;
    videoCreator.removeKaraokeBreak(position, isSubtitlesTranslated);
  };

  const extendEndIndexToFollowingWhitespace = (endIndex: number): number => {
    if (!transcriptElements) {
      return endIndex;
    }
    const nextElIndex = getClosestNotRemovedElementIndexToRight(
      endIndex + 1,
      transcriptElements,
    );
    if (nextElIndex !== -1 && transcriptElements[nextElIndex].value === ' ') {
      return nextElIndex;
    } else {
      return endIndex;
    }
  };

  const handleTranscriptRestore = async (action: 'restore' | 'showKaraoke') => {
    if (videoCreator.isPlaying) return;
    if (!transcriptElements) return;

    let startIndex, endIndex;
    const boundaries = getStartAndEndOfSelection();
    if (boundaries) {
      ({ startIndex, endIndex } = boundaries);
      startIndex = getClosestRemovedIndexToRight(
        startIndex,
        transcriptElements,
      );
      endIndex = getClosestRemovedIndexToLeft(endIndex, transcriptElements);
      if (startIndex === -1 || endIndex === -1 || endIndex < startIndex) return;
    } else if (selectedWordIndex > -1) {
      endIndex = startIndex = selectedWordIndex;
      if (
        action === 'showKaraoke' &&
        transcriptElements[startIndex].type === 'text'
      ) {
        endIndex = extendEndIndexToFollowingWhitespace(endIndex);
      }
    } else {
      return;
    }

    await videoCreator.restoreTranscriptAndVideo(
      startIndex,
      endIndex! + 1,
      isSubtitlesTranslated,
      !!boundaries && action == 'showKaraoke',
    );
    setCursor({
      from: endIndex,
      to: endIndex + 1,
    });

    await videoCreator.resetPhotoHighlights();
    videoCreator.timelineStore.setShouldCalcTimelineScale(true);
  };

  const getSelectedStartEndIndex = () => {
    let startIndex, endIndex;
    const boundaries = getStartAndEndOfSelection();
    if (boundaries) {
      ({ startIndex, endIndex } = boundaries);
    } else if (selectedWordIndex > -1) {
      endIndex = startIndex = selectedWordIndex;
    } else {
      return null;
    }

    startIndex = Math.max(startIndex, 0);
    endIndex = Math.min(endIndex, transcriptElements!.length - 1);
    return { startIndex, endIndex };
  };

  const handleHideKaraoke = () => {
    if (!transcriptElements) return;
    let { startIndex, endIndex } = getSelectedStartEndIndex() || {};
    if (startIndex === undefined || endIndex === undefined) return;
    if (
      startIndex === endIndex &&
      transcriptElements[endIndex].type === 'text'
    ) {
      endIndex = extendEndIndexToFollowingWhitespace(endIndex);
    }
    videoCreator.hideKaraoke({ startIndex, endIndex }, isSubtitlesTranslated);
    setSelectedWordIndex(-1);
  };

  const handleReplace = () => {
    const { startIndex, endIndex } = getSelectedStartEndIndex() || {};
    if (startIndex === undefined || endIndex === undefined) return;
    setShowReplaceModal({ startIndex, endIndex });
  };

  const handleEditTiming = () => {
    const selection = getSelectedStartEndIndex();
    if (transcriptElements && selection) {
      const { startIndex, endIndex } = selection; // Assuming only one element is selected

      // Ensure we're getting first/last text elements of each.
      const firstTextElementIndex = getClosestNotRemovedTextIndexToRight(
        startIndex,
        transcriptElements,
      );

      const lastTextElementIndex = getClosestNotRemovedTextIndexToLeft(
        endIndex,
        transcriptElements,
      );

      setShowEditTimingModal({
        startIndex: firstTextElementIndex,
        endIndex: lastTextElementIndex || firstTextElementIndex,
      });
    }
  };

  const handleTranscriptDelete = useCallback(() => {
    if (videoCreator.isPlaying) return;
    if (!transcriptElements) return;
    let startIndex: number, endIndex: number;
    const boundaries = getStartAndEndOfSelection();
    if (boundaries) {
      ({ startIndex, endIndex } = boundaries);
    } else if (selectedWordIndex > 0) {
      endIndex = startIndex = getClosestNotRemovedElementIndexToLeft(
        selectedWordIndex,
        transcriptElements,
      );
    } else if (cursor.to > 0) {
      endIndex = startIndex = getClosestElementIndexToLeftByFilter(
        cursor.from - 1,
        transcriptElements,
        (el) => {
          return (
            el.state !== 'removed' &&
            el.state !== 'cut' &&
            !!el.value &&
            (el.karaoke_break || !isInKaraokeView || el.value !== '\n')
          );
        },
      );
      if (transcriptElements![startIndex].karaoke_break) {
        handleRemoveKaraokeBreak(startIndex);
        if (
          isInKaraokeView ||
          videoCreator.finalTranscriptionElements![startIndex].value !== '\n'
        ) {
          return;
        }
      }
    } else {
      return;
    }

    videoCreator
      .cutTranscriptAndVideo(
        startIndex,
        endIndex! + 1,
        true,
        isSubtitlesTranslated,
      )
      .then(() => {
        let newPosition = isSubtitlesTranslated
          ? Math.min(endIndex, transcriptElements!.length)
          : Math.min(
              getClosestNotRemovedElementIndexToRight(
                endIndex + 1,
                transcriptElements!,
              ),
              transcriptElements!.length,
            );

        if (newPosition < 0) {
          newPosition = transcriptElements!.length; //getClosestNotRemovedElementIndexToRight(endIndex, transcriptElements);
        }

        if (newPosition > 0) {
          const newTextPosition = getClosestNotRemovedTextIndexToRight(
            newPosition,
            transcriptElements!,
          );
          if (newTextPosition > 0) {
            const newTime = transcriptElements?.at(newTextPosition)?.ts || 0;
            videoCreator.setTime(newTime, true);
            setLastTimeUpdated(newTime);
          }
          setCursor({
            from: newPosition,
            to: newPosition + 1,
          });
        }
        videoCreator.timelineStore.setShouldCalcTimelineScale(true);
      });
  }, [transcriptElements, cursor, selectedWordIndex, isInKaraokeView]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.code) {
        case 'Backspace':
          handleTranscriptDelete();
          break;
        case 'ArrowRight':
        case 'ArrowLeft':
          handleCursorMove(e.code === 'ArrowRight' ? 1 : -1);
          break;
        case 'Space':
        case 'Comma':
        case 'Period':
          handleAddPunctuation(e.code);
          break;
        case 'Enter':
          if (isInKaraokeView) {
            handleAddKaraokeBreak();
          } else {
            handleAddPunctuation(e.code);
          }
          break;
        case 'Backslash':
          if (e.shiftKey) {
            handleAddKaraokeBreak();
          }
          break;
      }
      e.stopImmediatePropagation();
      e.stopPropagation();
    },
    [handleTranscriptDelete, isInKaraokeView],
  );

  // todo make sure keyboard events from other places are not intercepted
  useEffect(() => {
    if (showReplaceModal || photoSelection) return;
    const container = document.getElementById('transcript-container');
    if (container) {
      container.addEventListener('keydown', handleKeyDown);
      return function cleanup() {
        container.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [handleKeyDown, showReplaceModal, photoSelection]);

  function getCursorPosition() {
    const currentWordIndex = transcriptElements!.findIndex(
      (el) =>
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.value &&
        el.end_ts &&
        el.end_ts > videoCreator.time,
    );

    return {
      from: currentWordIndex,
      to: currentWordIndex + 1,
    };
  }

  function skipToWordWithIndex(index: number) {
    if (!transcriptElements?.at(index)) return;
    if (
      transcriptElements.at(index)?.state === 'removed' ||
      transcriptElements.at(index)?.state === 'cut'
    ) {
      return;
    }
    const wasPlaying = videoCreator.isPlaying;
    let element = transcriptElements[index];
    if (element.type !== 'text') {
      element =
        transcriptElements[
          getClosestNotRemovedTextIndexToRight(index, transcriptElements)
        ] ??
        transcriptElements[
          getClosestNotRemovedTextIndexToLeft(index, transcriptElements)
        ];
    }
    videoCreator.setTime(element.ts!, true).then(() => {
      if (wasPlaying) {
        videoCreator.renderer?.play();
      }
      setCursor({
        from: index,
        to: index + 1,
      });
      setAutoScroll(true);
    });
  }

  const getActions = useCallback(
    (
      selectedWordIndex: number,
      selection: Selection | null = null,
    ): TranscriptContextMenuActions[] => {
      if (selectedWordIndex >= transcriptElements!.length) {
        if (videoCreator.videoTranscriptionProcessor.transcriptClipboard) {
          return ['pasteTextBefore'];
        }
        return [];
      }
      const selectedItem = transcriptElements![selectedWordIndex];

      if (selection && selection.toString().length) {
        const { startIndex, endIndex } =
          getBoundaryIndexesFromSelection(selection) || {};
        if (
          showHistory &&
          startIndex !== undefined &&
          endIndex !== undefined &&
          transcriptElements!
            .slice(startIndex, endIndex)
            .some((el) => el.state === 'removed' || el.state === 'cut')
        ) {
          return ['restore'];
        }
      }

      if (selectedItem.state === 'removed' || selectedItem.state === 'cut')
        return ['restore'];
      const menuActions = [] as TranscriptContextMenuActions[];
      if ((selection?.toString()?.split(' ') || []).length <= 5) {
        // max 5 words to replace
        // replacing makes text set into a single transcription element so that all words will have single timestamp
        menuActions.push('replace');
        if (videoCreator.videoTranscriptionProcessor.transcriptClipboard) {
          menuActions.push('pasteTextBefore');
          // menuActions.push('pasteTextAfter');
        }
      }

      menuActions.push(
        selectedItem.state === 'muted' ? 'showKaraoke' : 'hideKaraoke',
      );

      if (selectedItem.photo_highlight_id) {
        return videoCreator.punchListManager.addedPunchListItemId
          ? [...menuActions, 'removeTextLoading', 'removePhotoLoading']
          : [...menuActions, 'removeText', 'removePhoto'];
      }

      const finalActions = [
        ...menuActions,
        'removeText',
        'cutText',
        'saveAsClip',
        videoCreator.punchListManager.addedPunchListItemId
          ? 'addPhotoLoading'
          : 'addPhoto',
        'editTiming',
      ];

      return finalActions as TranscriptContextMenuActions[];
    },
    [
      transcriptElements,
      videoCreator.videoTranscriptionProcessor.transcriptClipboard,
      getBoundaryIndexesFromSelection,
      showHistory,
      videoCreator.punchListManager.addedPunchListItemId,
    ],
  );

  const filterActionsInSubtitles = useCallback(
    (actions: TranscriptContextMenuActions[]) =>
      actions.filter((action) => ['replace', 'removeText'].includes(action)),
    [],
  );

  const showOptionsForWordWithIndexAtPosition = useCallback(
    (
      index: number,
      positionXY: { x: number; y: number },
      selection: Selection | null,
    ) => {
      let actions: TranscriptContextMenuActions[] = getActions(
        index,
        selection,
      );
      if (!actions.length) return;
      if (isInKaraokeView && isSubtitlesTranslated) {
        actions = filterActionsInSubtitles(actions);
      }
      setSelectedWordIndex(index);
      setCursor({ from: index, to: index + 1 });
      setContextMenuPosition(positionXY);
      setContextMenuActions(actions);
    },
    [
      getActions,
      isInKaraokeView,
      isSubtitlesTranslated,
      filterActionsInSubtitles,
    ],
  );

  const mouseEnter = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    scrollRef.current?.focus();
  };

  const mouseLeave = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    scrollRef.current?.blur();
  };

  const showOptionsForSelection = useCallback(
    (
      e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
      selection: Selection,
      indices: { startIndex: number; endIndex: number },
    ) => {
      const { startIndex, endIndex } = indices;
      if (endIndex !== undefined && endIndex > -1) {
        showOptionsForWordWithIndexAtPosition(
          endIndex,
          { x: e.pageX, y: e.pageY },
          selection,
        );
      } else if (startIndex !== undefined && startIndex > -1) {
        showOptionsForWordWithIndexAtPosition(
          startIndex,
          { x: e.pageX, y: e.pageY },
          selection,
        );
      }
      if (
        endIndex &&
        endIndex > -1 &&
        endIndex < transcriptElements!.length &&
        transcriptElements![endIndex].state !== 'removed' &&
        transcriptElements![endIndex].state !== 'cut'
      ) {
        // do not set cursor on removed
        // videoCreator.setTime(transcriptElements![endIndex]?.ts || 0);
        setCursor({ from: endIndex, to: endIndex + 1 });
      }
    },
    [showOptionsForWordWithIndexAtPosition, transcriptElements],
  );

  const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const fn = async () => {
        if (e.button !== 0) return;
        // hack to wait for selection to be reset properly, otherwise onMouseUp old selection is possibly captured
        await new Promise((resolve) => setTimeout(resolve, 200));
        const selection = window.getSelection();
        if (!(selection && selection.toString().length)) return;
        const indices = getBoundaryIndexesFromSelection(selection);
        if (!indices) return;
        showOptionsForSelection(e, selection, indices);
      };
      fn();
    },
    [getBoundaryIndexesFromSelection, showOptionsForSelection],
  );

  const handleRemovePhoto = async () => {
    if (videoCreator.punchListManager.addedPunchListItemId) return;
    const punchListId =
      transcriptElements![selectedWordIndex!].photo_highlight_id;
    if (!punchListId) return;
    videoCreator.punchListManager.removePunchListItem(punchListId);
    await videoCreator.deleteElementWithTranscription(punchListId);
  };

  const handleAddPhoto = async () => {
    if (!photoSelection || !selectedImage?.url) {
      togglePhotoSelectionModal(null);
      return;
    }
    togglePhotoSelectionModal(null);
    if (!photoSelection.loc) return;

    let startIndex = getClosestNotRemovedTextIndexToRight(
      photoSelection.loc.startIndex,
      transcriptElements!,
    );

    let endIndex = getClosestNotRemovedTextIndexToLeft(
      photoSelection.loc.endIndex,
      transcriptElements!,
    );
    if (startIndex === -1 || endIndex === -1 || endIndex < startIndex) return;

    const startTime = transcriptElements![startIndex!].ts;
    const endTime = transcriptElements![endIndex!].end_ts;
    videoCreator.sidebarOptions = SidebarOption.aiProducer;

    const url = await saveAssetToDato(
      videoCreator,
      datoClientStore.assetRepository,
      selectedImage,
    );
    const aspectRatio = +selectedImage.width / +selectedImage.height;

    await videoCreator.punchListManager.addPhotoToPunchList(
      photoSelection.text,
      url,
      selectedImage.description || '',
      selectedImage.type,
      startIndex!,
      endIndex!,
      startTime!,
      endTime!,
      aspectRatio,
    );
    setSelectedWordIndex(-1);
  };

  const handleCutText = async () => {
    const { startIndex, endIndex } = getSelectedStartEndIndex() || {};
    if (startIndex === undefined || endIndex === undefined) return;
    await videoCreator.cutSentence(startIndex, endIndex);
    videoCreator.timelineStore.setShouldCalcTimelineScale(true);
  };

  const handlePasteText = (after: boolean) => {
    const { startIndex, endIndex } = getSelectedStartEndIndex() || {};
    if (startIndex === undefined || endIndex === undefined) return;
    videoCreator.pasteSentence(after ? startIndex + 1 : startIndex);
  };

  const handleRemoveText = async () => {
    handleTranscriptDelete();
  };

  const handleOpenPhotoModal = () => {
    const selection = window.getSelection();
    if (selection) {
      const boundaries = getBoundaryIndexesFromSelection(selection);
      const text = selection.toString();
      togglePhotoSelectionModal({ loc: boundaries!, text });
    }
  };

  const handleSaveAsClip = async () => {
    const selection = window.getSelection();
    if (!selection || !selection.toString().length) return;
    const { startIndex, endIndex } = getSelectedStartEndIndex() || {};

    await saveClipFromTranscript(videoCreator, {
      startIndex,
      endIndex,
      theme: 'social clip',
      transcriptPosition: {
        startIndex,
        endIndex,
      },
    });
    return;
  };

  const contextMenuCallback = async (
    action: TranscriptContextMenuActions | 'close',
  ) => {
    if (action === 'replace') {
      handleReplace();
    } else if (action === 'hideKaraoke') {
      handleHideKaraoke();
    } else if (action === 'removeText') {
      await handleRemoveText();
    } else if (action === 'removePhoto') {
      await handleRemovePhoto();
    } else if (action === 'saveAsClip') {
      handleSaveAsClip();
    } else if (action === 'addPhoto') {
      handleOpenPhotoModal();
    } else if (action === 'restore' || action === 'showKaraoke') {
      handleTranscriptRestore(action);
    } else if (action === 'cutText') {
      handleCutText();
    } else if (action === 'pasteTextAfter' || action === 'pasteTextBefore') {
      handlePasteText(action === 'pasteTextAfter');
    } else if (action === 'editTiming') {
      handleEditTiming();
    }
    if (action !== 'replace') {
      setSelectedWordIndex(-1);
    }
    setContextMenuPosition(null);
  };

  const handleContextMenu: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        const index = Number((e.target as HTMLElement).dataset.index);
        const selection = window.getSelection();
        if (selection && selection.type === 'Range') {
          const indices = getBoundaryIndexesFromSelection(selection);
          if (indices) showOptionsForSelection(e, selection, indices);
        } else if (!isNaN(index)) {
          showOptionsForWordWithIndexAtPosition(
            index,
            { x: e.pageX, y: e.pageY },
            selection,
          );
        }
      },
      [
        getBoundaryIndexesFromSelection,
        showOptionsForSelection,
        showOptionsForWordWithIndexAtPosition,
      ],
    );

  return (
    <Main
      id={'transcript-container'}
      ref={scrollRef}
      tabIndex={-1}
      onWheel={() => {
        setAutoScroll(false);
      }}
      onTouchMove={() => {
        setAutoScroll(false);
      }}
      onClick={(e) => {
        const index = Number((e.target as HTMLElement).dataset.index);
        if (!isNaN(index)) {
          skipToWordWithIndex(index);
        }
      }}
      onContextMenu={handleContextMenu}
      onMouseUp={handleMouseUp}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {contextMenuPosition && contextMenuActions && selectedWordIndex > -1 && (
        <TranscriptContextMenu
          positionXY={contextMenuPosition}
          actions={contextMenuActions}
          actionCallback={contextMenuCallback}
          clientHeight={scrollRef.current?.clientHeight!}
          overridenActionTitles={
            isInKaraokeView && isSubtitlesTranslated
              ? {
                  removeText: 'Remove Karaoke',
                  removeTextLoading: 'Remove Karaoke',
                }
              : undefined
          }
        />
      )}
      {showReplaceModal && (
        <WordReplacementModal
          selection={showReplaceModal}
          transcriptElements={transcriptElements || []}
          isSubtitlesTranslated={isSubtitlesTranslated}
          discard={() => {
            setShowReplaceModal(null);
            setSelectedWordIndex(-1);
          }}
        />
      )}
      <EditTimingSection
        transcriptElements={transcriptElements || []}
        isSubtitlesTranslated={isSubtitlesTranslated}
        showEditTimingModal={showEditTimingModal?.startIndex !== undefined}
        sourceVideoFramerate={videoCreator.getSourceVideoFrameRate()!}
        startIndex={showEditTimingModal?.startIndex || 0}
        endIndex={
          showEditTimingModal?.endIndex || showEditTimingModal?.startIndex || 0
        }
        discard={() => setShowEditTimingModal(null)}
      />
      {showReplaceWarning && !showSubtitles && (
        <RegenerateTranscriptionModal
          type={'transcription'}
          confirm={async () => {
            videoCreator.regenerateTranscription();
            setShowReplaceWarning(false);
          }}
          discard={() => {
            setShowReplaceWarning(false);
          }}
        />
      )}
      {showReplaceWarning && showSubtitles && (
        <RegenerateTranscriptionModal
          type={
            originalTranscription!.language.includes('en')
              ? 'karaoke breaks'
              : 'subtitles'
          }
          confirm={async () => {
            if (originalTranscription!.language.includes('en')) {
              if (process.env.REACT_APP_NEW_KARAOKE == 'true') {
                videoCreator.karaokeProducer.produceKaraoke();
              } else {
                videoCreator.karaokeProducer.createKaraokeBreaksFromExistingElements();
              }
            } else {
              videoCreator.refetchSubtitlesForCurrentVideo();
            }
            setShowReplaceWarning(false);
          }}
          discard={() => {
            setShowReplaceWarning(false);
          }}
        />
      )}

      <Header>
        <Heading>Transcript</Heading>
        <Buttons>
          <LeftSideButtons>
            {enableFixTranscriptButton && (
              <>
                <PanelButton
                  tabIndex={-1}
                  onFocus={() => scrollRef.current!.focus()}
                  onClick={() => videoCreator.fixTranscription()}
                  data-tooltip-id="repair-tooltip"
                  data-tooltip-content="Click if your transcript is misaligned"
                >
                  <RepairIcon strokeColor="#484848" />
                </PanelButton>
                <Tooltip
                  id="repair-tooltip"
                  style={{
                    padding: '8px',
                    fontSize: '12px',
                    lineHeight: '120%',
                    zIndex: '10',
                  }}
                  place="top"
                  positionStrategy="fixed"
                />
              </>
            )}
          </LeftSideButtons>
          <RightSideButtons>
            {originalTranscription &&
              videoCreator.karaokeProducer.hasElements() && (
                <>
                  {showSubtitles && (
                    <PanelButton
                      tabIndex={-1}
                      onFocus={(e) => scrollRef.current!.focus()}
                      onClick={() => setShowReplaceWarning(true)}
                    >
                      <ReplaceIcon strokeColor="#484848" />
                    </PanelButton>
                  )}
                  <div style={{ height: '20px' }}>
                    <PanelButton
                      tabIndex={-1}
                      outlined={true}
                      isPressed={showSubtitles}
                      onFocus={(e) => scrollRef.current!.focus()}
                      onClick={() => {
                        analytics.track('transcript_toggle_karaoke', {
                          storyId: videoCreator.story?.id,
                          storyTitle: videoCreator.story?.title,
                          videoId: videoCreator.currentVideo?.id,
                          videoTitle: videoCreator.currentVideo?.title,
                        });
                        videoCreator.sidebarOptions = SidebarOption.karaoke;
                        videoCreator.setShowSubtitles(!showSubtitles);
                      }}
                    >
                      <SubtitlesButton>KARAOKE</SubtitlesButton>
                    </PanelButton>
                  </div>
                </>
              )}
            {inDebugMode() && (
              <PanelButton
                tabIndex={-1}
                onFocus={(e) => scrollRef.current!.focus()}
                onClick={() => setDebugEnabled(true)}
              >
                <SubtitlesButton>DEBUG</SubtitlesButton>
              </PanelButton>
            )}
            <PanelButton
              tabIndex={-1}
              onFocus={(e) => scrollRef.current!.focus()}
              isPressed={showHistory}
              onClick={() =>
                startTransition(() => {
                  analytics.track('transcript_toggle_redline', {
                    storyId: videoCreator.story?.id,
                    storyTitle: videoCreator.story?.title,
                    videoId: videoCreator.currentVideo?.id,
                    videoTitle: videoCreator.currentVideo?.title,
                  });
                  setShowHistory((cur) => !cur);
                })
              }
            >
              <TranscriptHistoryIcon />
            </PanelButton>
          </RightSideButtons>
        </Buttons>
      </Header>
      <ScrollableText
        id="transcript-scrollable-container"
        data-testid="transcript-scrollable-container"
      >
        <TranscriptContent
          videoCreator={videoCreator}
          transcriptionUrl={videoCreator.transcriptionUrl}
          transcriptionLoadingStatus={videoCreator.transcriptionLoadingStatus}
          isEmpty={!transcriptElements?.length}
          showSubtitles={showSubtitles}
          isSubtitlesTranslated={isSubtitlesTranslated}
          isInKaraokeView={isInKaraokeView}
          showHistory={showHistory}
          cursor={cursor}
          transcriptBlocks={transcriptBlocks}
          setShowReplaceWarning={setShowReplaceWarning}
        />
      </ScrollableText>
      {photoSelection && (
        <Modal
          isOpen={true}
          closeModal={() => togglePhotoSelectionModal(null)}
          // paddingHorizontal="0"
          // allowPropagation
        >
          <ModalWrapper>
            <PhotoModal
              TopComponent={
                <PhotoModalTop
                  replaceAction={handleAddPhoto}
                  title="Swap or search for a new image."
                  isSelected={!!selectedImage}
                  origin="transcript"
                  selectedImage={selectedImage}
                />
              }
              showDescription
              otherFields={['stock', 'ai', 'org_photos']}
              onCloseSelf={() => togglePhotoSelectionModal(null)}
              openPrevModal={() => {}}
              selectedImageUrl={selectedImage?.url || null}
              setSelectedImage={setSelectedImage}
              from="transcript"
              searchBarRadius="10px"
            />
          </ModalWrapper>
        </Modal>
      )}
      {debugEnabled && (
        <DebugModal
          title="Transcript Debug"
          content={transcriptElements}
          onClose={() => {
            setDebugEnabled(false);
          }}
        />
      )}
    </Main>
  );
});

const TranscriptContent = React.memo(
  ({
    videoCreator,
    transcriptionUrl,
    transcriptionLoadingStatus,
    isEmpty,
    showSubtitles,
    isSubtitlesTranslated,
    isInKaraokeView,
    showHistory,
    cursor,
    transcriptBlocks,
    setShowReplaceWarning,
  }: {
    videoCreator: VideoCreatorStore;
    transcriptionUrl?: string;
    transcriptionLoadingStatus: TranscriptionStatus;
    isEmpty: boolean;
    showSubtitles: boolean;
    isSubtitlesTranslated: boolean;
    isInKaraokeView: boolean;
    showHistory: boolean;
    cursor: { from: number; to: number };
    transcriptBlocks: TranscriptElementBlock[];
    setShowReplaceWarning: (show: boolean) => void;
  }) => {
    if (
      videoCreator.storyId &&
      !transcriptionUrl &&
      transcriptionLoadingStatus !== TranscriptionStatus.Failed &&
      transcriptionLoadingStatus !== TranscriptionStatus.GenerationFailed
    ) {
      return (
        <div>
          <div style={{ width: '100%' }}>Transcript is regenerating...</div>
          {transcriptionLoadingStatus === 'none' && (
            <div>
              Click{' '}
              <PanelButton onClick={() => setShowReplaceWarning(true)}>
                <ReplaceIcon />
              </PanelButton>{' '}
              to Re-generate again
            </div>
          )}
        </div>
      );
    }

    if (
      !transcriptionUrl &&
      (transcriptionLoadingStatus === TranscriptionStatus.Failed ||
        transcriptionLoadingStatus === TranscriptionStatus.GenerationFailed)
    ) {
      return (
        <div>
          <div style={{ width: '100%' }}>
            Failed to generate the transcript.
          </div>
          <div>
            Click{' '}
            <PanelButton onClick={() => setShowReplaceWarning(true)}>
              <ReplaceIcon />
            </PanelButton>{' '}
            to try again
          </div>
          <div>Or contact developers about the issue</div>
        </div>
      );
    }
    if (
      transcriptionUrl &&
      transcriptionLoadingStatus === TranscriptionStatus.Loading
    ) {
      return <div>Transcription is loading...</div>;
    }
    if (
      transcriptionUrl &&
      transcriptionLoadingStatus === TranscriptionStatus.Failed
    ) {
      return <div>Transcription loading failed. Try refreshing the page.</div>;
    }
    if (transcriptionLoadingStatus === TranscriptionStatus.Failed && isEmpty) {
      return <div>Transcription loading failed. Try refreshing the page.</div>;
    }
    if (showSubtitles && isSubtitlesTranslated) {
      return <KaraokeBreaksSubtitlesView cursor={cursor} />;
    } else if (isInKaraokeView) {
      return <KaraokeBreaksView cursor={cursor} />;
    } else if (showHistory) {
      return <TranscriptionDiffNew cursor={cursor} blocks={transcriptBlocks} />;
    } else {
      return <TranscriptionText cursor={cursor} />;
    }
  },
);

const Main = styled.div`
  height: calc(100% - 16px);
  box-sizing: border-box;
  max-width: 380px;
  min-width: 200px;
  font-size: 14px;
  margin-left: 32px;
  overflow: hidden;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const ScrollableText = styled.div.withConfig({
  displayName: 'ScrollableText',
})`
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding-right: 14px;
  padding-bottom: 42px;
  box-sizing: border-box;
  line-height: 175%;
  margin-top: 12px;
  float: left;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.span`
  color: #f2d093;
  font-weight: 700;
  line-height: normal;
  margin: 4px 0;
`;

const Buttons = styled.div`
  height: 26px;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-left: 6px;
  padding-right: 6px;
`;

const PanelButton = styled.button.attrs(
  (props: { isPressed: boolean; outlined: boolean }) => props,
)`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) => (props.isPressed ? '#F2D093' : 'transparent')};
  color: ${(props) => (props.isPressed ? '#030419' : '#484848')};
  border: ${(props) =>
    props.outlined
      ? `1px solid ${props.isPressed ? '#F2D093' : '#484848'}`
      : 'none'};

  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const SubtitlesButton = styled.span`
  font-size: 10px;
`;

const RightSideButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
`;

const LeftSideButtons = styled(RightSideButtons)`
  gap: 0px;
`;

const ModalWrapper = styled.div`
  background-color: #030419;
  color: #f3e9d7;
  width: 500px;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 20px 0;
  border: 1px solid #484848;
`;

const customLog = (label: string, item: any) => {
  console.log(`=== ${label}:`, JSON.parse(JSON.stringify(item)));
};
