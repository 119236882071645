// KaraokeTranscriptMuter.ts
import { TranscriptElement } from '../../types.ts/transcript';

import { FILLER_WORDS } from '../constants/karaokeConstants';

interface KaraokeConfig {
  hideFillers?: boolean;
  hideComma?: boolean;
  hidePeriod?: boolean;
}

export default class KaraokeTranscriptMuter {
  private elements: TranscriptElement[];
  private config: KaraokeConfig;

  constructor(elements: TranscriptElement[], config: KaraokeConfig) {
    this.elements = elements;
    this.config = config;

    this.processElements();
  }

  public getProcessedElements(): TranscriptElement[] {
    return this.elements;
  }

  // Process elements to handle muting/unmuting of fillers and punctuation
  private processElements(): void {
    for (let idx = 0; idx < this.elements.length; idx++) {
      this.processElement(idx);
    }
  }

  private processElement(idx: number): void {
    const element = this.elements[idx];
    if (!element.value) {
      return;
    }

    // Likely taken care of in TimelineClip, but for safety
    if (['removed', 'cut'].includes(element.state ?? '')) {
      return;
    }

    const text = element.value || '';

    // Handle filler words
    if (element.type === 'text' && this.hasFillerWords(text)) {
      if (this.config.hideFillers) {
        // Mute filler word
        element.state = 'muted';
        element.muted_by_hideFillers = true;

        // Mute immediate preceding comma
        this.modifyCommaState(idx, 'muted', 'preceding');

        // Mute immediate trailing comma
        this.modifyCommaState(idx, 'muted', 'trailing');

        // Mute preceding whitespaces, no need to mute trailing whitespaces
        this.modifyWhitespaceState(idx, 'muted', 'preceding');
      } else if (element.muted_by_hideFillers) {
        // Unmute filler word
        delete element.state;
        delete element.muted_by_hideFillers;

        // Unmute immediate preceding comma
        this.modifyCommaState(idx, undefined, 'preceding');

        // Unmute immediate trailing comma
        this.modifyCommaState(idx, undefined, 'trailing');

        // Unmute preceding whitespaces
        this.modifyWhitespaceState(idx, undefined, 'preceding');
      }
    }

    this.handlePunctuation(element, ',', this.config.hideComma ?? false);
    this.handlePunctuation(element, '.', this.config.hidePeriod ?? false);
  }

  private handlePunctuation(
    element: TranscriptElement,
    punctValue: string,
    hideConfig: boolean,
  ): void {
    if (
      element.type === 'punct' &&
      (element.value ?? '').includes(punctValue)
    ) {
      if (hideConfig) {
        if (element.state !== 'muted') {
          element.state = 'muted';
          element.muted_by_hidePunct = true;
        }
      } else {
        // Unset state if it was set by the configuration
        if (element.muted_by_hidePunct && !element.muted_by_hideFillers) {
          delete element.state;
          delete element.muted_by_hidePunct;
        }
      }
    }
  }

  private modifyCommaState(
    idx: number,
    state: 'muted' | undefined,
    direction: 'preceding' | 'trailing',
  ): void {
    let i = direction === 'preceding' ? idx - 1 : idx + 1;

    while (
      i >= 0 &&
      i < this.elements.length &&
      this.isIgnorableElement(this.elements[i])
    ) {
      i = direction === 'preceding' ? i - 1 : i + 1;
    }

    if (i >= 0 && i < this.elements.length) {
      const el = this.elements[i];
      if (el.type === 'punct' && el.value === ',') {
        if (state === 'muted') {
          el.state = 'muted';
          el.muted_by_hideFillers = true;
        } else if (el.muted_by_hideFillers) {
          // Only remove state if it was muted by hideFillers
          delete el.state;
          delete el.muted_by_hideFillers;
        }
      }
    }
  }

  private modifyWhitespaceState(
    idx: number,
    state: 'muted' | undefined,
    direction: 'preceding' | 'trailing',
  ) {
    const moveIdx = (j: number) => (direction === 'preceding' ? j - 1 : j + 1);
    let i = moveIdx(idx);

    while (
      i >= 0 &&
      i < this.elements.length &&
      this.isIgnorableElement(this.elements[i])
    ) {
      i = moveIdx(i);
    }

    if (i >= 0 && i < this.elements.length) {
      // hide all of the encountered whitespaces `j` until a non-ignorable element `i` is reached
      let j = moveIdx(idx);
      while (j !== i && j >= 0 && j < this.elements.length) {
        const el = this.elements[j];
        if (this.isIgnorableElement(el)) {
          if (state === 'muted') {
            el.state = 'muted';
            el.muted_by_hideFillers = true;
          } else if (el.muted_by_hideFillers) {
            delete el.state;
            delete el.muted_by_hideFillers;
          }
        }
        j = moveIdx(j);
      }
    }
  }

  private isIgnorableElement(element: TranscriptElement): boolean {
    // Define ignorable elements (e.g., spaces)
    return element.type === 'punct' && element.value === ' ';
  }

  private hasFillerWords(text: string): boolean {
    const fillerRegex = new RegExp(`\\b(?:${FILLER_WORDS.join('|')})\\b`, 'gi');
    return fillerRegex.test(text);
  }
}
