import React from 'react';
import { observer } from 'mobx-react-lite';
import { ElementState } from '../../renderer/ElementState';

import PropertyDropdown from '../common/PropertyDropdown';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface Props {
  activeElement: ElementState;
  propertyName: string;
  defaultValue: any;
  options: Array<{ caption: string; value: any }>;
}

export const VideoFitPropertySelect: React.FC<Props> = observer((props) => {
  const videoCreator = useVideoCreatorStore();

  return (
    <PropertyDropdown
      value={
        props.activeElement.source[props.propertyName] ?? props.defaultValue
      }
      onChange={async (value) => {
        const elementId = props.activeElement.source.id;
        const modifications = {
          [`${elementId}.${props.propertyName}`]: value,
        };

        if (!videoCreator.hasDefaultAspectRatio()) {
          await videoCreator.reframingModeManager.exitReframingMode();
          modifications[`${elementId}.width`] = '100%';
          modifications[`${elementId}.height`] = '100%';
          modifications[`${elementId}.x`] = '50%';
          modifications[`${elementId}.y`] = '50%';
          if (value === 'cover') {
            await videoCreator.adjustModificationsToAspectRatio(modifications, {
              ...videoCreator.getElementSource(props.activeElement),
              [props.propertyName]: value,
            });
          }
        }

        await videoCreator.applyVideoStateModifications(
          modifications,
          true,
          'applying video fit',
        );

        if (!videoCreator.hasDefaultAspectRatio()) {
          await videoCreator.setActiveElements(elementId);
        }
      }}
      defaultValue={
        props.activeElement.source[props.propertyName] ?? props.defaultValue
      }
      values={props.options.map(({ value, caption }) => ({ value, caption }))}
    />
  );
});
