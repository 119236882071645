import { Client, SimpleSchemaTypes } from '@datocms/cma-client-browser';
import { FileData, myAudio } from '../types.ts/story';
import ApiClient from '../apiClient/ApiClient';
import { AssetRepository } from './AssetRepository';

export class AudioRepository {
  private dClient: Client | ApiClient;
  private assetRepository: AssetRepository;

  constructor(dClient: Client | ApiClient, assetRepository: AssetRepository) {
    this.dClient = dClient;
    this.assetRepository = assetRepository;
  }

  async uploadAudioFiles(files: File[]): Promise<myAudio[]> {
    const itemType = await this.dClient.itemTypes.find('my_audio');

    const uploadResults = await Promise.all(
      files.map(async (file) => {
        const fileName = file.name;
        const newFileData: FileData & { fileName: string } = {
          type: 'audio',
          file,
          fileName,
          alt: '',
          title: '',
        };

        const upload = await this.assetRepository.uploadFile(newFileData);

        const savedAudio = await this.dClient.items.create({
          item_type: { type: 'item_type', id: itemType.id },
          song: { upload_id: upload.id },
          metadata_status: 'generating',
        });

        return {
          id: savedAudio.id,
          duration: null,
          waveformUploadId: null,
          title: '',
          metadataStatus: 'generating' as 'generating',
          song: {
            id: upload.id,
            url: upload.url,
            title: '',
            customData: {} as any,
            tags: [],
          },
        };
      }),
    );

    return uploadResults;
  }

  async updateAudio(
    audioId: string,
    data: Partial<SimpleSchemaTypes.Item>,
  ): Promise<SimpleSchemaTypes.Item> {
    return await this.dClient.items.update(audioId, data);
  }
}
