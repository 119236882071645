import { makeAutoObservable } from 'mobx';
import { UserIdentityStore } from './UserIdentityStore';
import { AnalyticsStore } from './AnalyticsStore';
import { DatoClientStore } from './DatoClientStore';
import type { DatoContext } from './types';
import { TemplatedPromptsStore } from './TemplatedPromptsStore';

interface InitializeParams {
  env: string;
  datoContext?: DatoContext;
}

export class RootStore {
  // Child Stores
  userIdentityStore: UserIdentityStore;
  analyticsStore: AnalyticsStore;
  templatedPromptsStore: TemplatedPromptsStore;
  datoClientStore: DatoClientStore;

  // Base State
  initialized: boolean = false;
  storyId: string | undefined | null = null;

  constructor() {
    this.userIdentityStore = new UserIdentityStore(this);
    this.analyticsStore = new AnalyticsStore(this);
    this.templatedPromptsStore = new TemplatedPromptsStore(this);
    this.datoClientStore = new DatoClientStore(this);

    makeAutoObservable(this, {
      userIdentityStore: false,
      analyticsStore: false,
      templatedPromptsStore: false,
      datoClientStore: false,
    });
  }

  // Initialize the store with the storyId, environment, and Dato context
  // for setting Applications' intial state.
  initialize({ env, datoContext }: InitializeParams) {
    this.userIdentityStore.setEnvironment(env);
    if (!this.initialized) {
      this.analyticsStore.initSmartlook();
    }

    if (datoContext) {
      this.userIdentityStore.setDatoContext(datoContext);
    }

    this.initialized = true;
  }

  // We're ready if we're initialized and have either:
  // 1. Dato context
  // 2. Valid environment for external users
  // 3. API clients are ready
  get isReady(): boolean {
    return (
      this.initialized &&
      (this.userIdentityStore.hasDatoContext ||
        !!this.userIdentityStore.environment) &&
      this.datoClientStore.isReady
    );
  }

  // Helper method to get current auth type
  get authType(): 'dato' | 'clerk' | null {
    if (this.userIdentityStore.hasDatoContext) return 'dato';
    if (this.userIdentityStore.currentIdentity?.source === 'clerk')
      return 'clerk';
    return null;
  }

  // Helper to check if we have valid authentication
  get hasValidAuth(): boolean {
    return this.userIdentityStore.currentIdentity !== null;
  }
}
