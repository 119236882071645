import { FC, useState } from 'react';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';

import { Story } from '../../types.ts/story';
import SpinningLoading from '../SpinningLoading';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface ImagePresetProps {
  activeElement: ElementState;
  url: string;
  story?: Story;
}

export const ImagePreset: FC<ImagePresetProps> = (props) => {
  const videoCreator = useVideoCreatorStore();
  const currentElement = videoCreator.getImageElement(props.activeElement);
  const url = currentElement.source.source;

  const getDescription = () => {
    if (currentElement.source.type === 'image') {
      const photoAssets = [
        ...(videoCreator.story?.storyArtifacts || []),
        ...(videoCreator.story?.storyAssets || []),
        ...(videoCreator.story?.aiPhotos || []),
        ...(videoCreator.organization?.organizationArtifacts || []),
        ...(videoCreator.organization?.organizationLogos || []),
      ];
      return photoAssets.find(
        (p) =>
          p.responsiveImage?.srcSet === url ||
          p.responsiveImage?.src === url ||
          p.url === url,
      )?.title;
    }
    return '';
  };

  return (
    <>
      <Main>
        {videoCreator.savingStockPhoto && (
          <SpinningLoading text="Saving stock image" positionTop="20px" />
        )}
        <ImageWrapper>
          <ImageOverlay />
          <Image src={url} alt="image present" />
          <ReplaceButton
            onClick={() =>
              (videoCreator.openPhotoElementReplacementModal = {
                element: props.activeElement,
              })
            }
          >
            Replace
          </ReplaceButton>
        </ImageWrapper>

        <Description>{getDescription()}</Description>
      </Main>
    </>
  );
};

const Main = styled.div`
  position: relative;
  margin: 15px 0;
  // height: 80px;
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 50%)
    );
  }
`;

const Image = styled.img`
  width: 107px;
  height: 70px;
  border-radius: inherit;
  background-size: cover;
  object-fit: cover;
`;

const ImageWrapper = styled.div`
  width: 107px;
  height: 70px;
  border-radius: 8px;
  position: relative;
`;
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ReplaceButton = styled.button`
  outline: none;
  border: 1px solid #f2d093;
  border-radius: 4px;
  width: 55px;
  height: 28px;
  position: absolute;
  color: #f2d093;
  font-weight: 500;
  font-size: 10px;
  line-height: 12.1px;
  text-align: center;
  background-color: transparent;
  top: 21px;
  left: 26px;
  z-index: 1;
  cursor: pointer;
`;

const Description = styled.span`
  font-color: #d7d7e1;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
`;

const ScrollableArea = styled.div`
  width: 90%;
  padding: 0 10px 15px;
  display: flex;
  flex-direction: column;
`;
