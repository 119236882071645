import { makeAutoObservable } from 'mobx';
import { Client } from '@datocms/cma-client-browser';
import { GraphQLClient } from 'graphql-request';
import ApiClient from '@src/apiClient/ApiClient';
import { buildClient, LogLevel } from '@datocms/cma-client-browser';
import { createGQLClient } from '@src/utility/dato';
import type { RootStore } from './RootStore';
import type { DatoContext } from './types';
import {
  VideoRepository,
  StoryRepository,
  AssetRepository,
  AlbumRepository,
  TranscriptRepository,
  CaptionRepository,
  AudioRepository,
  // TemplateRepository,
  AIFlowRepository,
  AIPromptRepository,
} from '@src/repositories';

export class DatoClientStore {
  private _environment?: string;

  private _datoClient?: Client | ApiClient;
  private _gqlClient?: GraphQLClient;

  // Repositories
  private _repositories: Record<string, any> = {};
  private _videoRepository?: VideoRepository;
  private _storyRepository?: StoryRepository;
  private _assetRepository?: AssetRepository;
  private _albumRepository?: AlbumRepository;
  private _transcriptRepository?: TranscriptRepository;
  private _captionRepository?: CaptionRepository;
  private _audioRepository?: AudioRepository;
  // private _templateRepository?: TemplateRepository;
  private _aiFlowRepository?: AIFlowRepository;
  private _aiPromptRepository?: AIPromptRepository;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {
      // rootStore: false,
    });
  }

  initializeClients() {
    const { userIdentityStore } = this.rootStore;
    const { datoContext, clerkToken, environment } = userIdentityStore;

    this._environment = environment;

    console.debug('Initializing Dato and GraphQL clients with:', {
      datoContext,
      clerkToken,
      environment,
    });

    // Initialize Dato Client
    if (datoContext?.currentUserAccessToken) {
      this._datoClient = buildClient({
        apiToken: datoContext.currentUserAccessToken,
        environment: environment,
        logLevel: LogLevel.NONE,
      });
    } else if (clerkToken) {
      this._datoClient = new ApiClient({
        environment: environment,
        authToken: clerkToken,
      });
    } else {
      this._datoClient = buildClient({
        apiToken: process.env.REACT_APP_DATOCMS_API_TOKEN!,
        environment: environment,
        logLevel: LogLevel.NONE,
      });
    }
    // Initialize GraphQL Client
    this._gqlClient = createGQLClient({
      includeDrafts: true,
      excludeInvalid: true,
      environment: environment,
      authToken: this.gqlAuthToken,
    });

    // Initialize repositories
    this.initializeRepositories();
  }

  get gqlAuthToken(): string {
    return process.env.REACT_APP_DATOCMS_READ_API_TOKEN!;
  }

  private initializeRepositories() {
    if (!this._datoClient || !this._gqlClient) {
      throw new Error('Clients must be initialized before repositories');
    }

    this._videoRepository = new VideoRepository(
      this._datoClient,
      this._gqlClient,
    );
    this._storyRepository = new StoryRepository(
      this._datoClient,
      this._gqlClient,
    );
    this._assetRepository = new AssetRepository(
      this._datoClient,
      this._gqlClient,
    );
    this._albumRepository = new AlbumRepository(
      this._datoClient,
      this._gqlClient,
    );
    this._transcriptRepository = new TranscriptRepository(
      this._datoClient,
      this._gqlClient,
    );
    this._captionRepository = new CaptionRepository(this._datoClient);
    this._audioRepository = new AudioRepository(
      this._datoClient,
      this._assetRepository,
    );
    // this._templateRepository = new TemplateRepository(
    //   this._datoClient,
    //   this._gqlClient,
    // );
    this._aiFlowRepository = new AIFlowRepository(
      this._datoClient,
      this._gqlClient,
    );
    this._aiPromptRepository = new AIPromptRepository(this._gqlClient);

    // Assign repositories to the _repositories object for programmatic access
    // ie loops, etc
    this._repositories = {
      videoRepository: this._videoRepository,
      storyRepository: this._storyRepository,
      assetRepository: this._assetRepository,
      albumRepository: this._albumRepository,
      transcriptRepository: this._transcriptRepository,
      captionRepository: this._captionRepository,
      audioRepository: this._audioRepository,
      // templateRepository: this._templateRepository,
      aiFlowRepository: this._aiFlowRepository,
      aiPromptRepository: this._aiPromptRepository,
    };
  }

  // Client getters
  get datoClient(): Client | ApiClient {
    if (!this._datoClient) {
      throw new Error('Dato client not initialized');
    }
    return this._datoClient;
  }

  get gqlClient(): GraphQLClient {
    if (!this._gqlClient) {
      throw new Error('GraphQL client not initialized');
    }
    return this._gqlClient;
  }

  get environment(): string {
    if (!this._environment) {
      throw new Error('Environment not initialized');
    }
    return this._environment;
  }

  // Repository getters
  get videoRepository(): VideoRepository {
    if (!this._videoRepository) {
      throw new Error('Video repository not initialized');
    }
    return this._videoRepository;
  }

  get storyRepository(): StoryRepository {
    if (!this._storyRepository) {
      throw new Error('Story repository not initialized');
    }
    return this._storyRepository;
  }

  get assetRepository(): AssetRepository {
    if (!this._assetRepository) {
      throw new Error('Asset repository not initialized');
    }
    return this._assetRepository;
  }

  get albumRepository(): AlbumRepository {
    if (!this._albumRepository) {
      throw new Error('Album repository not initialized');
    }
    return this._albumRepository;
  }

  get transcriptRepository(): TranscriptRepository {
    if (!this._transcriptRepository) {
      throw new Error('Transcript repository not initialized');
    }
    return this._transcriptRepository;
  }

  get captionRepository(): CaptionRepository {
    if (!this._captionRepository) {
      throw new Error('Caption repository not initialized');
    }
    return this._captionRepository;
  }

  get audioRepository(): AudioRepository {
    if (!this._audioRepository) {
      throw new Error('Audio repository not initialized');
    }
    return this._audioRepository;
  }

  // get templateRepository(): TemplateRepository {
  //   if (!this._templateRepository) {
  //     throw new Error('Template repository not initialized');
  //   }
  //   return this._templateRepository;
  // }

  get aiFlowRepository(): AIFlowRepository {
    if (!this._aiFlowRepository) {
      throw new Error('AI flow repository not initialized');
    }
    return this._aiFlowRepository;
  }

  get aiPromptRepository(): AIPromptRepository {
    if (!this._aiPromptRepository) {
      throw new Error('AI prompt repository not initialized');
    }
    return this._aiPromptRepository;
  }

  get isReady(): boolean {
    const reposReady = Object.values(this._repositories).every(
      (repo) => !!repo,
    );

    return !!(this._datoClient && this._gqlClient && reposReady);
  }
}
