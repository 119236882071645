import OpenAI from 'openai';
import { AiFlowRetryCondition } from '../types.ts/ai_prompts';
import { logChatGptOutput } from '@src/utility/datadog';
import { ServerEventStreamer } from '@src/utility/ServerEventStreamer';

type StreamPayload = any;
type StepResponse = string | StreamPayload[] | null;

export class AIFlowStep {
  id: string;
  label: string;
  systemMessageTemplate: string;
  userPromptTemplate: string;
  lastResult?: any;
  lastResponse?: StepResponse;
  allResponses: StepResponse[];
  systemMessage?: string;
  includeMessagesFromStepId?: string;
  inputMessages?: any[];
  userPrompt?: string;
  attempts: number = -1;
  maxAttempts: number;
  timeElapsed: number = 0;
  models: string[] = ['gpt-4o'];
  config: {
    temperature: number;
  } = {
    temperature: 0.5,
  };
  status: 'pending' | 'finished' | 'running' | 'failed' = 'pending';
  retryCondition: boolean | AiFlowRetryCondition;
  mapper?: (flowContext: any, stepContext: AIFlowStep) => any;
  filter?: (flowContext: any, stepContext: AIFlowStep) => any;
  reducer?: (flowContext: any, stepContext: AIFlowStep) => any;
  logger?: (message: string, messageContext?: object, error?: Error) => void;
  onPartialResult?: (result: any) => void;
  stream: 'text' | 'object' | 'none';
  doneStreaming: boolean = false;
  iterateOver?: string;

  context: any;

  openai = new OpenAI({
    apiKey: process.env.REACT_APP_CHATGPT_API_KEY as string,
    dangerouslyAllowBrowser: true,
    timeout: 60 * 1000,
    maxRetries: 3,
  });

  constructor(params: {
    id: string;
    label: string;
    systemMessageTemplate: string;
    userPromptTemplate: string;
    maxAttempts: number;
    models: string[];
    config: {
      temperature: number;
    };
    retryCondition: boolean | AiFlowRetryCondition;
    includeMessagesFromStepId?: string | null;
    mapper?: (flowContext: any, stepContext: AIFlowStep) => any;
    filter?: (flowContext: any, stepContext: AIFlowStep) => any;
    reducer?: (flowContext: any, stepContext: AIFlowStep) => any;
    iterateOver?: string;
    stream?: 'text' | 'object' | 'none';
  }) {
    this.id = params.id;
    this.label = params.label;
    this.systemMessageTemplate = params.systemMessageTemplate;
    this.userPromptTemplate = params.userPromptTemplate;
    this.allResponses = [];
    this.maxAttempts = params.maxAttempts;
    this.config = params.config;
    this.models = params.models;
    this.retryCondition = params.retryCondition;
    this.mapper = params.mapper;
    this.filter = params.filter;
    this.reducer = params.reducer;
    this.logger = console.log;
    this.iterateOver = params.iterateOver;
    this.stream = params.stream || 'none';
    this.doneStreaming = false;
  }

  async init(context: any, inputMessages?: any[]) {
    this.log(`Initializing step ${this.label}`);
    this.context = context;
    this.inputMessages = inputMessages; //includeMessagesFromStepId
    this.attempts = -1;
    this.timeElapsed = 0;

    this.systemMessage = this.replaceVariablesInTemplate(
      this.systemMessageTemplate,
      context,
    );
    this.userPrompt = this.replaceVariablesInTemplate(
      this.userPromptTemplate,
      context,
    );
    this.lastResult = undefined;
  }

  replaceVariablesInTemplate(
    template: string,
    variablesMap: Record<string, string>,
    iterationElement?: any,
  ) {
    let result = template;
    result = result.replaceAll('{{iterationElement}}', iterationElement);
    for (const [key, value] of Object.entries(variablesMap)) {
      result = result.replaceAll(`{{${key}}}`, value);
    }
    // todo support stepLabel.lastResult
    //debugger;
    return eval('`' + result + '`'); //TODO make safer
  }

  async run() {
    this.log(`Running step ${this.label}`);
    this.status = 'running';
    let messages: any[] = this.inputMessages || [];
    if (messages.length === 0) {
      messages = messages.concat([
        { role: 'system', content: this.systemMessage },
      ]);
    }

    //todo iterate over
    //todo retry logic

    if (this.iterateOver) {
      if (
        this.context[this.iterateOver] &&
        this.context[this.iterateOver].length > 0
      ) {
        for (const item of this.context[this.iterateOver]) {
          const userPrompt = this.replaceVariablesInTemplate(
            this.userPromptTemplate,
            this.context,
            item,
          );
          messages = messages.concat([{ role: 'user', content: userPrompt }]);
          this.inputMessages = messages;
          await this.runStep(messages);
        }
      }
    } else {
      const userPrompt = this.replaceVariablesInTemplate(
        this.userPromptTemplate,
        this.context,
      );
      messages = messages.concat([{ role: 'user', content: userPrompt }]);
      this.inputMessages = messages;
      await this.runStep(messages);
    }
  }

  async runStep(messages: any[]) {
    this.log(`Step ${this.label}, prompts: ${JSON.stringify(messages)}`);
    let retry = true;
    let response: typeof this.lastResponse = null;

    const handleResponse = (
      response: typeof this.lastResponse,
      done: boolean,
    ) => {
      this.lastResponse = response;
      this.doneStreaming = done;
      if (done) {
        logChatGptOutput({ 'stream-ai-clips': response });
      }
      if (this.mapper) {
        try {
          this.lastResult = this.mapper(this.context, this);
        } catch (error) {
          console.error(`Error in mapper function`, error);
          throw error;
        }
      }
      if (this.filter) {
        this.lastResult = this.filter(this.context, this);
      }
      if (this.reducer) {
        this.lastResult = this.reducer(this.context, this);
      }
      try {
        this.onPartialResult && this.onPartialResult(this.lastResult);
      } catch (error) {
        console.error(`Error in handling partial result`, error);
        throw error;
      }
    };

    while (retry) {
      this.attempts += 1;
      const params = {
        temperature: this.config.temperature,
        model: this.models[Math.min(this.attempts, this.models.length - 1)],
      };
      this.log(
        `Step ${this.label}, attempt ${this.attempts}, model: ${params.model}`,
      );
      retry = false;
      if (this.stream === 'text') {
        let streamResponse = '';
        await this.makeStreamCall(messages, params, (chunk, done) => {
          streamResponse += chunk;
          handleResponse(streamResponse, done);
        });
        response = streamResponse || null;
      } else if (this.stream === 'object') {
        let streamResponseEvents: StreamPayload[] = [];
        await this.makeEventStreamCall(messages, params, (payload) => {
          console.log('Got new event message', payload);
          streamResponseEvents.push(payload);
          handleResponse(streamResponseEvents, false);
        });
        handleResponse(streamResponseEvents, true);
        response = streamResponseEvents || null;
      } else {
        response = await this.makeCall(messages, params);
        handleResponse(response, true);
      }
      this.allResponses.push(response);

      this.log(
        `Step ${this.label}, attempt ${
          this.attempts
        }, Streamed response: ${JSON.stringify(
          { response: this.lastResponse! },
          null,
          2,
        )}`,
      );
      if (
        !response &&
        typeof this.retryCondition === 'boolean' &&
        this.retryCondition === true
      ) {
        retry = this.attempts < this.maxAttempts;
      }
      if (typeof this.retryCondition === 'function') {
        retry = this.attempts < this.maxAttempts && this.retryCondition(this);
      }
    }
    this.log(
      `Step ${this.label}, attempt ${
        this.attempts
      }, transformed result: ${JSON.stringify(this.lastResult)}`,
    );
  }

  async makeCall(
    messages: any[],
    config: { temperature: number; model: string },
  ) {
    const completion = await this.openai.chat.completions.create({
      messages,
      ...config,
    });
    let response = completion.choices[0]?.message?.content;
    return response;
  }

  async makeEventStreamCall(
    messages: any[],
    config: { temperature: number; model: string },
    onMessageReceived: (message: any) => void,
  ) {
    await new ServerEventStreamer(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/stream-ai-clips`,
      {
        messages,
        ...config,
      },
    ).start(onMessageReceived, (error) => {
      console.error(error);
      throw error;
    });
  }

  async makeStreamCall(
    messages: any[],
    config: { temperature: number; model: string },
    onChunkReceived: (chunk: string, done: boolean) => void,
  ) {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/stream-ai-clips`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages,
          ...config,
        }),
      },
    );

    const data = res.body;
    if (!data) return null;
    const reader = data.getReader();
    const decoder = new TextDecoder();
    let isDone = false;

    while (!isDone) {
      const { value, done: doneReading } = await reader.read();
      isDone = doneReading;
      let chunk = decoder.decode(value);
      //console.log('chunk', chunk);
      onChunkReceived(chunk, isDone);
    }
  }

  log(message: string) {
    this.logger && this.logger(message);
  }
}
