import { sortBy, uniq } from 'lodash';
import { BrandingCustomFont } from '@src/types.ts/story';

export enum Bold {
  thin = 'Thin',
  extraLight = 'Extra-Light',
  light = 'Light',
  normal = 'Normal',
  medium = 'Medium',
  semiBold = 'Semi-Bold',
  bold = 'Bold',
  extraBold = 'Extra-Bold',
  black = 'Black',
}

export const BoldValues = {
  [Bold.thin]: 100,
  [Bold.extraLight]: 200,
  [Bold.light]: 300,
  [Bold.normal]: 400,
  [Bold.medium]: 500,
  [Bold.semiBold]: 600,
  [Bold.bold]: 700,
  [Bold.extraBold]: 800,
  [Bold.black]: 900,
};

export const DEFAULT_BOLD_VALUES: Bold[] = [
  Bold.normal,
  Bold.semiBold,
  Bold.bold,
  Bold.extraBold,
];

export const isUsingCustomFont = (
  fontFamily: string,
  fonts: BrandingCustomFont[],
) => {
  const brandCustomFontNames = uniq(fonts.map((f) => f.fontFamily));
  return brandCustomFontNames.includes(fontFamily);
};

export const getBoldValuesFromCustomFonts = (
  fontFamily: string,
  fonts: BrandingCustomFont[],
) => {
  const _fonts = sortBy(
    fonts.filter((f) => f.fontFamily === fontFamily && f.style !== 'italic'),
    (f) => f.usWeightClass,
  );
  return {
    boldValues: _fonts.map((f) => f.fontSubfamily) as Bold[],
    boldWeightsByValue: _fonts.reduce(
      (acc, font) => ({ ...acc, [font.fontSubfamily]: font.usWeightClass }),
      {} as Record<string, number>,
    ),
  };
};
