import { GraphQLClient } from 'graphql-request';
import { AIPrompt } from '../types.ts/ai_prompts';
import {
  ALL_AI_PROMPTS_QUERY,
  AI_PROMPT_BY_TITLE_QUERY,
} from '@src/gql/ai-prompts-gql';

export class AIPromptRepository {
  private gqlClient: GraphQLClient;

  constructor(gqlClient: GraphQLClient) {
    this.gqlClient = gqlClient;
  }

  async getAllPrompts(): Promise<AIPrompt[]> {
    try {
      const response = (await this.gqlClient.request(ALL_AI_PROMPTS_QUERY)) as {
        allAiPrompts: AIPrompt[];
      };
      return response.allAiPrompts;
    } catch (err) {
      console.error('Error loading AI prompts', err);
      return [];
    }
  }

  async getPromptByTitle(title: string): Promise<AIPrompt | undefined> {
    try {
      const response = (await this.gqlClient.request(AI_PROMPT_BY_TITLE_QUERY, {
        title,
      })) as { aiPrompt: AIPrompt };
      return response.aiPrompt;
    } catch (err) {
      console.error('Error loading AI prompt by title', err);
      return undefined;
    }
  }
}
