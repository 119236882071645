export function equalsIgnoringCase(string1: string, string2: string) {
  return (
    string1.localeCompare(string2, undefined, { sensitivity: 'base' }) === 0
  );
}

export function removePunctuation(text: string) {
  // remove periods, commas, exclamation marks, question marks
  return text.replace(/[.,;!?]/g, '');
}
