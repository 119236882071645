import type { UserResource } from '@clerk/types';
import { ShowcaseAccess } from '@src/types.ts/story';
import {
  useDatoClient,
  useStore,
  useUserIdentity,
} from '../stores-v2/StoreContext';
import { useNavigate } from 'react-router-dom';
import { redirect } from 'next/dist/server/api-utils';

const getRedirectToSignInLink = (orgSlug?: string) => {
  if (!orgSlug) {
    return `${process.env.REACT_APP_API_URL}`;
  }

  return `${process.env.REACT_APP_API_URL}/showcase/${orgSlug}`;
};

const hasAccessToStory = (
  albums: ShowcaseAccess[] | undefined,
  memberships: Array<{ organization: { id: string; slug: string | null } }>,
  clerkUserPrimaryEmail: string,
) => {
  return !!albums?.find(
    (album) =>
      memberships?.find(
        (memb) =>
          (album.organization && memb.organization.id === album.organization) ||
          (album.slug && memb.organization.slug === album.slug) ||
          album.adminList?.find(
            (admin) => admin.email === clerkUserPrimaryEmail,
          ) ||
          album.allowList?.find(
            (editor) => editor.email === clerkUserPrimaryEmail,
          ),
      ),
  );
};

export function useAccessControl() {
  const userIdentityStore = useUserIdentity();
  const datoClientStore = useDatoClient();

  const checkUserAccess = async (
    storyId: string | null,
    showcase: string | undefined,
  ) => {
    const isDatoUser = userIdentityStore.isInternalUser();
    const clerkUser = userIdentityStore.clerkUser;
    const memberships = userIdentityStore.memberships;

    if (isDatoUser) {
      console.log('Is Dato User - access granted');
      return { hasAccess: true };
    }

    if (!clerkUser) {
      console.log('User is not authenticated - access denied');
      return { hasAccess: false, redirectTo: getRedirectToSignInLink() };
    }

    if (
      clerkUser.emailAddresses.find((e) =>
        e.emailAddress.endsWith('@tryarbor.com'),
      )
    ) {
      console.log('access granted');
      return { hasAccess: true };
    }

    let hasOrgAccess;
    if (showcase) {
      hasOrgAccess = !!memberships.find(
        (memb) => memb.organization.slug === showcase,
      );
      if (!hasOrgAccess) {
        console.log('No org membership - access denied');
        return {
          hasAccess: false,
          redirectTo: getRedirectToSignInLink(showcase),
        };
      }
    }

    if (storyId) {
      const albums =
        await datoClientStore.albumRepository?.findAllWithStoryAccess(storyId);

      const hasStoryAccess = hasAccessToStory(
        albums,
        memberships,
        clerkUser.primaryEmailAddress?.emailAddress || '',
      );

      if (!hasStoryAccess) {
        console.log('No membership in story album - access denied');
        return {
          hasAccess: false,
          redirectTo: getRedirectToSignInLink(albums?.[0]?.slug || ''),
        };
      } else {
        console.log('story access granted');
        return { hasAccess: true };
      }
    } else if (hasOrgAccess) {
      console.log('showcase access granted');
      return { hasAccess: true };
    }

    console.log('access denied');
    return { hasAccess: false, redirectTo: getRedirectToSignInLink() };
  };

  return {
    checkUserAccess,
  };
}
