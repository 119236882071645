import styled from 'styled-components';
import Modal from './common/Modal';

export default function UnsupportedBrowserModal() {
  return (
    <Modal isOpen closeModal={undefined} backgroundColor="rgba(0, 0, 0, 0.75)">
      <Wrapper>
        <Content>
          <Title>This browser is not fully compatible with Arbor</Title>
          <Explanation>We require using Google Chrome.</Explanation>
        </Content>
        <ActionLink target="_blank" href="https://www.google.com/chrome/">
          Download Chrome
        </ActionLink>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  display: flex;
  width: 440px;
  padding: 48px 24px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 16px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 14px 8px 16px 0px rgba(0, 0, 0, 0.4);
  font-family: Inter;
`;

const Title = styled.h1`
  color: #f3e9d7;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  margin: 0;
`;

const Explanation = styled.p`
  color: #f3e9d7;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  margin: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActionLink = styled.a`
  display: flex;
  height: 48px;
  padding: 16px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: none;
  border-radius: 8px;
  background: #17c964;
  color: #03041a;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
`;
