import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { TranscriptElement } from '@src/types.ts/transcript';
import { WordReplacementModalUI } from './WordReplacementModalUI';
import { useState } from 'react';

type Selection = Record<'startIndex' | 'endIndex', number>;

type SelectionWithReplacement = Selection & {
  newValue: string;
  retainState?: boolean;
};

export function WordReplacementModal({
  selection,
  transcriptElements,
  isSubtitlesTranslated,
  discard,
}: {
  selection: Selection;
  discard: () => void;
  transcriptElements: TranscriptElement[];
  isSubtitlesTranslated: boolean;
}) {
  const videoCreator = useVideoCreatorStore();
  const [shouldReplaceAll, setShouldReplaceAll] = useState<boolean>(false);
  const [shouldMatchCase, setShouldMatchCase] = useState<boolean>(false);

  const getText = (selection: Selection): string => {
    return transcriptElements
      .slice(selection.startIndex, selection.endIndex + 1)
      .map((el) => el.value || '')
      .join('');
  };

  const shouldRetainState = (possibleSelection: Selection): boolean => {
    return (
      possibleSelection.startIndex !== selection.startIndex &&
      transcriptElements
        .slice(possibleSelection.startIndex, possibleSelection.endIndex + 1)
        .some((el) => ['removed', 'cut', 'muted'].includes(el.state || ''))
    );
  };

  const findAllOccurrencesOfSelection = (
    replacementText: string,
  ): SelectionWithReplacement[] => {
    const result: SelectionWithReplacement[] = [];
    const occurenceText = getText(selection);
    const occurenceSize = selection.endIndex + 1 - selection.startIndex;
    const makeRegex = () =>
      new RegExp(
        `(?<!\\w)(\\w*-)?${occurenceText}('s|-\\w+)?(?!\\w)`,
        `g${shouldMatchCase ? '' : 'i'}`,
      );

    for (let i = 0; i < transcriptElements.length - occurenceSize; i++) {
      const possibleSelection = {
        startIndex: i,
        endIndex: i + occurenceSize - 1,
      };
      if (makeRegex().test(getText(possibleSelection))) {
        result.push({
          ...possibleSelection,
          newValue: getText(possibleSelection).replace(
            makeRegex(),
            (_match, prefix = '', suffix = '') => {
              return (prefix || '') + replacementText + (suffix || '');
            },
          ),
          retainState: shouldRetainState(possibleSelection),
        });
        i += occurenceSize;
      }
    }

    return result;
  };

  const getSingleOccurrenceOfSelection = (
    replacementText: string,
  ): SelectionWithReplacement => {
    return {
      ...selection,
      newValue: replacementText,
    };
  };

  const submitText = (text: string) => {
    videoCreator.replaceTranscriptionElements(
      shouldReplaceAll
        ? findAllOccurrencesOfSelection(text)
        : [getSingleOccurrenceOfSelection(text)],
      isSubtitlesTranslated,
    );
    discard();
  };

  return (
    <WordReplacementModalUI
      type="input"
      currentText={getText(selection)}
      submitText={submitText}
      discard={discard}
      setShouldReplaceAll={setShouldReplaceAll}
      shouldReplaceAll={shouldReplaceAll}
      setShouldMatchCase={setShouldMatchCase}
      shouldMatchCase={shouldMatchCase}
    />
  );
}
