import { css } from 'styled-components';

export function applyCSSOverrides(
  overrides: Record<string, string | number> = {},
) {
  return (
    overrides &&
    css`
      ${Object.entries(overrides)
        .map(([key, value]) => `${key}: ${value};`)
        .join('\n')}
    `
  );
}
