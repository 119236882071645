import { makeAutoObservable } from 'mobx';
import VideoCreatorStore, {
  KARAOKE_TRACK_NUMBER,
  VIDEO_DEFAULT_WIDTH,
} from '../VideoCreatorStore';
import { ElementState } from '../../renderer/ElementState';
import { AspectRatio } from '../../types.ts/video';
import { deepClone } from '../../utility/deepClone';
import { KaraokeConfig } from '@src/videoTranscriptionProcessor/types/karaokeTypes';

class ReframingModeManager {
  store: VideoCreatorStore;

  actualVideoWidth: number | null = null;
  rectangleTrack: number | null = null;
  relevantElements: ElementState[] = [];
  karaokeConfigToRestore: KaraokeConfig | null = null;

  isToggleAllowed: boolean = true;

  constructor(store: VideoCreatorStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  public async toggleReframingMode() {
    if (!this.isToggleAllowed) {
      return;
    }
    const activeElement = this.findActiveFrameableElement();
    if (activeElement) {
      await this.enterReframingMode();
    } else {
      await this.exitReframingMode();
    }
  }

  public allowToggle(value: boolean) {
    this.isToggleAllowed = value;
  }

  private findActiveFrameableElement(): ElementState | undefined {
    if (this.store.currentVideo!.aspectRatio === AspectRatio.AR_16_9) {
      return undefined;
    }
    const activeElement = this.store.getActiveElement();
    return activeElement && this.isFrameableElement(activeElement)
      ? activeElement
      : undefined;
  }

  private isFrameableElement(element: ElementState): boolean {
    return (
      this.store.isRegularImageElement(element) ||
      this.store.isVideoElement(element)
    );
  }

  private isRelevantElement(element: ElementState): boolean {
    return (
      this.isFrameableElement(element) ||
      this.isTextElement(element) ||
      this.isLogoElement(element)
    );
  }

  private isTextElement(element: ElementState): boolean {
    return element.source.type === 'text';
  }

  private isKaraokeElement(
    element: ElementState | ElementState['source'],
  ): boolean {
    return element.track === KARAOKE_TRACK_NUMBER;
  }

  private isLogoElement(element: ElementState): boolean {
    return this.store.isLogoElement(element);
  }

  private getRelevantElements(): ElementState[] {
    if (this.store.currentVideo!.aspectRatio === AspectRatio.AR_16_9) {
      return [];
    }
    return (this.store.renderer?.state?.elements || []).filter((element) =>
      this.isRelevantElement(element),
    );
  }

  private getElementSource(element: ElementState): ElementState['source'] {
    return this.store.getElementSource(element);
  }

  private getVideoSource(): Record<string, any> {
    return this.store.renderer!.getSource();
  }

  private async setVideoSource(videoSource: Record<string, any>) {
    this.store.currentVideo!.videoSource = videoSource;
    await this.store.renderer?.setSource(videoSource);
  }

  private async enterReframingMode() {
    if (this.isModeActive()) {
      return;
    }

    const videoSource = this.getVideoSource();

    this.actualVideoWidth = videoSource.width;
    this.rectangleTrack = this.store.getMaxTrack() + 1;
    this.relevantElements = this.getRelevantElements();

    await this.setVideoSource(this.modifyVideoSourceForEnter(videoSource));

    this.karaokeConfigToRestore =
      this.store.karaokeProducer!.getKaraokeConfig();
    this.store.karaokeProducer!.setConfig(
      this.modifyKaraokeConfigForEnter(this.karaokeConfigToRestore),
    );
  }

  private modifyKaraokeConfigForEnter(karaokeConfig: KaraokeConfig) {
    let newKaraokeConfigModifiable = {
      width: karaokeConfig.width,
      x: karaokeConfig.x,
    };
    this.scaleRelevantElementToReframingMode(
      newKaraokeConfigModifiable,
      karaokeConfig,
    );
    return { ...karaokeConfig, ...newKaraokeConfigModifiable };
  }

  public restoreKaraokeConfigForExit(
    karaokeConfig?: KaraokeConfig,
  ): KaraokeConfig {
    return this.karaokeConfigToRestore || karaokeConfig!;
  }

  private modifyVideoSourceForEnter(
    videoSourceInput: Record<string, any>,
  ): Record<string, any> {
    const videoSource = deepClone(videoSourceInput);
    videoSource.width = VIDEO_DEFAULT_WIDTH;

    const elements = videoSource.elements;
    for (let i = 0; i < elements.length; i++) {
      const relevantIndex = this.getRelevantIndexOf(elements[i]);
      if (relevantIndex !== -1) {
        this.scaleElementToReframingMode(elements[i], relevantIndex);
      }
    }
    this.insertPreviewRectangles(videoSource, {
      time: 0,
      duration: this.store.duration,
    });

    return videoSource;
  }

  public restoreVideoSourceForExit(
    videoSourceInput: Record<string, any>,
  ): Record<string, any> {
    if (!this.isModeActive()) {
      return videoSourceInput;
    }

    const videoSource = deepClone(videoSourceInput);
    videoSource.width = this.actualVideoWidth;

    const elements = videoSource.elements;
    for (let i = 0; i < elements.length; i++) {
      const relevantIndex = this.getRelevantIndexOf(elements[i]);
      if (relevantIndex !== -1) {
        this.scaleElementFromReframingMode(elements[i], relevantIndex);
      } else if (this.isKaraokeElement(elements[i])) {
        // karaoke elements might not be found after refresh because ids have changed
        // need to use config as reference instead of relevantElements
        this.scaleRelevantElementFromReframingMode(
          elements[i],
          this.karaokeConfigToRestore!,
        );
      }
      if (this.isPreviewRectanglesElement(elements[i])) {
        this.removePreviewRectanglesAt(i, videoSource);
        i--;
      }
    }

    return videoSource;
  }

  public async exitReframingMode() {
    if (!this.isModeActive()) {
      return;
    }

    const videoSource = this.restoreVideoSourceForExit(this.getVideoSource());

    this.actualVideoWidth = null;
    this.rectangleTrack = null;
    this.relevantElements = [];

    await this.setVideoSource(videoSource);
    await this.unselectActiveElement();

    this.store.karaokeProducer!.setConfig(this.restoreKaraokeConfigForExit());
    this.karaokeConfigToRestore = null;
  }

  private getRelevantIndexOf(elementSource: ElementState['source']): number {
    return this.relevantElements.findIndex(
      (el) => el.source.id === elementSource.id,
    );
  }

  private async unselectActiveElement() {
    await this.store.setActiveElements();
  }

  private scaleElementToReframingMode(
    elementSource: ElementState['source'],
    relevantIndex: number,
  ) {
    const relevantElement = this.relevantElements[relevantIndex];
    if (!relevantElement) {
      return;
    }
    this.scaleRelevantElementToReframingMode(
      elementSource,
      this.getElementSource(relevantElement),
    );
  }

  private scaleRelevantElementToReframingMode(
    elementSource: ElementState['source'],
    relevantElementSource: ElementState['source'],
  ) {
    const prevWidth = parseFloat(relevantElementSource.width || '100');
    const currWidth = this.getInitialScaledWidth(relevantElementSource);

    elementSource.width = `${currWidth}%`;

    const prevX = parseFloat(relevantElementSource.x || '50');
    if (prevX !== 50) {
      const scaledX =
        ((prevX - 50 + (prevWidth / currWidth) * 50) * currWidth) / prevWidth;
      elementSource.x = `${scaledX}%`;
    }
  }

  private getInitialScaledWidth(
    relevantElementSource: ElementState['source'],
  ): number {
    const prevWidth = parseFloat(relevantElementSource.width || '100');
    return (prevWidth * this.actualVideoWidth!) / VIDEO_DEFAULT_WIDTH;
  }

  private scaleElementFromReframingMode(
    elementSource: ElementState['source'],
    relevantIndex: number,
  ) {
    const relevantElement = this.relevantElements[relevantIndex];
    if (!relevantElement) {
      return;
    }
    this.scaleRelevantElementFromReframingMode(
      elementSource,
      this.getElementSource(relevantElement),
    );
  }

  private scaleRelevantElementFromReframingMode(
    elementSource: ElementState['source'],
    relevantElementSource: ElementState['source'],
  ) {
    const currWidth = parseFloat(elementSource.width || '100');
    const prevWidth = parseFloat(relevantElementSource.width || '100');
    const nextWidth =
      (prevWidth * currWidth) /
      this.getInitialScaledWidth(relevantElementSource);

    const currX = parseFloat(elementSource.x || '50');
    const xDiff = (currX - 50) * (nextWidth / currWidth);
    const nextX = 50 + xDiff;

    elementSource.x = `${nextX}%`;
    elementSource.width = `${nextWidth}%`;
  }

  private insertPreviewRectangles(
    videoSource: Record<string, any>,
    elementSource: ElementState['source'],
  ) {
    const rectangleWidth = Number(
      (VIDEO_DEFAULT_WIDTH - this.actualVideoWidth!) / 2,
    ).toFixed(4);
    videoSource.elements.push({
      ...elementSource,
      type: 'composition',
      track: this.rectangleTrack,
      locked: true,
      elements: [
        this.makeReframingRectangleElement({
          x: rectangleWidth,
          width: rectangleWidth,
        }),
        this.makeReframingRectangleElement({
          x: '100%',
          width: rectangleWidth,
        }),
        ...this.makeReframingDashedLineElements({
          x: rectangleWidth,
          width: 3,
        }),
        ...this.makeReframingDashedLineElements({
          x: Number(
            Number(rectangleWidth) + this.actualVideoWidth! + 3,
          ).toFixed(4),
          width: 3,
        }),
      ].map((el, index) => ({ ...el, track: index + 1 })),
    });
  }

  private removePreviewRectanglesAt(
    index: number,
    videoSource: Record<string, any>,
  ) {
    videoSource.elements.splice(index, 1);
  }

  public isPreviewRectanglesElement(
    elementSource: ElementState['source'],
  ): boolean {
    return (
      elementSource.type === 'composition' &&
      elementSource.elements?.length >= 4 &&
      elementSource.elements.every(
        (el: Record<string, any>) => el.type === 'shape',
      )
    );
  }

  private makeReframingRectangleElement(
    variableProperties: Record<'x' | 'width', string | number>,
  ): Record<string, string | number | boolean | null | undefined> {
    return {
      type: 'shape',
      time: 0,
      height: '100%',
      x_anchor: '100%',
      locked: true,
      fill_color: 'rgb(3, 4, 26, 0.7)',
      path: 'M 0 0 L 100 0 L 100 100 L 0 100 L 0 0 Z',
      ...variableProperties,
    };
  }

  private makeReframingDashedLineElements(
    variableProperties: Record<'x' | 'width', string | number>,
  ): Record<string, string | number | boolean | null | undefined>[] {
    const totalDashes = 40;
    return Array(totalDashes)
      .fill('')
      .map((_, i) => {
        const dashHeight = 100 / (totalDashes * 2); // Height of each dash
        return {
          ...this.makeReframingRectangleElement(variableProperties),
          fill_color: '#006FEE',
          height: `${dashHeight}%`,
          y: `${i * dashHeight * 2}%`, // Move each dash down by its height + a gap
          y_anchor: '0%',
        };
      });
  }

  private isModeActive(): boolean {
    return this.rectangleTrack != null;
  }
}

export default ReframingModeManager;
