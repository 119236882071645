import { useDatoQuerySubscription } from '@src/hooks/useDatoQuerySubscription';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

export const useQuerySharedContent = (ids: string[]) => {
  const videoCreator = useVideoCreatorStore();
  const { status, error, data } = useDatoQuerySubscription<
    { allSharedContents: { id: string; ayrId: string; postUrl: string }[] },
    { ids: string[] }
  >({
    query: `query getSharedContents($ids: [ItemId]) {
        allSharedContents(filter: {id: {in: $ids}, postUrl: {isPresent: "true"}}) {
          id
          ayrId
          postUrl
        }
    }`,
    variables: { ids },
  });

  return { status, error, data };
};
