import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { uniqBy } from 'lodash';

import { VideoClip } from '../../types.ts/story';
import { handleCopyToClipboard } from '../../utility/general';

import Modal from '../common/Modal';

import LinkIcon from '../../svgs/LinkIcon';
import ShareIcon from '../../svgs/ShareIcon';
import EarthIcon from '../../svgs/EarthIcon';
import CheckIconAlt from '../../svgs/CheckIconAlt';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useStore } from '@src/stores-v2/StoreContext';

type Props = {
  clip: VideoClip;
  selectedDimension: string | null;
  onClose: () => void;
};

const ClipShareUnlistedLinkModal = (props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { datoClientStore } = useStore();
  const { clip, selectedDimension, onClose } = props;

  const [downloadsEnabled, setDownloadsEnabled] = useState(
    !!clip.isDownloadEnabled,
  );
  const [downloadsEnabledLoading, setDownloadsEnabledLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const link = useMemo(() => {
    const arParam = selectedDimension
      ? `?aspectRatio=${encodeURIComponent(selectedDimension)}`
      : '';
    const _link = `${process.env.REACT_APP_API_URL}/clips/${clip.slug}/${clip.hash}${arParam}`;
    return _link;
  }, [clip, selectedDimension]);

  const handleCopyLink = async () => {
    await handleCopyToClipboard(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleToggleDownloads = async () => {
    const current = downloadsEnabled;
    try {
      setDownloadsEnabledLoading(true);
      setDownloadsEnabled(!current);
      const relatedClips = uniqBy(
        [
          ...clip.associatedVideos,
          ...(clip._allReferencingVideos || []),
          ...(clip._allReferencingVideos?.flatMap((v) => v.associatedVideos) ||
            []),
        ],
        (v) => v.id,
      ).filter((v) => v.videoStatus === 'rendered' && v.id !== clip.id);
      const requests = [
        datoClientStore.videoRepository?.saveOrUpdateVideo({
          id: clip.id,
          isDownloadEnabled: !downloadsEnabled,
        }),
        relatedClips.map(
          (video) =>
            datoClientStore.videoRepository?.saveOrUpdateVideo({
              id: video.id,
              isDownloadEnabled: !downloadsEnabled,
            }),
        ),
      ];
      await Promise.all(requests);
    } catch (error) {
      console.error('Error toggling video downloads', error);
      setDownloadsEnabled(current);
    } finally {
      setDownloadsEnabledLoading(false);
    }
  };

  return (
    <Modal isOpen closeModal={onClose}>
      <SClipShareUnlistedLinkModal
        isChecked={downloadsEnabled}
        isToggleDisabled={downloadsEnabledLoading}
      >
        <h1>Get Unlisted Link</h1>
        <input type="text" disabled value={link} />
        <div className="message">
          <EarthIcon />
          <span>Anyone with the link can access the video.</span>
        </div>
        <div className="toggle-download">
          <div
            className="checkbox"
            onClick={
              !downloadsEnabledLoading ? handleToggleDownloads : undefined
            }
          >
            {downloadsEnabled ? <CheckIconAlt /> : null}
          </div>
          <span>Allow Video Download</span>
        </div>
        <div className="actions">
          <Button onClick={handleCopyLink} disabled={copied}>
            {!copied && <LinkIcon strokeColor="#03041A" width="18" />}
            {copied ? 'Link Copied!' : 'Copy Link'}
          </Button>
          <a target="_blank" href={link} rel="noopener noreferrer">
            <Button>
              <ShareIcon strokeColor="#03041A" width="16" height="16" />
              Open Link
            </Button>
          </a>
        </div>
      </SClipShareUnlistedLinkModal>
    </Modal>
  );
};

export default ClipShareUnlistedLinkModal;

const SClipShareUnlistedLinkModal = styled.div<{
  isChecked: boolean;
  isToggleDisabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 360px;
  padding: 24px 16px;
  border-radius: 16px;
  border: 1px solid #484848;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  color: #f3e9d7;
  h1 {
    margin: 0;
    color: #f3e9d7;
    font-size: 20px;
    font-weight: 600;
  }
  input[type='text'] {
    display: flex;
    height: 36px;
    padding: 0 16px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    gap: 10px;
    color: #bdbdbd;
    background: none;
    outline: none;
    border-radius: 8px;
    border: 1px solid #333;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
  }
  .message {
    margin-top: -8px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #848484;
    font-size: 10px;
    font-weight: 500;
  }
  .toggle-download {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    ${(props) =>
      props.isToggleDisabled &&
      css`
        opacity: 0.5;
      `}
    .checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      background-color: ${(props) =>
        props.isChecked ? '#4AD067' : 'transparent'};
      width: ${(props) => (props.isChecked ? '16px' : '12px')};
      height: ${(props) => (props.isChecked ? '16px' : '12px')};
      border: ${(props) => !props.isChecked && '2px solid #848484'};
      cursor: pointer;
      ${(props) =>
        props.isToggleDisabled &&
        css`
          cursor: not-allwowed;
          pointer-events: none;
        `}
    }
    span {
      color: #848484;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: none;
    }
  }
`;

const Button = styled.button`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  background-color: #f2d093;
  color: #03041a;
  border: none;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
