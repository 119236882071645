import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import SpinningLoading from '../SpinningLoading';
import { Circle } from '../../styles/mainStyle';
import { ImageKey, ImageWithType } from '../../types.ts/general';

import BackIcon from '../../svgs/BackIcon';
import { PhotoTab } from '../../types.ts/story';
import SearchInput from '../sidepanel/SearchInput';
import CloseIcon from '../../svgs/CloseIcon';
import PhotoIcon from '../../svgs/PhotoIcon';
import { generateImagesWithGPT } from '../../utility/processGPTData';
import ChatGPTService from '../../services/ChatGPTService';
import ShareableImageView from './ShareableImageView';
import applyImageOptimizations from '../../utility/applyImageOptimizations';
import {
  Attribution,
  searchPhotos,
  getAttributionForPhoto,
} from '../../utility/unsplash';
import { UnsplashAttribution, AuthorAttribution } from './UnsplashAttributions';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useDatoClient } from '@src/stores-v2/StoreContext';

type Props = {
  openPrevModal: () => void;
  onCloseSelf: () => void;
  modalItemType?: ImageKey;
  otherFields?: Array<'ai' | 'stock' | 'quotes' | 'org_photos' | 'org_logos'>;
  externalPhotos?: {
    ai?:
      | (Img & {
          id: string;
        })[]
      | undefined;
    stock: Img[] | undefined;
  };
  isLoading?: boolean;
  setSelectedImage: (img: ImageWithType[ImageKey] | null) => void;
  selectedImageUrl: string | null;
  keywords?: string[];
  setKeywords?: Dispatch<SetStateAction<string[]>>;
  dallePrompt?: string;
  setDallePrompt?: Dispatch<SetStateAction<string>>;
  TopComponent?: React.ReactNode;
  BottomComponent?: React.ReactNode;
  searchBarRadius?: string;
  searchText?: string;
  setSearchText?: Dispatch<SetStateAction<string>>;
  from?: string;
  showDescription?: boolean;
};

export type Img = {
  url: string;
  alt: string;
  width: string;
  height: string;
  description?: string;
  attribution?: Attribution;
};

type QuoteImg = Img & {
  quote: string;
  storytellerName?: string;
  shareableImageId: string;
};

type Asset<T extends PhotoTab> = {
  key: T;
  value: T extends PhotoTab.ai ? (Img & { id: string })[] : Img[];
};

const PhotoModal = (props: Props) => {
  const { otherFields = [], searchBarRadius, modalItemType } = props;
  const videoCreator = useVideoCreatorStore();
  const datoCientStore = useDatoClient();
  const hasOrgLogos = otherFields.includes('org_logos');
  const [stockItems, setStockItems] = useState<Img[] | undefined>(undefined);
  const [aiItems, setAiItems] = useState<Img[] | undefined>(undefined);
  const [stockLoading, setStockLoading] = useState<boolean>(false);
  const [aiLoading, setAiLoading] = useState<boolean>(false);
  const { keywords, setKeywords, dallePrompt, setDallePrompt } = props;
  const [searched, setSearched] = useState<Record<ImageKey, boolean>>();
  let hasStocks = !!(
    otherFields.includes('stock') || props.externalPhotos?.stock
  );
  let hasAis = !!(otherFields.includes('ai') || props.externalPhotos?.ai);
  let hasQuotes = otherFields.includes('quotes');
  let hasOrgArtifacts = otherFields.includes('org_photos');
  if (hasOrgLogos) {
    hasStocks = false;
    hasAis = false;
    hasQuotes = false;
    hasOrgArtifacts = false;
  }

  const artifactsOriginal = videoCreator.story?.storyArtifacts
    ?.filter((a) => a.responsiveImage)
    .map((a) => ({
      id: a.id,
      alt: a.title,
      description: a.title,
      url: a.url,
      width: a.width,
      height: a.height,
    })) as Img[];
  const stock =
    videoCreator?.story?.storyAssets
      ?.filter((a) => a.responsiveImage)
      .map((a) => ({
        id: a.id,
        alt: a.title,
        description: a.title,
        url: a.url,
        width: a.width,
        height: a.height,
      })) || [];

  const ai =
    videoCreator?.story?.aiPhotos
      ?.filter((a) => a.responsiveImage)
      .map((a) => ({
        id: a.id,
        alt: a.title,
        description: a.title,
        url: a.url,
        width: a.width,
        height: a.height,
      })) || [];

  const organizationArtifactsOriginal =
    videoCreator?.story?._allReferencingShowcases?.flatMap((album) =>
      (album.organizationArtifacts || [])
        .filter((a) => a.responsiveImage)
        .map((a) => ({
          id: a.id,
          alt: a.title,
          description: a.title,
          url: a.url,
          width: a.width,
          height: a.height,
        })),
    ) || [];

  const organizationLogosOriginal =
    videoCreator?.story?._allReferencingShowcases?.flatMap((album) =>
      (album.organizationLogos || [])
        .filter((a) => a.responsiveImage)
        .map((a) => ({
          id: a.id,
          alt: a.title,
          description: a.title,
          url: a.url,
          width: a.width,
          height: a.height,
        })),
    ) || [];

  const [artifact, setArtifact] = useState<Img[]>(artifactsOriginal);
  const [organizationArtifacts, setOrganizationArtifacts] = useState<Img[]>(
    organizationArtifactsOriginal,
  );
  const [organizationLogos, setOrganizationLogos] = useState<Img[]>(
    organizationLogosOriginal,
  );

  const handleGoBack = () => {
    props.openPrevModal();
    props.onCloseSelf();
  };

  let assets: Asset<PhotoTab>[] = hasOrgLogos
    ? [
        {
          key: PhotoTab.logo,
          value: organizationLogos,
        },
      ]
    : [
        {
          key: PhotoTab.stock,
          value: stockItems || stock || props.externalPhotos?.stock || [],
        },
        { key: PhotoTab.artifact, value: artifact },
      ];
  let tabHeader = hasOrgLogos
    ? [PhotoTab.logo]
    : [PhotoTab.artifact, PhotoTab.stock];

  if (hasAis) {
    assets.push({
      key: PhotoTab.ai,
      value: aiItems || ai || props.externalPhotos?.ai || [],
    });
    tabHeader.push(PhotoTab.ai);
  }

  if (hasQuotes) {
    const quoteImages = videoCreator.story?.shareableImages
      ?.filter((s) => s.imagefile)
      .map((s) => ({
        imagefile: s.imagefile,
        quote: s.quote,
        storytellerName: s.storytellerName,
        id: s.id,
      }))
      ?.map((a) => ({
        id: a?.imagefile?.id,
        alt: a?.imagefile?.title,
        url: a?.imagefile?.url,
        width: a?.imagefile?.width,
        height: a?.imagefile?.height,
        quote: a.quote,
        storytellerName: a.storytellerName,
        shareableImageId: a.id,
      })) as QuoteImg[];

    assets.push({
      key: PhotoTab.quote,
      value: quoteImages || [],
    });
    tabHeader.push(PhotoTab.quote);
  }

  if (hasOrgArtifacts) {
    assets.push({
      key: PhotoTab.orgArtifact,
      value: organizationArtifacts,
    });
    tabHeader.splice(1, 0, PhotoTab.orgArtifact);
  }

  const [tab, setTab] = useState(() => {
    if (modalItemType === 'stock') return PhotoTab.stock;
    if (hasOrgLogos) return PhotoTab.logo;
    if (artifact.length) return PhotoTab.artifact;
    if (hasOrgArtifacts && organizationArtifacts.length)
      return PhotoTab.orgArtifact;
    return PhotoTab.stock;
  });

  const [unsplashSearch, setUnsplashSearch] = useState(
    keywords ? keywords.join(' ') : '',
  );
  const [unsplashPage, setUnsplashPage] = useState(1);
  const handleSearchStock = async (textValue: string) => {
    setUnsplashSearch(textValue);
    setUnsplashPage(1);
  };
  useEffect(() => {
    const fetchUnsplashPhotos = async () => {
      if (!unsplashSearch) {
        setStockItems(undefined);
        setUnsplashPage(1);
        setUnsplashSearch('');
        return;
      }
      try {
        setStockLoading(true);
        const { photos } = await searchPhotos({
          query: unsplashSearch,
          orientation: 'landscape',
          page: unsplashPage,
          perPage: 9,
        });
        const stockData = (photos?.map((res) => ({
          id: res.id,
          url: res.urls.regular,
          alt: res.alt_description,
          width: res.width,
          height: res.height,
          description: res.description || res.alt_description,
          attribution: getAttributionForPhoto(res),
        })) || []) as unknown as Img[];
        setStockItems((_items) =>
          unsplashPage === 1 ? stockData : (_items || []).concat(stockData),
        );
        const _searched = { ...(searched || {}), stock: true } as Record<
          ImageKey,
          boolean
        >;
        setSearched(_searched);
      } catch (error) {
      } finally {
        setStockLoading(false);
      }
    };
    fetchUnsplashPhotos();
  }, [unsplashSearch, unsplashPage]);

  const handleAiSearch = async (dallePrompt: string) => {
    if (!dallePrompt.length) {
      return setAiItems([]);
    }
    try {
      setAiLoading(true);
      const gpt_service = new ChatGPTService(
        videoCreator,
        datoCientStore.aiPromptRepository,
      );
      const arborStylePrompt =
        await gpt_service.fetchAiPrompt('Arbor Photo Style');

      let prompt = `${dallePrompt} ${arborStylePrompt?.description || ''}`;

      const result = await generateImagesWithGPT(prompt, 2);
      setAiItems(result);
    } catch (error) {
      console.log('An error occurred in searching ai', error);
    } finally {
      setAiLoading(false);
    }
  };

  const asset = assets.find((asset) => asset.key === tab);

  const removeKeywords = async (keyword: string) => {
    let updatedKeywords = keywords?.filter((el) => el != keyword);
    if (setKeywords && updatedKeywords) {
      setKeywords(updatedKeywords);
      setUnsplashSearch(updatedKeywords.join(' '));
      setUnsplashPage(1);
    }
  };

  const handleArtifactSearch = (text: string) => {
    if (!text) return setArtifact(artifactsOriginal);
    const foundArtifacts = artifactsOriginal.filter(
      (a) => a.description?.toLowerCase().includes(text.toLowerCase()),
    );
    setArtifact(foundArtifacts);
  };

  const handleOrganizationArtifactSearch = (text: string) => {
    if (!text) return setOrganizationArtifacts(organizationArtifactsOriginal);
    const foundArtifacts = organizationArtifactsOriginal.filter(
      (a) => a.description?.toLowerCase().includes(text.toLowerCase()),
    );
    setOrganizationArtifacts(foundArtifacts);
  };

  const handleOrganizationLogoSearch = (text: string) => {
    if (!text) return setOrganizationLogos(organizationLogosOriginal);
    const foundLogos = organizationLogosOriginal.filter(
      (a) => a.description?.toLowerCase().includes(text.toLowerCase()),
    );
    setOrganizationLogos(foundLogos);
  };

  const renderKeyWordSearch = () => (
    <SearchWrapper keywords={keywords}>
      <SearchInput
        iconRight={true}
        placeholder="Search key words"
        handleAction={handleSearchStock}
        radius={searchBarRadius}
      />
      {hasStocks && (
        <KeywordsWrapper>
          {keywords?.map((keyword) => {
            return (
              <Keyword>
                <span className="text"> {keyword?.trim()}</span>
                <div className="close" onClick={() => removeKeywords(keyword)}>
                  <CloseIcon width="7" height="8" strokeColor="#D7D7E1" />
                </div>
              </Keyword>
            );
          })}
        </KeywordsWrapper>
      )}
    </SearchWrapper>
  );

  const renderAiSearch = () => (
    <SearchWrapper>
      <SearchInput
        iconRight={true}
        hideClearAll
        placeholder="Search key words"
        handleAction={handleAiSearch}
        initialValue={props.dallePrompt}
        expandHeight
        radius={searchBarRadius}
      />
    </SearchWrapper>
  );

  const renderArtifactSearch = () => (
    <SearchWrapper>
      <SearchInput
        iconRight={true}
        hideClearAll
        placeholder="Search a photo's description"
        handleAction={handleArtifactSearch}
        expandHeight
        radius={searchBarRadius}
      />
    </SearchWrapper>
  );

  const renderOrganizationArtifactSearch = () => (
    <SearchWrapper>
      <SearchInput
        iconRight={true}
        hideClearAll
        placeholder="Search a photo's description"
        handleAction={handleOrganizationArtifactSearch}
        expandHeight
        radius={searchBarRadius}
      />
    </SearchWrapper>
  );

  const renderOrganizationLogoSearch = () => (
    <SearchWrapper>
      <SearchInput
        iconRight={true}
        hideClearAll
        placeholder="Search a logo's description"
        handleAction={handleOrganizationLogoSearch}
        expandHeight
        radius={searchBarRadius}
      />
    </SearchWrapper>
  );

  const renderItemList = (asset: Asset<PhotoTab>) => (
    <Category from={props.from}>
      <ItemList>
        {asset.value.map((s) => (
          <div>
            <ImageWrapper
              onClick={() => {
                if (props.selectedImageUrl === s.url) return null;
                props.setSelectedImage({
                  ...s,
                  type: asset.key.toLowerCase(),
                } as ImageWithType[ImageKey]);
              }}
              key={s.url}
            >
              {asset.key === PhotoTab.quote ? (
                <ShareableImageView
                  background={s.url}
                  width="auto"
                  height="98px"
                  imageRef={null}
                  quote={(s as QuoteImg).quote}
                  fontSize="10px"
                  lineHeight="12px"
                  quoteSize={{ width: '14px', height: '12px' }}
                  storyTellerDim={0.5}
                />
              ) : (
                <>
                  <Circle isSelected={props.selectedImageUrl === s.url} />
                  <Image
                    loading="lazy"
                    src={applyImageOptimizations(s.url, { width: 140 })}
                    alt={s.alt}
                    type={asset.key}
                  />
                </>
              )}
            </ImageWrapper>
            {props.showDescription && (
              <Description>{s.description}</Description>
            )}
            {renderImgLevelAttribution(s, asset.key)}
          </div>
        ))}
      </ItemList>
      {renderTabLevelAttribution(asset)}
      {asset.key === PhotoTab.stock && unsplashSearch && (
        <LoadMoreButton
          disabled={stockLoading}
          onClick={() => setUnsplashPage((cur) => cur + 1)}
        >
          Load more photos
        </LoadMoreButton>
      )}
    </Category>
  );

  const renderSpinner = (text: string) => (
    <SpinningLoading
      customStyle={{ top: 0 }}
      Ico={<PhotoIcon strokeColor="#17C964" strokeWidth={2} />}
      text={text}
    />
  );

  const renderTabLevelAttribution = (
    asset: Asset<PhotoTab>,
  ): React.ReactNode => {
    if (!asset?.value?.some((v) => v.attribution)) return null;

    switch (asset.key) {
      case PhotoTab.stock:
        return (
          <AttributionRow>
            <UnsplashAttribution />
          </AttributionRow>
        );
      default:
        return null;
    }
  };

  const renderImgLevelAttribution = (
    img: Img,
    assetKey: Asset<PhotoTab>['key'],
  ): React.ReactNode => {
    if (!img.attribution) return null;

    switch (assetKey) {
      case PhotoTab.stock:
        return (
          <AuthorAttribution
            author={img.attribution.author}
            link={img.attribution.link}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Main onKeyDown={(e) => e.stopPropagation()}>
      <Back role="button" onClick={handleGoBack}>
        <BackIcon strokeColor="#F3E9D7" />
      </Back>

      {props?.TopComponent}

      <Tabs>
        {tabHeader.map((t) => (
          <Tab
            key={t}
            isSelected={tab === t}
            onClick={() => {
              setTab(t);
              props.setSelectedImage(null);
            }}
          >
            {t === PhotoTab.quote ? 'Shareable Images' : t}
          </Tab>
        ))}
      </Tabs>

      {tab === PhotoTab.artifact && renderArtifactSearch()}
      {tab === PhotoTab.stock && renderKeyWordSearch()}
      {tab === PhotoTab.ai && renderAiSearch()}
      {tab === PhotoTab.orgArtifact && renderOrganizationArtifactSearch()}
      {tab === PhotoTab.logo && renderOrganizationLogoSearch()}

      {props.isLoading && renderSpinner('Saving Ai photo. Please wait...')}

      <Content from={props.from}>
        {(() => {
          if (unsplashPage === 1 && stockLoading && tab === PhotoTab.stock) {
            return renderSpinner('Loading stock images...');
          } else if ((!hasAis || aiLoading) && tab === PhotoTab.ai) {
            return renderSpinner('Loading Ai images...');
          } else if (asset?.value?.length) {
            return renderItemList(asset);
          } else {
            return (
              <NoContent>
                {tab === PhotoTab.quote
                  ? 'You have no saved shareable images'
                  : tab === PhotoTab.artifact || tab === PhotoTab.orgArtifact
                    ? ''
                    : tab === PhotoTab.stock
                      ? searched?.stock
                        ? 'Stock did not generate results'
                        : ''
                      : 'Describe the photo you want to see and hit enter'}
              </NoContent>
            );
          }
        })()}
        <ContentBottom>{props?.BottomComponent}</ContentBottom>
      </Content>
    </Main>
  );
};

export default PhotoModal;

const Main = styled.div``;

const Back = styled.div`
  position: absolute;
  top: 15px;
  left: 16px;
  cursor: pointer;
`;

const Content = styled.div<{ from?: string }>`
  position: relative;
  height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.from === 'quotecard' &&
    css`
      padding: 0 20px;
      max-height: 400px;
      min-height: 340px;
    `}
`;

const ContentBottom = styled.div`
  margin-top: auto;
  padding: 0 20px;
`;

const SearchWrapper = styled.div<{ keywords?: string[] }>`
  padding: 0 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.keywords?.length && '15px'};
`;

const Category = styled.div<{ from?: string }>`
  text-align: left;

  ${(props) =>
    props.from !== 'quotecard' &&
    css`
      margin: 5px 0;
      overflow: auto;
      padding: 0 20px;
      flex: 1;
    `}
`;

const ItemList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
`;
const ImageWrapper = styled.div`
  height: 98px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    background: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Image = styled.img<{ type: PhotoTab }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${(props) => props.type !== PhotoTab.quote && 'top'};
  border-radius: 10px;
`;

const LoadMoreButton = styled.button`
  margin: 24px auto 5px;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background-color: #03041a;
  color: #17c964;
  border: 1px solid #17c964;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  outline: 0;
  transition: 0.2s;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const Description = styled.div`
  margin-top: 5px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #f3e9d7;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const Tab = styled.div<{ isSelected?: boolean }>`
  color: ${(props) => (props.isSelected ? '#F2D093' : '#f3e9d7')};
  text-decoration: ${(props) => (props.isSelected ? 'underline' : '')};
  cursor: pointer;
  font-size: 12px;
  font-weight: ${(props) => (props.isSelected ? '800' : '400')};
`;

const Tabs = styled.div`
  display: flex;
  gap: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
`;

const NoContent = styled.div`
  color: #f3e9d7;
  font-weight: 600;
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
`;

const Keyword = styled.span`
  color: #d7d7e1;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #d7d7e1;
  border-radius: 40px;
  padding: 8px 16px;
  align-items: center;
  white-space: nowrap;
  display: flex;
  gap: 5px;
  .close {
    cursor: pointer;
  }
`;

const KeywordsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding-left: 5px;
  overflow: auto;
`;

const AttributionRow = styled.div`
  margin: 24px 0 8px 0;
  display: flex;
  justify-content: center;
`;
