import { useEffect } from 'react';
import { myAudio } from '../types.ts/story';
import { MY_AUDIO_QUERY } from '../gql/media-gql';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useDatoQuerySubscription } from '@src/hooks/useDatoQuerySubscription';

export const useQueryVideos = (
  ids: string[],
): {
  status: string;
  error: any;
  data: { allMyAudios: myAudio[] } | undefined;
} => {
  const { status, error, data } = useDatoQuerySubscription<
    { allMyAudios: myAudio[] },
    { ids: string[] }
  >({
    query: MY_AUDIO_QUERY,
    variables: { ids },
  });
  return { status, error, data };
};

const MyAudioQuerySubscriptionComponent = (props: {
  audioIds: string[];
  onDataReceived: (videos: myAudio[]) => void;
  onError: (error: any) => void;
}) => {
  const { audioIds, onDataReceived, onError } = props;
  const { error, data } = useQueryVideos(audioIds);

  useEffect(() => {
    console.log('data', data);
    console.log('error', error);
    if (error) {
      onError(error);
    } else if (data?.allMyAudios?.length) {
      onDataReceived(data.allMyAudios);
    }
  }, [data, error]);

  return <></>;
};

export default MyAudioQuerySubscriptionComponent;
