import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { sortBy } from 'lodash';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import {
  checkMultipleWords,
  getTranscriptElementFromCharIndex,
  TranscriptDiffElement,
  TranscriptElementBlock,
} from './utils';

declare global {
  type CaretPosition = {
    offsetNode: Node;
    offset: number;
  };
  interface Document {
    caretPositionFromPoint?(x: number, y: number): CaretPosition;
  }
}

type ActiveElement = {
  element: TranscriptDiffElement;
  elementIndex: number;
  blockIndex: number;
  rect?: DOMRect;
};

type Props = {
  cursor: { from: number; to: number };
  blocks: TranscriptElementBlock[];
};

export const TranscriptionDiff = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { blocks, cursor } = props;

  const [activeElement, setActiveElement] = useState<ActiveElement | null>(
    null,
  );

  const firstNonRemovedBlock = useMemo(
    () =>
      sortBy(blocks, 'indexOffset').find(
        (b) => b.state !== 'removed' && b.state !== 'cut',
      ),
    [blocks],
  );
  const renderElementBlocks = useCallback(
    (block: TranscriptElementBlock, index: number) => {
      return (
        <TranscriptDiffBlock
          block={block}
          cursor={
            !cursor.from && !cursor.to
              ? firstNonRemovedBlock?.indexOffset === block.indexOffset
                ? {
                    from: firstNonRemovedBlock.indexOffset,
                    to: firstNonRemovedBlock.indexOffset,
                  }
                : null
              : cursor.from >= block.indexOffset &&
                  cursor.from < block.indexOffset + block.elements.length
                ? cursor
                : null
          }
          isPlaying={videoCreator.isPlaying}
          key={`block-${index}`}
          activeDataIndex={
            window.getSelection()?.type === 'Caret' &&
            activeElement &&
            block.indexOffset === activeElement.blockIndex
              ? activeElement.elementIndex
              : undefined
          }
          setActiveElement={setActiveElement}
        />
      );
    },
    [cursor, activeElement],
  );

  useEffect(() => {
    const container = document.getElementById(
      'transcript-scrollable-container',
    );
    if (container) {
      const handleScroll = (e: Event) => {
        if (!e) return;
        const target = e.target;
        if (target instanceof HTMLElement) setActiveElement(null);
      };
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      {blocks.map(renderElementBlocks)}

      {window.getSelection()?.type !== 'Range' &&
        activeElement &&
        activeElement.element.value !== ' ' && (
          <SActiveElement
            key={activeElement.element.initial_index}
            rect={activeElement.rect}
            hide={
              activeElement.element.state === 'replaced' ||
              checkMultipleWords(activeElement.element)
            }
          >
            {activeElement.element.value}
          </SActiveElement>
        )}
    </>
  );
});

const getRect = (
  textNode: ChildNode,
  element: TranscriptDiffElement,
  elementCharIndex = 0,
) => {
  const range = document.createRange();
  range.setStart(textNode, elementCharIndex);
  range.setEnd(textNode, elementCharIndex + (element.value?.length || 0));
  const rect = range.getBoundingClientRect();
  return rect;
};

type TranscriptDiffBlockProps = {
  block: TranscriptElementBlock;
  cursor: { from: number; to: number } | null;
  isPlaying: boolean;
  activeDataIndex?: number;
  setActiveElement: (active: ActiveElement | null) => void;
};
const TranscriptDiffBlock = (props: TranscriptDiffBlockProps) => {
  const { block, cursor, isPlaying, activeDataIndex, setActiveElement } = props;
  const { state, elements } = block;

  const text = useMemo(() => {
    if (state === 'replaced') return elements[0].value || '';
    return elements.map((element) => element.value).join('');
  }, [elements]);

  const ref = useRef<HTMLSpanElement>(null);
  const [scrolled, setInitialScrolled] = useState(false);
  useEffect(() => {
    if (!scrolled && cursor) {
      const container = document.getElementById(
        'transcript-scrollable-container',
      );
      if (container && ref.current) {
        const range = document.createRange();
        range.selectNodeContents(ref.current);
        const rect = range.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const x = cursor.from;
        const a = block.indexOffset;
        const b = a + block.elements.length;
        const top =
          rect.top +
          container.scrollTop -
          containerRect.top +
          rect.height * ((x - a) / (b - a)) -
          rect.height / (block.elements.length / 10) -
          60;
        container.scrollTo({
          top,
          behavior: 'smooth',
        });
        setInitialScrolled(true);
      }
    }
  }, [scrolled, cursor]);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      const firstElement = elements[0];
      if (
        firstElement &&
        (state === 'replaced' || checkMultipleWords(firstElement))
      ) {
        setActiveElement({
          element: firstElement,
          elementIndex: block.indexOffset,
          blockIndex: block.indexOffset,
        });
        return;
      }

      const mousePos = document.caretPositionFromPoint?.(e.clientX, e.clientY);
      const charIndex = mousePos?.offset || 0;
      const match = getTranscriptElementFromCharIndex(charIndex, elements);
      if (match) {
        setActiveElement({
          element: match.element,
          elementIndex: block.indexOffset + match.elementIndex,
          blockIndex: block.indexOffset,
          rect: e.currentTarget.firstChild
            ? getRect(
                e.currentTarget.firstChild,
                match.element,
                match.elementCharIndex,
              )
            : undefined,
        });
      }
    },
    [block, elements],
  );

  return (
    <>
      {state === 'replaced' && elements[0].originalValue && (
        <SReplacedElement>{elements[0].originalValue}</SReplacedElement>
      )}
      <STranscriptSpan
        ref={ref}
        data-block-index={block.indexOffset}
        data-index={activeDataIndex}
        state={state}
        onMouseMove={handleMouseMove}
        hover={elements.length === 1 && checkMultipleWords(elements[0])}
      >
        {text}
      </STranscriptSpan>
    </>
  );
};

const getColorFromState = (state: TranscriptDiffElement['state']) => {
  switch (state) {
    case 'added':
      return '#7bb975';
    case 'removed':
      return '#cd5c5c';
    case 'replaced':
      return '#bdbdbd';
    case 'muted':
      return '#F69B12';
    case 'cut':
      return '#7bb97599';
    default:
      return '#bdbdbd';
  }
};

const SActiveElement = styled.div<{
  rect?: DOMRect;
  hide?: boolean;
}>`
  display: flex;
  align-items: center;
  position: fixed;
  pointer-events: none;
  user-select: none;
  background: #030419;
  color: #90ee90;
  ${({ rect }) =>
    rect &&
    css`
      top: ${rect.top}px;
      left: ${rect.left}px;
      right: ${rect.right}px;
      bottom: ${rect.bottom}px;
      width: ${rect.width}px;
      height: ${rect.height}px;
      z-index: 9999;
    `}
  ${({ hide }) =>
    hide &&
    css`
      width: 0;
      height: 0;
    `}
`;

const SReplacedElement = styled.span`
  color: #b59b14;
  text-decoration: line-through;
`;

const STranscriptSpan = styled.span<{
  state: TranscriptDiffElement['state'];
  hover?: boolean;
}>`
  color: ${({ state }) => getColorFromState(state)};
  cursor: pointer;
  ${({ state }) =>
    state &&
    {
      added: css``,
      cut: css`
        text-decoration: line-through #7bb97580;
        cursor: default;
      `,
      removed: css`
        text-decoration: line-through #cd5c5c80;
        cursor: default;
      `,
      replaced: css`
        &:hover {
          color: #90ee90;
        }
      `,
      muted: css``,
    }[state]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        color: #90ee90;
      }
    `}
`;
