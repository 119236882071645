import { useState } from 'react';
import styled from 'styled-components';
import PencilIcon from '@src/svgs/PencilIcon';
import { AlbumStory } from '@src/types.ts/story';
import Modal from '../common/Modal';
import CopyContentModal from '../stage/CopyContentModal';

const ActionRenameStory = ({
  story,
  onRename,
}: {
  story: AlbumStory;
  onRename: (storyId: AlbumStory['id'], newTitle: AlbumStory['title']) => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClick: React.MouseEventHandler<HTMLSpanElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleSave = (newTitle: string) => {
    onRename(story.id, newTitle);
    closeModal();
  };

  return (
    <>
      <IconWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      >
        <PencilIcon
          width="24px"
          height="24px"
          strokeColor={isHovered ? '#F2D093' : '#848484'}
        />
      </IconWrapper>
      {isModalOpen && (
        <Modal isOpen closeModal={closeModal}>
          <CopyContentModal
            name={story.title}
            tag="Story"
            type="rename"
            onCancel={closeModal}
            onSave={handleSave}
            modalOverrides={{
              hideInputLabel: true,
              saveButtonContent: (
                <>
                  <PencilIcon
                    width="24px"
                    height="24px"
                    strokeColor="#03041a"
                  />
                  Save Name
                </>
              ),
              wrapperStyles: {
                height: 'unset',
                width: '440px',
                display: 'flex',
                gap: '29px',
                'flex-direction': 'column',
                'justify-content': 'center',
                'box-shadow': '14px 8px 16px 0px rgba(0, 0, 0, 0.4)',
                'box-sizing': 'content-box',
              },
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ActionRenameStory;

const IconWrapper = styled.span`
  cursor: pointer;
`;
