import React from 'react';
import { observer } from 'mobx-react-lite';
import { ElementState } from '../../renderer/ElementState';

import PropertyDropdown from '../common/PropertyDropdown';
import { getImageWithBlackFrameElements } from '../../utility/elements';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Props = {
  activeElement: ElementState;
  propertyName: string;
  defaultValue: any;
  options: Array<{ caption: string; value: any }>;
};
const PROPS_TO_EXCLUDE = [
  'panLeft',
  'panRight',
  'panLeftWithZoom',
  'panRightWithZoom',
];

const ImageFitPropertySelect: React.FC<Props> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  const elementId = props.activeElement.source.id;
  function getActiveProperty() {
    if (videoCreator.isImageElementComposition(props.activeElement)) {
      return 'black-frame';
    }
    return props.activeElement.source[props.propertyName] ?? props.defaultValue;
  }
  const source = videoCreator.getImageElement(props.activeElement)?.source;

  return (
    <PropertyDropdown
      value={getActiveProperty()}
      onChange={async (value) => {
        await videoCreator.reframingModeManager.exitReframingMode();

        const properties: Record<string, any> = {};

        properties[`${elementId}.width`] = '100%';
        properties[`${elementId}.height`] = '100%';
        properties[`${elementId}.x`] = '50%';
        properties[`${elementId}.y`] = '50%';

        if (value === 'black-frame') {
          const animations =
            source?.animations?.map((a: any) => {
              if (PROPS_TO_EXCLUDE.includes(a.arbor_subType)) {
                return {
                  easing: 'linear',
                  type: 'scale',
                  arbor_subType: 'zoomIn',
                  scope: 'element',
                  start_scale: '100%',
                  end_scale: '130%',
                  fade: false,
                };
              }
              return a;
            }) || [];

          properties[`${elementId}.type`] = 'composition';
          properties[`${elementId}.elements`] = getImageWithBlackFrameElements({
            ...(source as { source: string }),
            animations: [],
          });
          properties[`${elementId}.duration`] = props.activeElement.duration;
          properties[`${elementId}.animations`] = animations;
          properties[`${elementId}.width`] = '100%';
          properties[`${elementId}.height`] = '100%';
          properties[`${elementId}.color_overlay`] =
            source?.color_overlay || '';
        } else {
          properties[`${elementId}.${props.propertyName}`] = value;
          properties[`${elementId}.source`] = source?.source;
          properties[`${elementId}.locked`] = false;
          properties[`${elementId}.type`] = 'image';
          properties[`${elementId}.animations`] =
            props.activeElement.source?.animations || [];
          properties[`${elementId}.color_overlay`] =
            props.activeElement.source?.color_overlay || '';

          if (!videoCreator.hasDefaultAspectRatio() && value === 'cover') {
            await videoCreator.adjustModificationsToAspectRatio(properties, {
              ...videoCreator.getElementSource(props.activeElement),
              [props.propertyName]: value,
            });
          }
        }

        await videoCreator.applyVideoStateModifications(
          {
            ...properties,
          },
          true,
          'applying image fit',
        );
        await videoCreator.setActiveElements(elementId);
      }}
      defaultValue={getActiveProperty()}
      values={props.options.map(({ value, caption }) => ({ value, caption }))}
    />
  );
});

export default ImageFitPropertySelect;
