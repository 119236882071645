import CircleCheckIcon from '@src/svgs/CircleCheckIcon';
import CircleMinusIcon from '@src/svgs/CircleMinusIcon';
import { AssociatedVideo, VideoClip } from '@src/types.ts/story';
import styled from 'styled-components';

export default function PublishBadge({
  clip,
}: {
  clip: VideoClip | AssociatedVideo;
}) {
  const publishState = clip.videoFilePrimary ? 'Published' : 'Unpublished';

  return (
    <Badge isPublished={publishState === 'Published'}>
      {publishState === 'Published' ? (
        <CircleCheckIcon
          width="12"
          height="11"
          strokeColor="black"
          strokeWidth="2"
        />
      ) : (
        <CircleMinusIcon width="10" height="10" strokeColor="black" />
      )}
      <span>{publishState}</span>
    </Badge>
  );
}

const Badge = styled.div<{ isPublished: boolean }>`
  background-color: ${(props) => (props.isPublished ? '#17c964' : '#848484')};
  color: #03041a;
  padding: 4px 8px;
  width: fit-content;
  border-radius: 4px;
  height: 15px;
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;
