import React, { useEffect } from 'react';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import BrollService from './BrollService';
import smartlook from 'smartlook-client';
import { useDatoClient } from '@src/stores-v2/StoreContext';

const useInitializeAction = (brollService: BrollService) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const smartlookInitialized = smartlook.initialized();
  const datoClientStore = useDatoClient();

  useEffect(() => {
    if (!brollService.contributor || !smartlookInitialized) return;
    brollService.identifyContributor();
  }, [smartlookInitialized, brollService.contributor]);

  useEffect(() => {
    console.log('loading broll service');
    const { storyId, organizationId, env } = params || {};
    if (!datoClientStore.isReady) return;

    (async () => {
      console.log('loading broll service');
      brollService.organizationId = organizationId;
      brollService.storyId = storyId;
      brollService.environment = env;

      const data = await brollService.findOne();
      sessionStorage.removeItem('saved_assets');
      if (data) {
        const existingAssets = [
          ...(data.photos || []),
          ...(data?.videos || []),
        ].sort(
          (a, b) =>
            new Date(b._createdAt).getTime() - new Date(a._createdAt).getTime(),
        );

        brollService.assets = existingAssets.map((a) => ({
          id: a.id,
          imgSrc: a.responsiveImage
            ? a.responsiveImage?.src
            : a.video?.thumbnailUrl,
          videoSrc: a?.video?.mp4Url || a.url!,
          type: a.responsiveImage ? 'photo' : 'video',
          description: a.title,
          createdAt: new Date(a._createdAt),
          isPreview: false,
          state: 'uploaded',
        }));
        brollService.initialized = true;
      }
    })();
  }, [datoClientStore.isReady]);
};

export default useInitializeAction;
