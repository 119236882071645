import MainMenu from '../components/MainMenu';
import React, { useEffect } from 'react';
import GlobalQuerySubscriptionComponent from '../components/GlobalQuerySubscriptionComponent';
import { Insights } from '../utility/insights';
import { useAccessControl } from '@src/hooks/useAccessControl';

const RootLayout = ({ children }: { children: React.ReactNode }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { storyId, videoId, showcase } = Object.fromEntries(
    urlSearchParams.entries(),
  );
  const { checkUserAccess } = useAccessControl();

  useEffect(() => {
    const checkAccess = async () => {
      const result = await checkUserAccess(storyId || null, showcase);
      if (!result.hasAccess && result.redirectTo) {
        console.log('Redirecting to:', result.redirectTo);
        window.location.href = result.redirectTo;
      }
    };

    checkAccess();
  }, [storyId, showcase, checkUserAccess]);

  return (
    <div className="editor">
      <Insights />
      <MainMenu params={params} />
      <GlobalQuerySubscriptionComponent />
      {children}
    </div>
  );
};

export default RootLayout;
