import {
  useQuerySubscription,
  QueryListenerOptions,
} from 'react-datocms/use-query-subscription';
import { useDatoClient } from '@src/stores-v2/StoreContext';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

export function useDatoQuerySubscription<QueryResult, QueryVariables>(
  options: Omit<
    QueryListenerOptions<QueryResult, QueryVariables>,
    'environment' | 'token'
  > & {
    query: TypedDocumentNode<QueryResult, QueryVariables> | string;
  },
): ReturnType<typeof useQuerySubscription<QueryResult, QueryVariables>> {
  const datoClientStore = useDatoClient();

  return useQuerySubscription<QueryResult, QueryVariables>({
    ...options,
    environment: datoClientStore.environment,
    token: datoClientStore.gqlAuthToken,
  });
}
