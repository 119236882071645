import { useRef } from 'react';
import styled from 'styled-components';
import {
  convertMMSSToSeconds,
  convertTimeToMMSS,
} from '../../utility/timeFormat';
import Modal from '../common/Modal';
import Checkbox from '../common/Checkbox';
import { CopyIcon } from '../common/icons';

export const WordReplacementModalUI: React.FC<{
  currentText: string;
  type: 'input' | 'textarea';
  submitText: (text: string) => void;
  discard: () => void;
  currentRange?: { start: number; end: number };
  changeRange?: (range: { start: number; end: number }) => void;
  setShouldReplaceAll?: (value: boolean) => void;
  shouldReplaceAll?: boolean;
  setShouldMatchCase?: (value: boolean) => void;
  shouldMatchCase?: boolean;
}> = ({
  currentText,
  type,
  currentRange,
  changeRange,
  submitText,
  discard,
  setShouldReplaceAll,
  shouldReplaceAll = false,
  setShouldMatchCase,
  shouldMatchCase = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const rangeStartRef = useRef<HTMLInputElement>(null);
  const rangeEndRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Modal isOpen={true} closeModal={discard}>
      <Wrapper ref={wrapperRef} type={type}>
        <Header>
          <Heading>Replace Term</Heading>
          <Term>’{currentText}’</Term>
        </Header>
        <FieldSet>
          <FieldName>Edit Transcription</FieldName>
          {currentRange && (
            <Ranges>
              <RangeInput
                type="time"
                step={0.001}
                ref={rangeStartRef}
                defaultValue={
                  '00:' + convertTimeToMMSS(currentRange.start, true)
                }
                onKeyDownCapture={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  e.nativeEvent.stopPropagation();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  e.nativeEvent.stopPropagation();
                }}
              />
              <span>{'-->'}</span>
              <RangeInput
                type="time"
                step={0.001}
                ref={rangeEndRef}
                defaultValue={'00:' + convertTimeToMMSS(currentRange.end, true)}
                onKeyDownCapture={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  e.nativeEvent.stopPropagation();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  e.nativeEvent.stopPropagation();
                }}
              />
            </Ranges>
          )}
          {type === 'textarea' ? (
            <TextArea
              defaultValue={currentText}
              ref={textAreaRef}
              onKeyDownCapture={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.nativeEvent.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.nativeEvent.stopPropagation();
              }}
            />
          ) : (
            <TextInput
              type="text"
              defaultValue={currentText}
              ref={inputRef}
              onKeyDownCapture={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.nativeEvent.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.nativeEvent.stopPropagation();
              }}
              data-testid="word-replacement-input"
            />
          )}
          <CheckboxSection>
            {setShouldMatchCase && (
              <Checkbox
                label="Match case"
                color={shouldMatchCase ? '#17C964' : '#03041A'}
                borderColor={shouldMatchCase ? '#17C964' : '#484848'}
                labelColor="#f3e9d7"
                onClick={() => {
                  setShouldMatchCase(!shouldMatchCase);
                }}
                checked={shouldMatchCase}
              />
            )}
            {setShouldReplaceAll && (
              <Checkbox
                label="Replace all instances"
                color={shouldReplaceAll ? '#17C964' : '#03041A'}
                borderColor={shouldReplaceAll ? '#17C964' : '#484848'}
                labelColor="#f3e9d7"
                onClick={() => {
                  setShouldReplaceAll(!shouldReplaceAll);
                }}
                checked={shouldReplaceAll}
              />
            )}
          </CheckboxSection>
        </FieldSet>
        <Buttons>
          <CancelButton onClick={discard}>Cancel</CancelButton>
          <SubmitButton
            onClick={() => {
              submitText((inputRef.current || textAreaRef.current)?.value!);
              if (
                changeRange &&
                rangeStartRef.current?.value &&
                rangeEndRef.current?.value
              ) {
                changeRange({
                  start: convertMMSSToSeconds(
                    rangeStartRef.current?.value.substring(3),
                  ),
                  end: convertMMSSToSeconds(
                    rangeEndRef.current?.value.substring(3),
                  ),
                });
              }
            }}
          >
            <CopyIcon />
            Replace {shouldReplaceAll ? 'All' : ''}
          </SubmitButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div<{ type: 'input' | 'textarea' }>`
  width: 459px;
  height: ${(props) => (props.type === 'textarea' ? '315px' : 'unset')};
  overflow: ${(props) => props.type === 'textarea' && 'auto'};

  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #45d483;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
`;

const FieldSet = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Ranges = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 6px;
  margin-top: 6px;
  justify-content: space-between;
`;

const RangeInput = styled.input`
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;

  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  background: #03041a;
`;

const TextInput = styled.input`
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;

  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  background: #03041a;
`;

const FieldName = styled.div`
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TextArea = styled.textarea`
  font-family: 'Inter', sans-serif;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 8px 6px;
  border-radius: 4px;
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  border: 1px solid #f3e9d7;
`;

const Buttons = styled.div`
  display: flex;
  height: 48px;
  gap: 8px;
  cursor: pointer;
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #17c964;

  font-size: 14px;
  font-weight: 700;

  color: #17c964;

  &:hover {
    font-size: 15px;
  }
`;

const SubmitButton = styled(CancelButton)`
  background: #17c964;
  color: #03041a;
`;

const Term = styled.div`
  align-self: stretch;
  color: #f3e9d7;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 31.2px */
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const CheckboxSection = styled(Header)`
  align-items: flex-start;
`;
