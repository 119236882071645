import { useEffect } from 'react';

import {
  ShowcaseArtifactsAiTitleUpdate,
  StoryArtifactsAiTitleUpdate,
} from '../types.ts/story';
import {
  SHOWCASES_ARTIFACT_AI_TITLE_QUERY,
  STORIES_ARTIFACT_AI_TITLE_QUERY,
} from '../gql/ai-prompts-gql';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useDatoQuerySubscription } from '@src/hooks/useDatoQuerySubscription';
import { NewStoryUpdate } from './NewStoriesQuerySubscriptionComponent';

export const useQueryShowcases = (
  ids: string[],
): {
  status: string;
  error: any;
  data: { allShowcases: ShowcaseArtifactsAiTitleUpdate[] } | undefined;
} => {
  const { status, error, data } = useDatoQuerySubscription<
    { allShowcases: ShowcaseArtifactsAiTitleUpdate[] },
    { ids: string[] }
  >({
    query: SHOWCASES_ARTIFACT_AI_TITLE_QUERY,
    variables: { ids },
  });

  return { status, error, data };
};

const OrganizationArtifactsAiTitleSubscription = (props: {
  organizationIds: string[];
  onDataReceived: (updates: ShowcaseArtifactsAiTitleUpdate[]) => void;
  onError: (error: any) => void;
}) => {
  const { organizationIds, onDataReceived, onError } = props;
  const { error, data } = useQueryShowcases(organizationIds);

  useEffect(() => {
    if (error) {
      onError(error);
    } else if (data?.allShowcases?.length) {
      onDataReceived(data.allShowcases);
    }
  }, [data, error]);

  return <></>;
};

export default OrganizationArtifactsAiTitleSubscription;
