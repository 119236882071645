import styled, { CSSProperties, css } from 'styled-components';
import {
  Artifact,
  AssociatedVideo,
  FileData,
  Video,
  VideoClip,
} from '../../types.ts/story';
import { AspectRatio } from '../../types.ts/video';

import { getFormattedDurationForClip } from '../../utility/timeFormat';
import { useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AiProducerIcon from '../../svgs/AiProducerIcon';
import ThumbnailIcon from '../../svgs/ThumbnailIcon';
import { ImageKey, ImageWithType } from '../../types.ts/general';
import MuxPlayer from '@mux/mux-player-react';
import type MuxPlayerElement from '@mux/mux-player';
import type { MuxPlayerElementEventMap } from '@mux/mux-player';
import ShareModal from './ShareModal';
import SocialShareStatus from './SocialShareStatus';
import {
  getAvailableClipFormats,
  getPosterUrl,
  handleDownloadMedia,
} from '../../utility/general';
import PhotoModal from '../common/PhotosModal';
import Modal from '../Modal';
import PhotoModalTop from '../common/PhotoModalTop';
import { getRandomFileName, createImageElement } from '../../utility/general';
import SpinningLoading from '../SpinningLoading';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import '@mux/mux-player/themes/minimal';
import DownloadIcon from '../../svgs/DownloadIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import {
  ShareModalAnimate,
  ShareModalDropdown,
  ShareModalDropdownItem,
} from '../../styles/mainStyle';
import MuxPlayerClip from '../common/MuxPlayerClip';
import ShareableImageView from '../common/ShareableImageView';
import { v4 as uuid } from 'uuid';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { analytics } from '@src/utility/analytics';
import { useNavigateToCreatorStudio } from '@src/hooks/useNavigateToCreatorStudio';
import PublishBadge from '../common/PublishBadge';
import {
  useDatoClient,
  useStore,
  useUserIdentity,
} from '@src/stores-v2/StoreContext';
import React from 'react';

const playerStyle = {
  height: '100%',
  width: '100%',
  borderRadius: 10,
  overflow: 'hidden',
  border: '1px solid #484848',
  aspectRatio: 4 / 3,
  '--pip-button': 'none',
} as CSSProperties;

type Props = {
  onClose: () => void;
  clip: VideoClip & { autoPlay?: boolean; videoJson?: Video['videoSource'] };
};

const getDurationColor = (duration: number | null) => {
  if (!duration) return;
  if (duration <= 15) {
    return '#CBD7F0';
  } else if (duration <= 30) {
    return '#7879f1';
  } else if (duration <= 60) {
    return '#F178B6';
  } else {
    return '#484848';
  }
};

const SocialClipShareModal = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const userIdentity = useUserIdentity();
  const datoClientStore = useDatoClient();

  const story = videoCreator?.story;
  const { clip } = props;
  const { clipJson } = clip;
  const durationData = getFormattedDurationForClip(clip);
  const navigate = useNavigateToCreatorStudio();

  const [showPhotosModal, togglePhotosModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<
    | (ImageWithType[ImageKey] & { quote?: string; shareableImageId?: string })
    | null
  >(null);
  const [clipImage, setClipImage] = useState<string>(
    clip.thumbnail?.url || story?.thumbnail?.url!,
  );
  const [dropdown, toggleDropdown] = useState<DOMRect | null>(null);
  const [savingStock, setSavingStock] = useState<boolean>(false);
  const [savingQuote, setSavingQuote] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedDimension, setSelectedDimension] = useState<string>();
  const { contentStudioEnableClipShare, enableVideoClipEdit, enableSharing } =
    useFlagsCombination(userIdentity.currentRole);
  const imageRef = useRef<HTMLImageElement>(null);

  const buttonRef = useRef<HTMLButtonElement>(null);
  useOutsideAlerter(buttonRef, () => {
    toggleDropdown(null);
  });

  const [mainClip, setMainClip] = useState<VideoClip>(clip);
  const [selectedFormattedClip, setSelectedFormattedClip] = useState<
    VideoClip | AssociatedVideo
  >(clip);
  const playerRef = useRef<MuxPlayerElement | null>(null);
  const [formatDownloading, setFormatDownloading] = useState<string | null>(
    null,
  );
  const availableFormats = getAvailableClipFormats(clip);

  const posterUrl = getPosterUrl(
    clipImage,
    selectedFormattedClip?.videoFilePrimary?.width!,
    selectedFormattedClip?.videoFilePrimary?.height!,
  );

  const saveToDato = async (
    videoIdx: number,
    imageUrl: string,
    selectedClip = selectedFormattedClip,
    targetImage = selectedImage,
  ) => {
    try {
      if (!imageUrl || !targetImage) return;
      const isInDato = imageUrl.startsWith('https://www.datocms-assets.com');
      let url = imageUrl;
      let id = targetImage.id;

      if (!isInDato) {
        if (targetImage.type === 'stock') setSavingStock(true);
        const fileName = getRandomFileName(searchText!) || uuid();

        const newPhotoData: FileData & { fileName: string } = {
          type: targetImage.type,
          url,
          fileName,
          alt: searchText,
          title: searchText,
        };
        const newUpload =
          await datoClientStore.assetRepository?.uploadFile(newPhotoData);
        if (newUpload) {
          url = newUpload.url;
          id = newUpload.id;
          if (targetImage.type !== 'quotes') {
            const assets = [
              ...(videoCreator.story?.storyAssets || []),
              {
                id,
                title: searchText,
                customData: {},
                format: newUpload.format,
                mimeType: newUpload.mime_type,
                url,
                responsiveImage: {
                  srcSet: url,
                  alt: searchText,
                  title: searchText,
                },
                video: null,
                _createdAt: new Date(),
              },
            ] as Artifact[];
            videoCreator.story!.storyAssets! = assets;
            await videoCreator.updateStory(videoCreator.story!);
          }

          setClipImage(url);

          updateOtherVideos(
            videoIdx,
            url,
            targetImage.shareableImageId,
            selectedClip,
          );

          setSavingStock(false);
          setSavingQuote(false);
          setSelectedImage(null);
        } else {
          return;
        }
      }

      if (id && url) {
        const shareableImageId = targetImage.shareableImageId;
        setMainClip((prev) => {
          if (prev.id === selectedClip.id) {
            return {
              ...prev,
              shareableImageId: targetImage.shareableImageId || '',
              thumbnail: { url },
            };
          } else {
            const associatedClipIdx = prev.associatedVideos.findIndex(
              (v) => v.id === selectedClip.id,
            );

            if (associatedClipIdx !== undefined && associatedClipIdx > -1) {
              prev.associatedVideos[associatedClipIdx] = {
                ...prev.associatedVideos[associatedClipIdx],
                shareableImageId: targetImage.shareableImageId || '',
                thumbnail: { url },
              };
            }
          }
          return prev;
        });

        setSelectedFormattedClip((prev) => ({
          ...prev,
          shareableImageId: targetImage.shareableImageId || '',
          thumbnail: { url },
        }));

        await datoClientStore.videoRepository?.updateThumbnail(
          selectedClip.id || clip.id!,
          id,
          shareableImageId,
        );
        setSelectedImage(null);
      }
    } catch (error) {}
  };

  const updateOtherVideos = (
    videoIdx: number,
    url: string,
    shareableImageId: string | undefined,
    selectedClip = selectedFormattedClip,
  ) => {
    if (videoIdx > -1) {
      if (selectedClip.id === clip.id) {
        videoCreator!.story!.otherVideos[videoIdx].thumbnail = { url };
        if (shareableImageId) {
          videoCreator!.story!.otherVideos[videoIdx].shareableImageId =
            shareableImageId || '';
        }
        if (story?.finalVideo?.id === clip.id) {
          const video = story!.finalVideo! as Video;

          (story!.finalVideo! as Video) = {
            ...video,
            thumbnail: {
              ...(video.thumbnail || {}),
              url,
            },
          };
        }
      } else {
        const associatedVideoIdx = videoCreator!.story!.otherVideos[
          videoIdx
        ].associatedVideos.findIndex((v) => v.id === selectedClip.id);
        videoCreator!.story!.otherVideos[videoIdx].associatedVideos[
          associatedVideoIdx
        ].thumbnail = { url };

        if (shareableImageId) {
          videoCreator!.story!.otherVideos[videoIdx].associatedVideos[
            associatedVideoIdx
          ].shareableImageId = shareableImageId || '';
        }
      }
    }
  };

  const onReplaceAction = async () => {
    if (!selectedImage) return;
    let url = selectedImage.url;

    if (selectedImage.type === 'quotes') {
      url = await createImageElement(
        imageRef.current!,
        url,
        selectedImage.quote!,
        () => {
          setSavingQuote(true);
          togglePhotosModal(false);
        },
        selectedFormattedClip.aspectRatio || AspectRatio.AR_16_9,
      );
    }

    setClipImage(selectedImage.url);

    const videoIdx = videoCreator?.story?.otherVideos.findIndex(
      (video) => video.id === clip.id,
    );

    if (videoIdx === undefined) {
      togglePhotosModal(false);
      return;
    }

    updateOtherVideos(
      videoIdx,
      selectedImage.url,
      selectedImage.shareableImageId,
    );

    togglePhotosModal(false);
    await saveToDato(videoIdx, url);
  };

  const onSelectDimension = async (dimension: string) => {
    setSelectedDimension(dimension);
    const clips = [mainClip, ...(mainClip.associatedVideos || [])];
    const selectedClip = clips.find((v) => v.aspectRatio === dimension)!;
    const shareableImageId = clips.find((v) => v.shareableImageId)
      ?.shareableImageId;
    setSelectedFormattedClip(selectedClip);

    if (!shareableImageId) return;

    if (selectedClip.thumbnail) {
      setClipImage(selectedClip.thumbnail.url);
    }

    if (!selectedClip.shareableImageId && selectedClip.id) {
      const shareableImage = videoCreator.story?.shareableImages?.find(
        (s) => s.id === shareableImageId,
      );

      if (shareableImage) {
        const imageFile = shareableImage.imagefile;
        const url = imageFile?.url || '';
        const quote = shareableImage.quote;
        const blobUrl = await createImageElement(
          imageRef.current!,
          url,
          quote,
          () => {
            setSavingQuote(true);
          },
          selectedClip.aspectRatio,
        );

        const videoIdx = videoCreator?.story?.otherVideos.findIndex(
          (video) => video.id === clip.id,
        );

        if (videoIdx !== undefined && videoIdx !== -1) {
          const data = {
            type: 'quotes' as 'quotes',
            url: imageFile?.url || '',
            alt: imageFile?.title || '',
            id: imageFile?.id || '',
            width: imageFile?.width || '',
            height: imageFile?.height || '',
            quote: shareableImage.quote,
            shareableImageId: shareableImage.id,
          };

          await saveToDato(videoIdx, blobUrl, selectedClip, data);
        }
        setSavingQuote(false);
      }
    }
  };

  const clipElement = (clip?.videoJson || clip?.videoSource)?.elements?.find(
    (el: any) => videoCreator.isOriginalVideoElement(el),
  );

  function updateTimeOnPublishedVideo(
    event: MuxPlayerElementEventMap['timeupdate'],
  ) {
    const startTime = 0;
    const endTime =
      selectedFormattedClip?.videoFilePrimary?.video?.duration ||
      clipJson?.duration;

    const videoElement = event.target as HTMLVideoElement;
    const seconds = videoElement.currentTime;
    if (seconds > endTime) {
      setTimeout(() => {
        playerRef.current!.currentTime = startTime;
      }, 1000);
    }
  }

  const clipSource =
    selectedFormattedClip.videoFilePrimary || clipJson?.muxPlaybackId
      ? null
      : clipElement?.source;

  const playbackId = useMemo(() => {
    if (selectedFormattedClip.videoFilePrimary) {
      return selectedFormattedClip.videoFilePrimary?.video?.muxPlaybackId;
    }
    if (clipJson?.muxPlaybackId) {
      return clipJson.muxPlaybackId;
    }
    if (clipElement?.source) return;
    const finalVideo = story?.finalVideo?.videoFilePrimary?.video;
    const originalVideo = story?.originalVideo.video;
    return (finalVideo || originalVideo)?.muxPlaybackId;
  }, [selectedFormattedClip, clipJson, clipElement, story]);

  async function downloadMedia(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dimension: string,
  ) {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (
        clip &&
        ((clip.associatedVideos && clip.associatedVideos.length > 0) ||
          clip.videoFilePrimary)
      ) {
        analytics.track('download_clip', {
          videoId: clip?.id,
          videoTitle: clip?.title,
          storyId: story?.id,
          storyTitle: story?.title,
          dimension,
        });
        const videoFile: any = clip
          ? [clip, ...clip.associatedVideos].find(
              (video: any) =>
                video && video.aspectRatio && video.aspectRatio === dimension,
            )
          : null;

        if (!videoFile?.videoFilePrimary) return;

        setFormatDownloading(dimension);
        const media = {
          url: videoFile.videoFilePrimary.url,
          fileName: clip?.id,
          id: clip?.id,
        };
        await handleDownloadMedia(e, media);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFormatDownloading(null);
      toggleDropdown(null);
    }
  }

  const canDownload = (): boolean => {
    return availableFormats.length > 0;
  };

  const renderDownloadDropdown = (dropdown: DOMRect) => {
    const top = dropdown.top + dropdown.height + 5;
    const height = 180;
    const isBottom = top + height < window.innerHeight;
    const bottomPos = dropdown.top - height - 10;

    return (
      <Dropdown
        top={top}
        parentHeight={dropdown.height}
        isBottom={isBottom}
        bottomPos={bottomPos}
      >
        {Object.values(AspectRatio)
          .filter((dim) => availableFormats.includes(dim))
          .map((dim) => (
            <DropdownItem key={dim} onClick={(e) => downloadMedia(e, dim)}>
              <span>{dim}</span>
              {formatDownloading === dim ? (
                <Animate />
              ) : (
                <DownloadIcon strokeColor="currentColor" />
              )}
            </DropdownItem>
          ))}
      </Dropdown>
    );
  };

  return (
    <ShareModal
      clip={clip}
      onClose={() => {
        const tempImageContainer = document.getElementById(
          'placeholder-shareable-image',
        );
        if (tempImageContainer) tempImageContainer.remove();
        props.onClose();
      }}
      dimensions={availableFormats}
      onSelectDimension={onSelectDimension}
      hideShareButton={!contentStudioEnableClipShare}
      posterUrl={posterUrl}
    >
      <Title>
        <h2>{clip?.title || ''}</h2>
        <h5>{story?.storyTeller?.name || ''}</h5>
      </Title>

      <TopContent>
        <Icons>
          <PublishBadgeContainer>
            <PublishBadge clip={selectedFormattedClip} />
          </PublishBadgeContainer>
          <DurationBadge color={getDurationColor(durationData.duration)}>
            {durationData.formattedDuration}
          </DurationBadge>
          {enableSharing && (
            <SocialShareStatus
              sharedContents={clip?._allReferencingSharedContents}
            />
          )}
        </Icons>
      </TopContent>

      <VideoPlayer>
        <ShareablePreview style={{ display: 'none' }}>
          <ShareableImageView imageRef={imageRef} />
        </ShareablePreview>
        {selectedFormattedClip?.videoFilePrimary ? (
          <MuxPlayer
            ref={playerRef}
            style={playerStyle}
            onTimeUpdate={updateTimeOnPublishedVideo}
            streamType="on-demand"
            playbackId={playbackId}
            poster={posterUrl}
            autoPlay={clip.autoPlay}
          />
        ) : (
          <MuxPlayerClip
            clip={{
              startTime: clipJson?.startTime || clipElement?.trim_start,
              duration: clipJson?.duration || clipElement?.duration,
              muxPlaybackId: playbackId,
              src: clipSource,
              poster: posterUrl,
              autoPlay: clip.autoPlay || false,
            }}
          />
        )}
      </VideoPlayer>
      <ButtonGroup>
        {enableVideoClipEdit && (
          <Button
            onClick={() => {
              navigate(selectedFormattedClip);
            }}
          >
            <AiProducerIcon /> Edit
          </Button>
        )}
        <Button onClick={() => togglePhotosModal(true)}>
          <ThumbnailIcon /> Thumbnail
        </Button>
        {canDownload() && (
          <Button
            ref={buttonRef}
            onClick={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              toggleDropdown((prev) => (prev ? null : rect));
            }}
          >
            <DownloadIcon /> Download
            {dropdown && renderDownloadDropdown(dropdown)}
          </Button>
        )}
      </ButtonGroup>

      {savingStock ||
        (savingQuote && (
          <SpinningLoading
            customStyle={{
              top: 0,
              position: 'fixed',
              alignItems: 'center',
            }}
            text={
              savingQuote ? 'Saving quote photo...' : 'Saving stock photo...'
            }
          />
        ))}

      {showPhotosModal && (
        <Modal
          isOpen={true}
          onClose={() => togglePhotosModal(false)}
          paddingHorizontal="0"
        >
          <PhotoModal
            TopComponent={
              <PhotoModalTop
                replaceAction={onReplaceAction}
                title="Thumbnail"
                isSelected={!!selectedImage}
                selectedImage={selectedImage}
              />
            }
            otherFields={['stock', 'quotes']}
            onCloseSelf={() => togglePhotosModal(false)}
            openPrevModal={() => {}}
            selectedImageUrl={selectedImage?.url || null}
            setSelectedImage={setSelectedImage}
            setSearchText={setSearchText}
            searchText={searchText}
          />
        </Modal>
      )}
    </ShareModal>
  );
});

export default SocialClipShareModal;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  h2,
  h5 {
    margin: 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    color: #a9a9a9;
  }
`;

const PublishBadgeContainer = styled.div`
  margin: 9px 0px;
`;

const DurationBadge = styled.div<{ color?: string }>`
  padding: 2px 5px;
  background-color: ${(props) => props.color || ''};
  color: black;
  width: fit-content;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 10px;
  height: 17px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Icons = styled.div`
  display: flex;
  gap: 10px;
`;

const VideoPlayer = styled.div`
  width: 100%;
  height: 361px;
  padding-right: 0;
  position: relative;
  margin: 0 auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-left: 0;
  justify-content: end;
  gap: 10px;
  margin-bottom: 25px;
`;

const Button = styled.button<{
  borderColor?: string;
  color?: string;
  backgroundColor?: string;
  width?: string;
}>`
  position: relative;
  display: flex;
  max-width: 50%;
  flex: 1;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #484848;
  cursor: pointer;
  background-color: #030419;
  color: #f3e9d7;
`;

const Tabs = styled.nav`
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 10px;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  ${(props) =>
    props.isSelected &&
    css`
      border-bottom: 1px solid #f2d093;
      color: #f2d093;
      font-weight: 700;
    `}
`;

const Dropdown = styled(ShareModalDropdown)<{
  bottomPos: number;
  parentHeight: number;
}>`
  position: absolute;
  ${({ isBottom, parentHeight }) =>
    !isBottom &&
    css`
      bottom: ${parentHeight}px;
    `}
  ${({ isBottom, parentHeight }) =>
    isBottom &&
    css`
      top: ${parentHeight}px;
    `}
  left: 0;
  width: 100%;
`;

const DropdownItem = styled(ShareModalDropdownItem)`
  &:hover:not(&:disabled) {
    color: #03041a;
    background-color: #17c964;
  }
`;

const Animate = styled(ShareModalAnimate)``;

const ShareablePreview = styled.div`
  position: absolute;
  top: -80px;
  left: 1px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;
