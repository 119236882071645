import Checkbox from '../common/Checkbox';

export const AddSongActionOptions = ({
  fitSongToVideo,
  setFitSongToVideo,
  disabled,
}: {
  fitSongToVideo: boolean;
  setFitSongToVideo: (fitSongToVideo: boolean) => void;
  disabled?: boolean;
}) => {
  const color = fitSongToVideo ? '#17C964' : '#03041A';
  const borderColor = fitSongToVideo ? '#17C964' : '#484848';
  const toggleFitSongToVideo = () => {
    setFitSongToVideo(!fitSongToVideo);
  };

  return (
    <Checkbox
      label="Fit song to end of video"
      color={color}
      borderColor={borderColor}
      onClick={toggleFitSongToVideo}
      checked={fitSongToVideo}
      disabled={disabled}
      overrides={{
        containerStyles: { 'margin-top': '8px', gap: '10px' },
      }}
    />
  );
};

export default AddSongActionOptions;
