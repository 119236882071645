import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import Modal from '../common/Modal';
import PhotoModal from './PhotosModal';
import PhotoModalTop from './PhotoModalTop';
import { ImageKey, ImageWithType } from '../../types.ts/general';
import { saveAssetToDato } from '../../utility/general';
import { runInAction } from 'mobx';
import styled from 'styled-components';
import { getImageWithBlackFrameElements } from '../../utility/elements';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { AspectRatio } from '@src/types.ts/video';
import { useStore } from '@src/stores-v2/StoreContext';

type Props = {
  title?: string;
  from?: 'string';
};

const ReplacePhotoElementModal = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { datoClientStore } = useStore();

  const { title = 'Swap or search for a new image.', from = 'transcript' } =
    props;
  const element = videoCreator.openPhotoElementReplacementModal?.element;

  const [selectedImage, setSelectedImage] = useState<
    ImageWithType[ImageKey] | null
  >(null);
  const handleReplacePhoto = async () => {
    videoCreator.openPhotoElementReplacementModal = null;
    if (!selectedImage?.url || !element) return;

    const url = await saveAssetToDato(
      videoCreator,
      datoClientStore.assetRepository,
      selectedImage,
    );

    const isPortrait = +selectedImage.width < +selectedImage.height;
    const videoAspectRatio =
      videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9;

    const id = element.source.id;
    const modifications =
      isPortrait && videoAspectRatio === AspectRatio.AR_16_9
        ? {
            [`${id}.type`]: 'composition',
            [`${id}.source`]: null,
            [`${id}.elements`]: getImageWithBlackFrameElements({
              source: url,
            }),
          }
        : {
            [`${id}.type`]: 'image',
            [`${id}.source`]: url,
            [`${id}.fit`]: 'cover',
            [`${id}.smart_crop`]: true,
            [`${id}.elements`]: [],
          };

    await videoCreator.reframingModeManager.exitReframingMode();
    if (
      !videoCreator.hasDefaultAspectRatio() &&
      modifications[`${id}.fit`] === 'cover'
    ) {
      await videoCreator.adjustModificationsToAspectRatio(modifications, {
        ...videoCreator.getElementSource(element),
        fit: 'cover',
        type: 'image',
        source: url,
        arbor_naturalDimensions: {
          width: +selectedImage.width,
          height: +selectedImage.height,
        },
      });
    }

    await videoCreator.applyVideoStateModifications(
      modifications,
      true,
      'replacing image',
    );
    await videoCreator.setActiveElements(element.source.id);

    // Replace item in punchlist, if it exists.
    if (videoCreator.punchListManager.length()) {
      const punchListItem = videoCreator.punchListManager.findPunchListItemById(
        element.source.id,
      );
      if (punchListItem) {
        runInAction(() => {
          videoCreator.punchListManager.updatePunchListItem(element.source.id, {
            artifactSrc: url,
            artifactAspectRatio: +selectedImage.width / +selectedImage.height,
          });
        });
      }
    }
  };

  if (!element || !videoCreator.isImageElement(element)) return null;

  return (
    <Modal
      isOpen={true}
      closeModal={() => (videoCreator.openPhotoElementReplacementModal = null)}
    >
      <ModalWrapper>
        <PhotoModal
          TopComponent={
            <PhotoModalTop
              replaceAction={handleReplacePhoto}
              title={title}
              isSelected={!!selectedImage}
              origin={from}
              selectedImage={selectedImage}
            />
          }
          showDescription
          otherFields={
            videoCreator.isLogoElement(element)
              ? ['org_logos']
              : ['ai', 'stock', 'org_photos']
          }
          onCloseSelf={() =>
            (videoCreator.openPhotoElementReplacementModal = null)
          }
          openPrevModal={() => {}}
          selectedImageUrl={selectedImage?.url || null}
          setSelectedImage={setSelectedImage}
          from={'from'}
          searchBarRadius="10px"
        />
      </ModalWrapper>
    </Modal>
  );
});

export default ReplacePhotoElementModal;

const ModalWrapper = styled.div`
  background-color: #030419;
  color: #f3e9d7;
  width: 500px;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 20px 0;
  border: 1px solid #484848;
`;
