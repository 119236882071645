import { Client } from '@datocms/cma-client-browser';
import { GraphQLClient } from 'graphql-request';
import { TranscriptData, TranscriptElement } from '../types.ts/transcript';
import ApiClient from '../apiClient/ApiClient';
import { Subtitles } from '../types.ts/story';

const TRANSCRIPT_UPLOAD_QUERY = `query getTranscript($id: UploadId) {
    allUploads(filter: {id: {eq: $id}}) {
      id
      url
    }
  }`;

const UPLOAD_BY_TITLE_QUERY = `query getUpload($title: String!) {
    allUploads(filter: {title: {matches: {pattern: $title, caseSensitive: "false"}}}) {
      id
      url
      customData
    }
  }`;

const STORY_TRANSCRIPT_CHANGES_QUERY = `query getStory($id: ItemId) {
    story(filter: {id: {eq: $id}}) {
      transcriptionChanges
    }
  }`;

const ORIGINAL_VIDEO_TRANSCRIPTION_QUERY = `query getStory($id: ItemId) {
  story(filter: {id: {eq: $id}}) {
    id
    transcription {
      jobStatus
      elementsJson {
        id
        url
      }
    }
  }
}`;

export class TranscriptRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async getTranscriptionFromDato(storyId: string) {
    const queryResult: {
      story: {
        transcription: {
          jobStatus: string;
          elementsJson: {
            id: string;
            url: string;
          };
        };
        id: string;
      };
    } = await this.gqlClient.request(ORIGINAL_VIDEO_TRANSCRIPTION_QUERY, {
      id: storyId,
    });
    return queryResult.story.transcription;
  }

  async fetchTranscriptFromUrl(transcriptUrl: string): Promise<TranscriptData> {
    const jsonResponse = await fetch(transcriptUrl);
    const transcriptData = (await jsonResponse.json()) as TranscriptData;
    if (!transcriptData.language) {
      transcriptData.language = 'en';
    }
    return transcriptData;
  }

  async generateSubtitles(
    transcriptionElements: TranscriptElement[],
    language: string,
  ): Promise<Subtitles | null> {
    const fetchUrl = `${process.env.REACT_APP_API_URL}/api/captions/get-translated`;
    const response = await fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        transcriptionElements,
        language,
      }),
    });

    if (!response.ok) {
      console.error('Failed to fetch subtitles', response.json());
      return null;
    }

    const subtitlesLines = (await response.json()).translatedCaptions;
    return { lines: subtitlesLines } as Subtitles;
  }

  async fetchWaveformDataFromUrl(waveformUrl: string) {
    const jsonResponse = await fetch(waveformUrl);
    return jsonResponse.json();
  }

  async fetchWaveformData(uploadId: string) {
    const response: any = await this.gqlClient.request(
      TRANSCRIPT_UPLOAD_QUERY,
      {
        id: uploadId,
      },
    );

    const waveformFile = response.allUploads[0];
    if (!waveformFile) {
      throw Error(`Waveform file not found`);
    }

    const jsonResponse = await fetch(waveformFile.url);
    return jsonResponse.json();
  }

  async fetchWaveformDataForAudioWithTitle(title: string) {
    const response: any = await this.gqlClient.request(UPLOAD_BY_TITLE_QUERY, {
      title,
    });

    const audio = response.allUploads[0];
    const waveformFileId = audio?.customData?.waveformDataUploadId;
    const duration = parseFloat(audio?.customData?.duration) || 0;

    if (!waveformFileId) {
      throw Error(`Waveform file Id not found`);
    }

    return {
      waveformJson: await this.fetchWaveformData(waveformFileId),
      duration,
    };
  }
}
