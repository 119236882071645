import { useEffect } from 'react';
import { StoryArtifactsAiTitleUpdate } from '../types.ts/story';
import { STORIES_ARTIFACT_AI_TITLE_QUERY } from '../gql/ai-prompts-gql';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useDatoQuerySubscription } from '@src/hooks/useDatoQuerySubscription';

export const useQueryStories = (
  ids: string[],
): {
  status: string;
  error: any;
  data: { allStories: StoryArtifactsAiTitleUpdate[] } | undefined;
} => {
  const { status, error, data } = useDatoQuerySubscription<
    { allStories: StoryArtifactsAiTitleUpdate[] },
    { ids: string[] }
  >({
    query: STORIES_ARTIFACT_AI_TITLE_QUERY,
    variables: { ids },
  });
  return { status, error, data };
};

const StoryArtifactsAiTitleSubscription = (props: {
  storyIds: string[];
  onDataReceived: (updates: StoryArtifactsAiTitleUpdate[]) => void;
  onError: (error: any) => void;
}) => {
  const { storyIds, onDataReceived, onError } = props;
  const { error, data } = useQueryStories(storyIds);

  useEffect(() => {
    if (error) {
      onError(error);
    } else if (data?.allStories?.length) {
      onDataReceived(data.allStories);
    }
  }, [data, error]);

  return <></>;
};

export default StoryArtifactsAiTitleSubscription;
