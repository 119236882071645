import { Client, SimpleSchemaTypes } from '@datocms/cma-client-browser';
import { GraphQLClient } from 'graphql-request';
import {
  AlbumStory,
  Artifact,
  DatoAlbumQueryResult,
  DatoStory,
  Story,
  StoryDTO,
  DatoStoryQueryResult,
} from '../types.ts/story';
import TurndownService from 'turndown';
import { TalkingPointContent } from '../types.ts/general';
import ApiClient from '../apiClient/ApiClient';
import { v4 as uuid } from 'uuid';
import transformKeysToCamelCase from '../utility/transformKeysToCamelCase';
import { request } from '../utility/dato';
import {
  populateAlbumQueryStoriesWithOriginalVideo,
  populateStoryQueryWithMissingFields,
} from '../utility/story';
import { ALBUM_STORY_IDS_QUERY, DASHBOARD_ALBUM_QUERY } from '../gql/album-gql';
import { STORY_QUERY } from '../gql/story-gql';

const turndownService = new TurndownService();

export class StoryRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  // TODO: Should migrate to using DatoClientStore gqlClient
  // but currently relies on 'includeDrafts' and 'excludeInvalid' options
  // which are required at client creation.
  async findMany(
    albumId: string,
    clientOptions: {
      includeDrafts: boolean;
      excludeInvalid: boolean;
      environment: string;
    },
  ): Promise<AlbumStory[]> {
    const response = (await request({
      query: DASHBOARD_ALBUM_QUERY,
      variables: {
        id: albumId,
      },
      ...clientOptions,
    })) as Record<'showcase', DatoAlbumQueryResult>;
    if (!response?.showcase?.stories) return [];

    const fullShowcase = await populateAlbumQueryStoriesWithOriginalVideo(
      response.showcase,
    );

    return fullShowcase.stories;
  }

  async findOne(id: string): Promise<Story | null> {
    const response = (await this.gqlClient.request(STORY_QUERY, {
      id,
    })) as DatoStoryQueryResult;
    if (!response.story) return null;
    const fullStory = await populateStoryQueryWithMissingFields(response.story);

    const storyClips = response.story.otherVideos?.map((clip) => ({
      ...clip,
    }));

    const story: Story = {
      ...fullStory!,
      otherVideos: storyClips || [],
      allVideos: storyClips || [],
      // sharableImages: sharableImages || [],
    };
    return story;
  }

  async create(story: Partial<StoryDTO>): Promise<DatoStory> {
    if (!story.title) {
      throw new Error('Story title is required');
    }
    if (!story.storyTeller?.id) {
      throw new Error('Storyteller id is required');
    }

    const uploadLogitemType =
      await this.dClient.itemTypes.find('story_steps_log');
    const storyUploadLog = await this.dClient.items.create({
      item_type: { type: 'item_type', id: uploadLogitemType.id },
      story_id: story.id,
    });

    const itemType = await this.dClient.itemTypes.find('story');
    const createdItem = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      hash: uuid(),
      slug: uuid(),
      upload_log: storyUploadLog.id,
      ...(story.title && {
        title: { en: story.title },
      }),
      ...(story.storyType && {
        story_type: story.storyType,
      }),
      ...(story.byExternalUser != null && {
        by_external_user: story.byExternalUser,
      }),
      ...(story.externalUploadLog && {
        external_upload_log: JSON.stringify(story.externalUploadLog),
      }),
      ...(story.storyTeller?.id && {
        story_teller: story.storyTeller.id,
      }),
      ...(story.primaryShowcase?.id && {
        primary_showcase: story.primaryShowcase.id,
      }),
      ...(story.useAws != null && {
        // != null is necessary for boolean values
        use_aws: story.useAws,
      }),
    });

    if (story.primaryShowcase?.id) {
      await this.addAsReferenceToShowcase(
        createdItem.id,
        story.primaryShowcase.id,
      );
    }

    return this.itemToStory({ ...createdItem, ...story });
  }

  private async fetchShowcaseWithStoryIds(
    showcaseId: string,
  ): Promise<{ showcase?: { stories: { id: string }[] } }> {
    return this.gqlClient.request(ALBUM_STORY_IDS_QUERY, {
      id: showcaseId,
    });
  }

  private async addAsReferenceToShowcase(storyId: string, showcaseId: string) {
    const showcaseRecord = await this.fetchShowcaseWithStoryIds(showcaseId);
    if (showcaseRecord?.showcase) {
      await this.dClient.items.update(showcaseId, {
        stories: showcaseRecord.showcase.stories
          .map((s) => s.id)
          .concat(storyId),
      });
      await this.dClient.items.publish(showcaseId);
    }
  }

  async removeReferenceFromShowcase(storyId: string, showcaseId: string) {
    const showcaseRecord = await this.fetchShowcaseWithStoryIds(showcaseId);
    if (showcaseRecord?.showcase) {
      await this.dClient.items.update(showcaseId, {
        stories: showcaseRecord.showcase.stories
          .map((s) => s.id)
          .filter((id) => id !== storyId),
      });
    }
  }

  async updateStoryStepsLog(uploadLog: Record<string, any>): Promise<void> {
    await this.dClient.items.update(uploadLog.id, uploadLog);
  }

  async update(story: Partial<StoryDTO> & { id: string }): Promise<DatoStory> {
    if (!story.id) {
      throw new Error('Story id is required');
    }

    const updatedItem = await this.dClient.items.update(story.id, {
      ...(story.title && {
        title: { en: story.title },
      }),

      ...(story.description && {
        description: { en: story.description },
      }),

      // DO NOT OVERWRITE VIDEOS TO AVOID DELETING THEM
      // Instead, videos are added and removed directly with addVideoToStory and removeVideoFromStory
      // ...(story.otherVideos && {
      //   other_videos: story.otherVideos.map((video) => video.id),
      // }),

      ...(story.finalVideo && {
        final_video: story.finalVideo.id,
      }),

      ...(story.shareableImages && {
        shareable_images: story.shareableImages.map((s) => s.id),
      }),

      ...(story.aiPhotos && {
        ai_photos: await Promise.all(
          story.aiPhotos.map((photo) => ({ upload_id: photo.id })),
        ),
      }),

      ...(story.storyAssets && {
        story_assets: await Promise.all(
          story.storyAssets.map((photo) => ({
            upload_id: photo.id,
            ...((photo as Artifact).title && {
              title: (photo as Artifact).title,
            }),
          })),
        ),
      }),

      ...(story.storyArtifacts && {
        story_artifacts: await Promise.all(
          story.storyArtifacts.map((photo) => ({
            upload_id: photo.id,
            ...((photo as Artifact).title && {
              title: (photo as Artifact).title,
            }),
          })),
        ),
      }),

      ...(story.storyArtifactsVideo && {
        story_artifacts_video: await Promise.all(
          story.storyArtifactsVideo.map((video) => ({
            upload_id: video.id,
            ...((video as Artifact).title && {
              title: (video as Artifact).title,
            }),
          })),
        ),
      }),

      ...(story.contributors && {
        contributors: story.contributors.map((c) => c.id),
      }),

      ...(story.myAudios && {
        my_audios: story.myAudios.map((audio) => audio.id),
      }),

      ...(story.aiResponse && {
        ai_response: JSON.stringify(story.aiResponse),
      }),
    });
    await this.dClient.items.publish(story.id);

    return this.itemToStory(updatedItem);
  }

  async addVideoToStory(storyId: string, videoId: string) {
    const story = await this.dClient.items.find(storyId);
    const updatedItem = await this.dClient.items.update(storyId, {
      other_videos: [videoId].concat((story.other_videos as string[]) || []),
    });
    await this.dClient.items.publish(storyId);
  }

  async removeVideoFromStory(storyId: string, videoId: string) {
    const story = await this.dClient.items.find(storyId);
    const updatedItem = await this.dClient.items.update(storyId, {
      other_videos: (story.other_videos as string[]).filter(
        (id) => id !== videoId,
      ),
      final_video: story.final_video === videoId ? null : story.final_video,
    });
    await this.dClient.items.publish(storyId);
  }

  private itemToStory(item: SimpleSchemaTypes.Item): DatoStory {
    return {
      ...transformKeysToCamelCase(item),
      title:
        typeof item.title === 'string'
          ? item.title
          : (item.title as { en?: string })?.en,
      _publishedAt: item.meta?.published_at,
    };
  }

  async handleSaveAiGeneratedContent(
    storyId: string,
    data: any,
    type: 'saved_blog' | 'saved_email' | 'saved_talking_point_content',
  ) {
    await this.dClient.items.update(storyId, {
      [`${type}`]: JSON.stringify(data, null, 2),
    });
    await this.dClient.items.publish(storyId);
  }

  async saveTalkingPointContent(
    story: Pick<Story, 'id' | 'savedTalkingPointContent'>,
    data: Record<
      keyof TalkingPointContent,
      Record<'title' | 'content' | 'prompt', string>
    >,
    title: string,
  ) {
    const savedData = story?.savedTalkingPointContent || {};
    let existingKey: number | undefined = undefined;

    for (let [key, content_data] of Object.entries(savedData)) {
      const hasMatch = Object.entries(content_data.content).every(
        ([key, value]) => {
          const k = key as keyof TalkingPointContent;
          const enterContentMk = turndownService.turndown(data[k].content);
          const existingMk = turndownService.turndown(value.content);

          return existingMk === enterContentMk && value.title === data[k].title;
        },
      );
      if (hasMatch) {
        existingKey = Number(key);
        break;
      }
    }

    if (existingKey) {
      delete savedData[existingKey];
    }

    savedData[new Date().getTime()] = { title, content: data };
    await this.handleSaveAiGeneratedContent(
      story.id,
      savedData,
      'saved_talking_point_content',
    );
    return savedData;
  }

  async saveBlogOrEmail(
    story: Pick<Story, 'id' | 'savedBlog' | 'savedEmail'>,
    data: string,
    title: string,
    userName: string | null | undefined,
    type: 'saved_blog' | 'saved_email',
  ) {
    let savedData = type === 'saved_email' ? story.savedEmail : story.savedBlog;

    savedData = savedData || {};
    const currDataMarkdown = turndownService.turndown(data);

    let existingKey: number | undefined = undefined;

    for (let [key, data] of Object.entries(savedData)) {
      const dataMarkdown = turndownService.turndown(data.content);
      if (currDataMarkdown === dataMarkdown) {
        existingKey = Number(key);
        break;
      }
    }

    if (existingKey) {
      delete savedData[existingKey];
    }

    const username = userName ?? 'Arbor Admin';
    savedData[new Date().getTime()] = { title, content: data, username };
    await this.handleSaveAiGeneratedContent(story.id, savedData, type);
    return savedData;
  }

  public async createShareableImage(
    story: Pick<Story, 'id' | 'title' | 'storyTeller'>,
    quote: string,
    imagefile?: Artifact,
    storytellerName?: string,
  ) {
    const itemType = await this.dClient.itemTypes.find('shareable_image');
    const savedShareableImage = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType!.id },
      story_id: story.id,
      title: `${story.title} - ${story.id}`,
      quote,
      storyteller_name: storytellerName || story.storyTeller?.name,
      ...(imagefile && {
        imagefile: {
          upload_id: imagefile.id,
        },
      }),
    });
    return savedShareableImage.id;
  }

  public async updateShareableImage(
    id: string,
    quote: string,
    imagefile?: Artifact,
    storytellerName?: string,
  ) {
    await this.dClient.items.update(id, {
      quote,
      storyteller_name: storytellerName,
      ...(imagefile && {
        imagefile: {
          upload_id: imagefile.id,
          alt: imagefile.responsiveImage?.alt,
        },
      }),
    });
    return id;
  }

  async deleteTranscription(storyId: string) {
    await this.dClient.items.update(storyId, {
      locked: false,
      transcription: null,
    });
    await this.dClient.items.publish(storyId);
  }
}
