import { forwardRef, useEffect, useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { uniq } from 'lodash';

import { useClickAway } from '../../utility/useClickAway';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

import Accordion from './Accordion';
import AddTile from './AddTile';
import PlusIcon from '../../svgs/PlusIcon';
import DeleteIcon from '../../svgs/DeleteIcon';
import CloseIcon from '../../svgs/CloseIcon';
import { useStore } from '@src/stores-v2/StoreContext';

type Props = {
  type: 'primary' | 'secondary';
};

const BrandKitColors = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { datoClientStore } = useStore();
  const { type } = props;

  const branding = videoCreator.organization?.branding;
  const colors =
    type === 'primary' ? branding?.primaryColors : branding?.secondaryColors;
  const [value, setValue] = useState('');

  const [isSaving, setIsSaving] = useState(false);
  const handleSave = async (newValue: string) => {
    setIsSaving(true);
    const organization = videoCreator.organization;
    organization!.branding = {
      ...organization!.branding!,
      ...(type === 'primary'
        ? { primaryColors: newValue }
        : { secondaryColors: newValue }),
    };
    await datoClientStore.albumRepository?.update(organization!);
    setIsSaving(false);
  };

  useEffect(() => {
    if (colors) setValue(colors);
  }, [Boolean(colors)]);
  const colorList = value ? value.split(',') : [];

  const [addingColor, setAddingColor] = useState<string | null>(null);
  const addPickerRef = useClickAway(() => {
    setAddingColor(null);
  });
  const handleAddColor = () => {
    if (addingColor) {
      const newValue = uniq(
        [...colorList, addingColor].map((c) => c.toUpperCase()),
      ).join(',');
      setValue(newValue);
      handleSave(newValue);
      setAddingColor(null);
    }
  };
  const addColorPicker = addingColor ? (
    <ColorPicker
      type="add"
      isSaving={isSaving}
      color={addingColor}
      onChange={setAddingColor}
      onDelete={() => setAddingColor(null)}
      onSave={handleAddColor}
      ref={addPickerRef}
    />
  ) : null;

  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingColor, setEditingColor] = useState<string | null>(null);
  const editPickerRef = useClickAway(() => {
    setEditingIndex(null);
    setEditingColor(null);
  });
  const handleEditColor = () => {
    if (editingIndex !== null && editingColor) {
      const updatedColors = [...colorList];
      updatedColors[editingIndex] = editingColor;
      const newValue = uniq(updatedColors.map((c) => c.toUpperCase())).join(
        ',',
      );
      setValue(newValue);
      handleSave(newValue);
      setEditingIndex(null);
      setEditingColor(null);
    }
  };
  const handleRemoveColor = (index: number) => {
    const newValue = colorList
      .filter((_, i) => i !== (index ?? editingIndex))
      .join(',');
    setValue(newValue);
    handleSave(newValue);
    setEditingIndex(null);
    setEditingColor(null);
  };
  const editColorPicker =
    editingIndex !== null && editingColor ? (
      <ColorPicker
        type="edit"
        isSaving={isSaving}
        color={editingColor}
        onChange={setEditingColor}
        onDelete={() => handleRemoveColor(editingIndex)}
        onSave={handleEditColor}
        ref={editPickerRef}
      />
    ) : null;

  return (
    <Accordion
      id={type === 'primary' ? 'primary-colors' : 'secondary-colors'}
      title={`${type.charAt(0).toUpperCase() + type.slice(1)} Colors${
        colors ? ` (${colorList.length})` : ''
      }`}
    >
      {branding !== undefined ? (
        <Container showEmptyMessage={!colorList.length && !addingColor}>
          {colorList.length ? (
            <ColorList>
              {colorList.map((color, index) => (
                <ColorItem key={color} color={color}>
                  <div
                    role="button"
                    className="color-tile"
                    onClick={() => {
                      setEditingIndex(index);
                      setEditingColor(color);
                    }}
                  >
                    <div />
                    <span>{color}</span>
                    <div
                      role="button"
                      className="delete-color-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveColor(index);
                      }}
                    >
                      <CloseIcon width="9" height="9" strokeColor="#f2d093" />
                    </div>
                  </div>
                  {index === editingIndex ? editColorPicker : null}
                </ColorItem>
              ))}
              <AddTileContainer>
                <AddTile onClick={() => setAddingColor('#ffffff')} />
                {addColorPicker}
              </AddTileContainer>
            </ColorList>
          ) : (
            <EmptyMessage>
              <div
                className="empty-content"
                role="button"
                onClick={() => setAddingColor('#ffffff')}
              >
                <PlusIcon width="40" strokeColor="#f3e9d7" />
                <span>Add your first color</span>
              </div>
              {addColorPicker}
            </EmptyMessage>
          )}
        </Container>
      ) : null}
    </Accordion>
  );
});

type ColorPickerProps = {
  type: 'add' | 'edit';
  isSaving: boolean;
  color: string;
  onChange: (color: string) => void;
  onDelete: () => void;
  onSave: () => void;
};

const ColorPicker = forwardRef<HTMLDivElement, ColorPickerProps>(
  (props, ref) => {
    const { type, isSaving, color, onChange, onDelete, onSave } = props;
    return (
      <SColorPicker
        isSaving={isSaving}
        className="color-picker"
        color={color}
        ref={ref}
      >
        <HexColorPicker
          color={color}
          onChange={onChange}
          style={{ width: '100%' }}
        />
        <div className="color-picker-controls">
          <div className="color-picker-controls-row">
            <button
              className="delete-color-btn"
              onClick={onDelete}
              disabled={isSaving}
            >
              <DeleteIcon width="20" height="20" />
            </button>
            <div className="color-input">
              <div />
              <HexColorInput color={color} onChange={onChange} />
            </div>
          </div>
          <button
            className="save-color-btn"
            onClick={onSave}
            disabled={isSaving}
          >
            {type === 'add' ? 'Add to Brand Kit' : 'Save'}
          </button>
        </div>
      </SColorPicker>
    );
  },
);

const Container = styled.div<{ showEmptyMessage: boolean }>`
  border-radius: 8px;
  background: #262630;
  padding: ${({ showEmptyMessage }) => (showEmptyMessage ? '0' : '24px')};
  cursor: ${({ showEmptyMessage }) =>
    showEmptyMessage ? 'pointer' : 'default'};
  min-height: 120px;
  position: relative;
  transition: 0.2s;
  ${({ showEmptyMessage }) =>
    showEmptyMessage &&
    css`
      &:hover {
        background: #484848;
      }
    `}
`;

const ColorList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(72px, 1fr));
  gap: 24px;
`;

const ColorItem = styled.div<{ color: string }>`
  position: relative;
  > .color-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: relative;
    cursor: pointer;
    &:hover {
      > span {
        color: #f3e9d7;
      }
      > .delete-color-icon {
        opacity: 1;
        cursor: pointer;
        transition-delay: 0.4s;
      }
    }
    > div {
      background: ${({ color }) => color};
      width: 72px;
      height: 72px;
      border-radius: 4px;
    }
    > span {
      color: #848484;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      transition: 0.2s;
    }
    > .delete-color-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #484848;
      position: absolute;
      top: -8px;
      right: -8px;
      opacity: 0;
      cursor: none;
      transition: 0.2s;
      svg path {
        transition: 0.2s;
      }
      &:hover {
        svg path {
          stroke: #ef5d6f;
        }
      }
    }
  }
  > .color-picker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

const AddTileContainer = styled.div`
  position: relative;

  > .color-picker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

const SColorPicker = styled.div<{ color: string; isSaving: boolean }>`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #484848;
  background: #000000;
  > .color-picker-controls {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > .color-picker-controls-row {
      display: flex;
      align-items: center;
      gap: 16px;
      > .delete-color-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        padding: 0;
        border-radius: 8px;
        border: 1px solid #484848;
        background: none;
        transition: 0.2s;
        opacity: ${({ isSaving }) => (isSaving ? 0.6 : 1)};
        cursor: ${({ isSaving }) => (isSaving ? 'not-allowed' : 'pointer')};
      }
      .color-input {
        height: 48px;
        display: flex;
        padding: 12px 8px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #484848;
        gap: 8px;
        > div {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          border-radius: 4px;
          background: ${({ color }) => color};
        }
        > input {
          width: 162px;
          color: #f3e9d7;
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
          border: none;
          outline: none;
          background: none;
          padding: 0;
        }
      }
    }
    > .save-color-btn {
      display: block;
      height: 48px;
      padding: 12px 16px;
      border-radius: 8px;
      background: #17c964;
      color: #03041a;
      font-size: 14px;
      font-weight: 700;
      border: none;
      transition: 0.2s;
      opacity: ${({ isSaving }) => (isSaving ? 0.6 : 1)};
      cursor: ${({ isSaving }) => (isSaving ? 'not-allowed' : 'pointer')};
    }
  }
`;

const EmptyMessage = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  > .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    > span {
      color: #f3e9d7;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }
  > .color-picker {
    position: absolute;
    z-index: 1;
  }
`;

export default BrandKitColors;
