import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';
import { useClickAway } from '../../utility/useClickAway';

import ColorPicker from './ColorPicker';
import ShadowIcon from '../../svgs/ShadowIcon';
import DeleteIcon from '../../svgs/DeleteIcon';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

export type KaraokeShadowConfig = {
  color: string | null;
  blur: number | null;
  x: number | null;
  y: number | null;
};

const defaultShadowConfig = {
  color: 'rgba(0,0,0,0.35)',
  blur: 6,
  x: 0,
  y: 6,
};

type Props = {
  action: (propertyMap: Partial<KaraokeConfig>) => void;
  value?: KaraokeShadowConfig;
  type?: 'basic' | 'karaoke';
};

const KaraokeShadow = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { action, value, type = 'karaoke' } = props;
  const isBasicTextSetting = type === 'basic';

  const _color = value?.color || defaultShadowConfig.color;
  const _blur = value?.blur?.toString() || defaultShadowConfig.blur.toString();
  const _x = value?.x?.toString() || defaultShadowConfig.x.toString();
  const _y = value?.y?.toString() || defaultShadowConfig.y.toString();

  const [color, setColor] = useState(_color);
  const rgbaColor = useMemo(
    () =>
      color
        ? videoCreator.textBrandingService.getRgbaValueFromRgbaString(color)
        : undefined,
    [color],
  );
  const [opacity, setOpacity] = useState(
    (((rgbaColor?.alpha || 0) * 100) / 255).toFixed(0),
  );
  const [blur, setBlur] = useState(_blur);
  const [x, setX] = useState(_x);
  const [y, setY] = useState(_y);

  useEffect(() => {
    setColor(_color);
    setOpacity((((rgbaColor?.alpha || 0) * 100) / 255).toFixed(0));
    setBlur(_blur);
    setX(_x);
    setY(_y);
  }, [value]);

  const getRgbWithOpacity = (rgbaString: string, opacity: number) => {
    const rgbaColor = rgbaString
      ? videoCreator.textBrandingService.getRgbaValueFromRgbaString(rgbaString)
      : undefined;
    return `rgba(${rgbaColor?.red || 0},${rgbaColor?.green || 0},${
      rgbaColor?.blue || 0
    },${Number(opacity) / 100})`;
  };

  const [dialog, setDialog] = useState<DOMRect | null>(null);
  const [colorPicker, setColorPicker] = useState<DOMRect | null>(null);
  const ref = useClickAway(() => {
    if (!colorPicker && dialog) {
      setDialog(null);
      action({
        ...(isBasicTextSetting ? {} : { shadowEffect: true }),
        shadow_color: getRgbWithOpacity(color, Number(opacity)),
        shadow_blur: Number(blur),
        shadow_x: Number(x),
        shadow_y: Number(y),
      });
    }
  });

  const saveColor = (newColor = color) =>
    action({
      shadow_color: getRgbWithOpacity(newColor as string, Number(opacity)),
    });
  const saveBlur = () => action({ shadow_blur: Number(blur) });
  const saveX = () => action({ shadow_x: Number(x) });
  const saveY = () => action({ shadow_y: Number(y) });

  return (
    <Main ref={ref}>
      <Container
        active={!!value}
        onClick={(e) => {
          if (!value) {
            action({
              ...(isBasicTextSetting ? {} : { shadowEffect: true }),
              shadow_color: _color,
              shadow_blur: Number(_blur),
              shadow_x: Number(_x),
              shadow_y: Number(_y),
            });
          }
          setDialog(dialog ? null : e.currentTarget.getBoundingClientRect());
          setColorPicker(null);
        }}
      >
        <ShadowIcon />
      </Container>

      {dialog && (
        <Dialog top={dialog.bottom} right={window.innerWidth - dialog.right}>
          <DialogRow>
            <DialogItem
              onClick={(e) => {
                if (colorPicker) setColorPicker(null);
                else setColorPicker(e.currentTarget.getBoundingClientRect());
              }}
            >
              <DialogItemLabel>Color</DialogItemLabel>
              <DialogColorContainer>
                <DialogColor
                  color={`rgb(${rgbaColor?.red || 0},${rgbaColor?.green || 0},${
                    rgbaColor?.blue || 0
                  })`}
                />
              </DialogColorContainer>
            </DialogItem>

            <DialogItem>
              <DialogItemLabel>Opacity</DialogItemLabel>
              <DialogOpacityInput>
                <input
                  value={opacity}
                  type="number"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e) =>
                    setOpacity(validateNumberInput(e.target.value, 0, 100))
                  }
                  onBlur={() => saveColor()}
                  onKeyDown={(e) => e.key === 'Enter' && saveColor()}
                />
                <span>%</span>
              </DialogOpacityInput>
            </DialogItem>

            <DialogItem>
              <DialogItemLabel>Blur</DialogItemLabel>
              <DialogInput
                value={blur}
                type="number"
                step={1}
                onChange={(e) => setBlur(validateNumberInput(e.target.value))}
                onBlur={saveBlur}
                onKeyDown={(e) => e.key === 'Enter' && saveBlur()}
              />
            </DialogItem>
          </DialogRow>

          <DialogRow>
            <DialogItem>
              <DialogItemLabel>X Offset</DialogItemLabel>
              <DialogInput
                value={x}
                type="number"
                step={0.1}
                onChange={(e) => setX(validateNumberInput(e.target.value))}
                onBlur={saveX}
                onKeyDown={(e) => e.key === 'Enter' && saveX()}
              />
            </DialogItem>

            <DialogItem>
              <DialogItemLabel>Y Offset</DialogItemLabel>
              <DialogInput
                value={y}
                type="number"
                step={0.1}
                onChange={(e) => setY(validateNumberInput(e.target.value))}
                onBlur={saveY}
                onKeyDown={(e) => e.key === 'Enter' && saveY()}
              />
            </DialogItem>

            <DeleteIconContainer
              onClick={() => {
                action({
                  ...(isBasicTextSetting ? {} : { shadowEffect: false }),
                  shadow_color: null,
                  shadow_blur: null,
                  shadow_x: null,
                  shadow_y: null,
                });
                setDialog(null);
              }}
            >
              <DeleteIcon width="20" height="20" />
            </DeleteIconContainer>
          </DialogRow>
        </Dialog>
      )}

      {colorPicker && (
        <ColorPicker
          defaultValue={color}
          getValue={(_color) => {
            setColor(_color as string);
            setColorPicker(null);
            saveColor(_color as string);
          }}
          onClose={() => setColorPicker(null)}
          pos={colorPicker}
        />
      )}
    </Main>
  );
});

const validateNumberInput = (value: string, min = 0, max?: number) => {
  if (value === '') return '';
  if (isNaN(Number(value))) return '0';
  if (Number(value) < min) return min.toString();
  if (max !== undefined && Number(value) > max) return max.toString();
  return value;
};

const Main = styled.div``;

const Container = styled.div<{ active: boolean }>`
  position: relative;
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ active }) => (active ? '#f2d093' : '#484848')};
  background-color: ${({ active }) => (active ? '#f2d093' : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: ${({ active }) => (active ? '#03041A' : '#484848')};
  }
`;

const Dialog = styled.div<{ top: number; right: number }>`
  position: fixed;
  top: ${({ top }) => top}px;
  right: ${({ right }) => right}px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  z-index: 999;
`;

const DialogRow = styled.div`
  display: flex;
  gap: 12px;
`;

const DialogItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DialogItemLabel = styled.div`
  color: #848484;
  font-size: 10px;
  font-weight: medium;
`;

const DialogColorContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #484848;
  padding: 8px;
  cursor: pointer;
`;

const DialogColor = styled.div<{ color?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 40px;
  background-color: ${({ color }) => color || 'transparent'};
`;

const DialogOpacityInput = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  border: 1px solid #484848;
  border-radius: 4px;
  height: 34px;
  color: #f3e9d7;
  font-size: 14px;
  position: relative;
  input {
    height: 32px;
    width: 64px;
    padding: 0 20px 0 8px;
    box-sizing: border-box;
    background: none;
    border: none;
    outline: none;
    color: #f3e9d7;
    font-size: 14px;
    text-align: center;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      display: none;
    }
  }
  span {
    position: absolute;
    right: 8px;
  }
`;

const DialogInput = styled.input`
  box-sizing: border-box;
  height: 34px;
  width: 50px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #484848;
  background: none;
  outline: none;
  color: #f3e9d7;
  font-size: 14px;
  text-align: center;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
`;

const DeleteIconContainer = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  border: 1px solid #484848;
  cursor: pointer;
`;

export default KaraokeShadow;
