import { useGesture } from '@use-gesture/react';
import React, { useEffect, useRef, KeyboardEvent } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Timeline } from './timeline/Timeline';
import { Stage } from './stage/Stage';

import { Music } from '../types.ts/story';
import { request } from '../utility/dato';
import { LeftSidePanel } from './sidepanel/LeftSidePanel';
import { RightSidePanel } from './sidepanel/RightSidePanel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReplacePhotoElementModal from './common/ReplacePhotoElementModal';
import RetryPublishModal from './stage/RetryPublishModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SaveOrDiscardTemplateStyleModal from './common/SaveOrDiscardTemplateStyleModal';
import ToastNotification from './common/ToastNotification';
import StatusMessageModal from './common/StatusMessageModal';
import { inDebugMode } from '../utility/debug';
import GlobalGeneratedPunchListModal from './common/GlobalGeneratedPunchListModal';
import { STOCK_MUSIC_QUERY } from '../gql/media-gql';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import {
  useDatoClient,
  useStore,
  useTemplatedPrompts,
  useUserIdentity,
} from '@src/stores-v2/StoreContext';
import { useAccessControl } from '@src/hooks/useAccessControl';

export const VideoCreator: React.FC<{}> = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const templatedPromptsStore = useTemplatedPrompts();
  const analyticsStore = useStore().analyticsStore;
  const datoClientStore = useDatoClient();
  const story = videoCreator.story;
  const { enableAiImageGeneration } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const { storyId, videoId, showcase } = Object.fromEntries(
    searchParams.entries(),
  );

  const ref = useRef<HTMLDivElement>(null);
  useGesture(
    {
      onPinch: () => {},
      onWheel: () => {},
    },
    { target: ref, eventOptions: { passive: false } },
  );

  useEffect(() => {
    return () => {
      (async () => {
        await videoCreator.reframingModeManager.exitReframingMode();
      })();
    };
  }, []);

  useEffect(() => {
    videoCreator.setAiImageFeatureFlag(enableAiImageGeneration);
  }, [story?.id, enableAiImageGeneration]);

  useEffect(() => {
    videoCreator.justLoaded = true;

    if (
      videoCreator.currentVideo &&
      !videoCreator.currentVideo?.id &&
      searchParams.get('videoId')
    ) {
      searchParams.delete('videoId');
      setSearchParams(searchParams);
    } else if (videoCreator.currentVideo?.id) {
      searchParams.set('videoId', videoCreator.currentVideo?.id);
      setSearchParams(searchParams);
    }

    // Log debug information if in debug mode
    if (inDebugMode()) {
      console.debug('Debug mode is ON.');
    }
  }, [videoCreator.currentVideo?.id]);

  const shouldInitializeWithNewData = () => {
    const isStoryChanged = storyId && videoCreator.story?.id !== storyId;
    const isVideoChanged = videoId && videoCreator.currentVideo?.id !== videoId;
    return isStoryChanged || isVideoChanged;
  };

  useEffect(() => {
    if (shouldInitializeWithNewData()) {
      videoCreator.initializeData({
        storyId,
        videoId,
        showcaseSlug: showcase,
      });
    }
    analyticsStore.setContext(storyId, showcase || 'Unknown');
    templatedPromptsStore.fetchTemplates(storyId);
  }, [storyId, videoId, showcase]);

  useEffect(() => {
    (async () => {
      // TODO: Move to Repository
      const stockMusicResponse = (await request({
        query: STOCK_MUSIC_QUERY,
        variables: undefined,
        environment: datoClientStore.environment,
      })) as { allMusics: Music[] };

      videoCreator.stockMusic = stockMusicResponse.allMusics;
    })();
  }, [story?.id]);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Space') {
      videoCreator.isPlaying
        ? videoCreator.renderer?.pause()
        : videoCreator.refreshSourceAndPlay();
    }
  };

  return (
    <Main tabIndex={-1} onKeyDown={handleKeyDown} ref={ref}>
      {/* {demoStore.demoHeadline} */}
      <MainView>
        {!videoCreator.isPlayerFullScreen && <LeftSidePanel />}
        <Stage storyTellerName={story?.storyTeller?.name || ''} />
        {!videoCreator.isPlayerFullScreen && <RightSidePanel story={story} />}
      </MainView>
      {!videoCreator.isPlayerFullScreen && <Timeline story={story} />}
      {videoCreator.openPhotoElementReplacementModal?.element &&
        (videoCreator.openPhotoElementReplacementModal?.isForPunchlist ? (
          <GlobalGeneratedPunchListModal />
        ) : (
          <ReplacePhotoElementModal />
        ))}
      {videoCreator.renderingStatus === 'error' &&
        videoCreator.renderVideoResLevel &&
        !videoCreator.renderQueueing && (
          <RetryPublishModal
            closeModal={() => {
              videoCreator.renderingStatus = 'none';
              videoCreator.renderVideoResLevel = null;
            }}
          />
        )}

      {videoCreator.textBrandingService.modifiedTemplateData &&
        !videoCreator.textBrandingService.isTemplateModified && (
          <SaveOrDiscardTemplateStyleModal />
        )}
      {videoCreator.toastState && <ToastNotification />}
      <StatusMessageModal enabled={false} variant="system-issues" />
    </Main>
  );
});

const Main = styled.div`
  width: 100%;
  height: calc(100vh - 69px);
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
  }
`;

const MainView = styled.div`
  flex: 1 1 0%;
  display: flex;
  overflow: hidden;
`;

const SideView = styled.div`
  display: flex;
`;
