export class ServerEventStreamer<T> {
  private url: string;
  private body: any;
  private consumedEventIds: Set<string>;
  private controller: AbortController | null = null;

  constructor(url: string, body: any) {
    this.url = url;
    this.body = body;
    this.consumedEventIds = new Set();
  }

  async start(
    onMessageReceived: (messagePayload: T) => void,
    onError?: (error: string) => void,
  ) {
    if (this.controller) return;

    this.controller = new AbortController();
    const { signal } = this.controller;

    try {
      const response = await fetch(this.url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.body),
        signal,
      });

      if (!response.body) throw new Error('No response body');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        console.log('done', done);
        if (done) break;

        const text = decoder.decode(value, { stream: true });

        text.split('\n\n').forEach((line) => {
          if (line.startsWith('data: ')) {
            const payload: T & { id: string } = JSON.parse(line.slice(6));
            // console.log('Server Event payload', payload);
            // Ensure the same clip is not sent twice
            if (!this.consumedEventIds.has(payload.id)) {
              this.consumedEventIds.add(payload.id);
              onMessageReceived(payload);
            }
          }
        });
      }
    } catch (err) {
      if (onError) onError((err as Error).message);
    } finally {
      this.controller = null;
    }
  }

  stop() {
    this.controller?.abort();
    this.controller = null;
  }
}
